import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth"
import { auth } from "../../utils/firebase"
import { updateName } from "../../hooks/functions"
import { images } from "../../assets"
import { ExtProviderButtons } from "./auth_ext_provider"
import { useTheme } from "../../context/useTheme"
import { classNames } from "../../utils/formats"
import { WidgetTerms } from "./term"

export const WidgetSignUp = () => {
  const navigate = useNavigate()
  const theme = useTheme()

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [isChecked, setIsChecked] = useState(false)
  const [error, setError] = useState("")
  const [openTermsModal, setOpenTermsModal] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (isChecked) setOpenTermsModal(true)
  }, [isChecked])

  useEffect(() => {
    //check if passwords match
    if (confirmPassword !== "" && password !== "") {
      password !== confirmPassword
        ? setError("Password and confirm password don't match!")
        : setError("")
    }
  }, [password, confirmPassword])

  const handleCheckBox = () => {
    setIsChecked(!isChecked)
  }

  const handleSubmit = async () => {
    setError("")
    if (email.trim() === "") {
      console.log("Email field is empty")
      setError("Email field is empty")
      return
    }
    if (password !== confirmPassword) {
      console.log("Password and confirm password don't match!")
      setError("Password and confirm password don't match!")
      return
    }
    if (!isChecked) {
      console.log("You have to agree with Terms & Service agreement")
      setError("You have to agree with Terms & Service agreement")
      return
    }

    setLoading(true)

    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        await updateName(firstName, lastName)
        await sendEmailVerification(auth.currentUser)

        //update name
        setLoading(false)
        navigate("/swap/verification")
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        setLoading(false)
        console.log(errorCode, errorMessage)
        setError(errorMessage)
      })
  }

  return (
    <div className="pb-5">
      <p className="pt-5 flex">First name</p>
      <div className="flex rounded-md mt-2">
        <input
          type="text"
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `border-lightWidget-primaryColor hover:border-lightWidget-primaryColor/70`
              : `border-darkWidget-primaryColor hover:border-darkWidget-primaryColor/70`,
            "flex py-2 w-full focus:shadow-none focus:ring-0 border bg-transparent rounded-md"
          )}
          placeholder="First name"
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <p className="pt-5 flex">Last name</p>
      <div className="flex rounded-md mt-2">
        <input
          type="text"
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `border-lightWidget-primaryColor hover:border-lightWidget-primaryColor/70`
              : `border-darkWidget-primaryColor hover:border-darkWidget-primaryColor/70`,
            "flex py-2 w-full focus:shadow-none focus:ring-0 border bg-transparent rounded-md"
          )}
          placeholder="Last name"
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <p className="pt-5 flex">Email</p>
      <div className="flex rounded-md mt-2">
        <input
          type="email"
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `border-lightWidget-primaryColor hover:border-lightWidget-primaryColor/70`
              : `border-darkWidget-primaryColor hover:border-darkWidget-primaryColor/70`,
            "flex py-2 w-full focus:shadow-none focus:ring-0 border bg-transparent rounded-md"
          )}
          placeholder="achithebest@example.com"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <p className="pt-5 flex">Password</p>
      <div className="flex rounded-md mt-2">
        <input
          type="password"
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `border-lightWidget-primaryColor hover:border-lightWidget-primaryColor/70`
              : `border-darkWidget-primaryColor hover:border-darkWidget-primaryColor/70`,
            "flex py-2 w-full focus:shadow-none focus:ring-0 border bg-transparent rounded-md"
          )}
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <p className="pt-5 flex">Confirm password</p>
      <div className="flex rounded-md mt-2">
        <input
          type="password"
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `border-lightWidget-primaryColor hover:border-lightWidget-primaryColor/70`
              : `border-darkWidget-primaryColor hover:border-darkWidget-primaryColor/70`,
            "flex py-2 w-full focus:shadow-none focus:ring-0 border bg-transparent rounded-md"
          )}
          placeholder="Confirm password"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>
      {error && <p className="text-red-700 pt-3 text-center">{error}</p>}
      <div
        className="flex gap-1 justify-start items-start px-2 pt-2"
        onClick={handleCheckBox}
      >
        <input
          type="checkbox"
          className="mt-1 border outline-0 focus:shadow-none focus:outline-0 focus:ring-0 focus:border-0 hover:cursor-pointer"
          checked={isChecked}
          onChange={handleCheckBox}
        />
        <label className="hover:cursor-pointer">
          I agree I have read this Terms & Service agreement
        </label>
      </div>
      <div className="flex gap-3">
        <button
          className="bg-[#bbbbbb] hover:bg-[#bbbbbb]/80 rounded-full py-2 w-full mt-4"
          onClick={() => navigate("/swap")}
        >
          Back
        </button>
        <button
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `bg-lightWidget-primaryColor hover:bg-lightWidget-primaryColor/70`
              : `bg-darkWidget-primaryColor hover:bg-darkWidget-primaryColor/70`,
            "rounded-full py-2 w-full mt-4"
          )}
          onClick={handleSubmit}
        >
          Create
        </button>
      </div>

      <p className="text-center pt-4">
        Already have an account?{" "}
        <span
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `text-lightWidget-primaryColor`
              : `text-darkWidget-primaryColor`,
            "hover:underline cursor-pointer"
          )}
          onClick={() => navigate("/swap/signin")}
        >
          Sign In
        </span>
      </p>

      <div className="mt-3 flex items-center justify-between">
        <img
          src={
            theme.currentWidgetTheme === "light"
              ? images.BlackLine
              : images.Line
          }
          alt="Line"
          className="w-24 h-auto"
        />
        <p>Or</p>
        <img
          src={
            theme.currentWidgetTheme === "light"
              ? images.BlackLine
              : images.Line
          }
          alt="Line"
          className="w-24 h-auto"
        />
      </div>

      <ExtProviderButtons />
      <WidgetTerms
        openTermsModal={openTermsModal}
        setOpenTermsModal={setOpenTermsModal}
      />
    </div>
  )
}
