import { useTheme } from "../../context/useTheme";
import { MarketSummary } from "../../components/MarketSummary/MarketSummary";
import { MarketView } from "../../components/MarketView/";

export const Market = () => {
  const theme = useTheme();
  const isMobile = theme.isMobile;

  return (
    <div className="relative flex flex-col min-w-0 break-words w-full h-full rounded-xl p-2 sm:p-3 md:p-5 bg-panelColor-light dark:bg-panelColor-dark text-textColor-light dark:text-textColor-dark">
      {isMobile ? <MarketView /> : <MarketSummary />}
    </div>
  );
};
