// Import the functions you need from the SDKs you need
import { initializeApp, getApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup, connectAuthEmulator, TwitterAuthProvider

 } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBlOGcxJjUzRG49fUtTBebKhOO73QBNxHs",
  authDomain: "achi-inu.firebaseapp.com",
  projectId: "achi-inu",
  storageBucket: "achi-inu.appspot.com",
  messagingSenderId: "242300351060",
  appId: "1:242300351060:web:eb425511f36f8d0704b3c1",
  measurementId: "G-LX518B65P8"
};

/*
const firebaseConfig = {
  apiKey: "AIzaSyA7bBIJiVWHbMa_LrkQeD7dLdPFImUEceI",
  authDomain: "127.0.0.1:9099",
  projectId: "walletx-58e5f",
  storageBucket: "127.0.0.1:5000",
  messagingSenderId: "1071344673471",
  appId: "1:1071344673471:web:32c695f7dd1e17d7e65c0a",
  measurementId: "G-0CWEBJLLME"
};*/

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
process.env.REACT_APP_ENVIRONMENT === "development" && connectFirestoreEmulator(db, "localhost", 8080);
const auth = getAuth(app);
const ga_provider = new GoogleAuthProvider();
const tw_provider = new TwitterAuthProvider();
process.env.REACT_APP_ENVIRONMENT === "development" && connectAuthEmulator(auth, "http://localhost:9099");  
const functions = getFunctions(getApp());
process.env.REACT_APP_ENVIRONMENT === "development" && connectFunctionsEmulator(functions, "localhost", 5001);
const storage = getStorage(app);

export { db, analytics, app, auth, functions, storage, ga_provider, tw_provider, signInWithPopup  };
