import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { ArrowsUpDownIcon } from "@heroicons/react/20/solid"
import { TokenListModal } from "../../components/TokenListModal"
import { approvedTokens } from "../../constant"
import useSwap from "../../hooks/useSwap"
import DebouncedInput from "../Trade/Debounce"
import { useTheme } from "../../context/useTheme"
function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const SwapInterface = ({ account, handleOnboarding }) => {
  const [flag, setFlag] = useState(0)
  const navigate = useNavigate()
  const theme = useTheme()

  const [amountTabs, setAmountTabs] = useState([
    { name: "Custom", value: "", current: true },
    { name: "$50", value: "50", current: false },
    { name: "$100", value: "100", current: false },
    { name: "$500", value: "500", current: false },
  ])
  const [openTokenListModal, setOpenTokenListModal] = useState(false)
  const [openBuyTokenModal, setOpenBuyTokenModal] = useState(false)

  const init = async () => {
    handleTokenSelect(approvedTokens[0], true) // true means sell token
    handleTokenSelect(approvedTokens[4], false) // false here means buy token
  }

  useEffect(() => {
    init()
  }, [])

  const {
    inputToken,
    outputToken,
    usdValue,
    usdValues,
    response,
    swapButtonLoadingText,
    handleUsdAmountChange,
    handleTokenSelect,
    swapTokensUI,
    startSwapTokens,
    isSwapping,
    isSuccess,
    formattedQuote,
    isActive,
  } = useSwap(account)

  return (
    <div className="">
      <div className="lg:ml-6 lg:mt-0 flex w-[90vw] sm:w-[400px] h-full">
        <div
          className={classNames(
            theme.currentTheme === "dogTheme"
              ? "bg-dogTheme-bgColor"
              : theme.currentTheme === "lightTheme"
              ? "bg-lightTheme-bgColor"
              : "bg-darkTheme-bgColor",
            "w-full rounded-xl"
          )}
        >
          {flag === 0 && (
            <div>
              <div className="flex flex-col gap-3 justify-center items-center text-center">
                <div className="w-[80%] flex flex-row justify-between pt-5 relative">
                  <div className="w-3 h-3 rounded-full bg-[#555555] z-10"></div>
                  <div className="w-3 h-3 rounded-full bg-dogTheme-primary z-10"></div>
                  <div className="w-3 h-3 rounded-full bg-[#555555] z-10"></div>
                  <div className="w-full h-[2px] absolute bg-[#555555] top-[78%] z-0"></div>
                </div>
                <div className="md:text-2xl text-xl py-3 flex flex-col">
                  <span>STEP 2 - BUY (OUTPUTMINT)</span>
                  <span className="text-[14px]">
                    Start trading SOL for your preferred token
                  </span>
                </div>
              </div>
              <div>
                <div className="sm:pt-6 flex justify-center items-center">
                  <span className="text-4xl text-right"></span>
                  {/*Debounce so there is a delay when sending the quote for input typing*/}
                  <DebouncedInput
                    handleAmountChange={handleUsdAmountChange}
                    amount={usdValue}
                    disabled={isSwapping}
                  />
                </div>
                {inputToken?.balance && (
                  <p className="text-center mt-2 text-xs sm:text-sm">
                    Your available balance is $
                    {inputToken?.balance
                      ? Number(
                          Math.round(
                            Number(inputToken.usdPrice) *
                              Number(inputToken.balance) *
                              100
                          ) / 100
                        ).toLocaleString()
                      : "0"}
                    &nbsp; (
                    {inputToken?.balance
                      ? Number(inputToken?.balance).toLocaleString()
                      : "0"}{" "}
                    {inputToken?.symbol})
                  </p>
                )}

                {response.error && (
                  <p className="text-center text-[#f00] mt-2 text-xs sm:text-sm">
                    {response.error}
                  </p>
                )}
                {response.message && (
                  <p className="text-center green-[#f00] mt-2 text-xs sm:text-sm">
                    {response.error}
                  </p>
                )}

                <div className="mx-4">
                  <div className="mt-6 rounded-md border border-black">
                    <nav
                      className="flex justify-between flex-wrap"
                      aria-label="Tabs"
                    >
                      {amountTabs.map((tab) => (
                        <Link
                          key={tab.name}
                          className={classNames(
                            tab.current
                              ? theme.currentTheme === "dogTheme"
                                ? "bg-dogTheme-primary"
                                : theme.currentTheme === "lightTheme"
                                ? "bg-lightTheme-gradient"
                                : "bg-darkTheme-gradient"
                              : theme.currentTheme === "dogTheme"
                              ? "text-dogTheme-textLightColor"
                              : theme.currentTheme === "lightTheme"
                              ? "text-lightTheme-textLightColor"
                              : "text-darkTheme-textLightColor",
                            "rounded-md sm:px-3 sm:py-3 px-1 py-2 font-medium text-xs sm:text-[13px] w-[25%] text-center"
                          )}
                          aria-current={tab.current ? "page" : undefined}
                          onClick={(e) => {
                            e.preventDefault()
                            setAmountTabs((_prev) => {
                              const temp = [..._prev]
                              for (let i = 0; i < temp.length; i++) {
                                if (temp[i].name === tab.name) {
                                  temp[i].current = true
                                } else {
                                  temp[i].current = false
                                }
                              }
                              return temp
                            })
                            handleUsdAmountChange({
                              target: { value: tab.value },
                            })
                          }}
                        >
                          {tab.name}
                        </Link>
                      ))}
                    </nav>
                  </div>
                  <p className="mt-3 sm:mt-6 text-sm sm:text-lg flex justify-between">
                    <span>You are selling</span>
                    <span>
                      available:{" "}
                      {inputToken?.balance
                        ? Number(inputToken.balance).toLocaleString()
                        : "0"}{" "}
                      {inputToken.symbol}
                    </span>
                  </p>
                  <div className="flex flex-row items-center justify-between mt-3 sm:mt-6 gap-[5px]">
                    <p>
                      <span className="text-xl sm:text-4xl">
                        {formattedQuote?.inputAmount === "0.0000"
                          ? ""
                          : Number(
                              formattedQuote?.inputAmount
                            ).toLocaleString()}
                      </span>
                      <span className="text-[#aaa] text-sm sm:text-md ml-3">
                        {formattedQuote?.inputAmount === "0.0000" ? (
                          ""
                        ) : (
                          <span>
                            USD $
                            {Number(usdValues["inputToken"]).toLocaleString()}
                          </span>
                        )}
                      </span>
                    </p>

                    <TokenListModal
                      showStatus={openTokenListModal}
                      setShowStatus={setOpenTokenListModal}
                      isBottomToken={false}
                      selectedToken={inputToken}
                      setSelectedToken={(token) =>
                        handleTokenSelect(token, true)
                      }
                    />
                  </div>
                  <div className="flex items-center justify-between mt-6">
                    <p className="text-sm sm:text-lg">You are buying</p>
                    <ArrowsUpDownIcon
                      className="h-5 w-5 sm:h-6 sm:w-6 mr-12 cursor-pointer"
                      aria-hidden="true"
                      onClick={swapTokensUI}
                    />
                  </div>
                  <div className="flex flex-row items-center justify-between mt-6 gap-[5px]">
                    <p className="text-xl sm:text-4xl">
                      {formattedQuote?.outputAmount === "0.0000"
                        ? ""
                        : Number(formattedQuote?.outputAmount).toLocaleString()}
                    </p>
                    <TokenListModal
                      showStatus={openBuyTokenModal}
                      setShowStatus={setOpenBuyTokenModal}
                      isBottomToken={true}
                      selectedToken={outputToken}
                      setSelectedToken={(token) => {
                        handleTokenSelect(token, false)
                      }}
                    />
                  </div>
                  <div className="w-full flex flex-row gap-2">
                    <button
                      onClick={() => {
                        handleOnboarding(2)
                      }}
                      className="bg-[#aaa] mt-6 w-full rounded-md text-sm sm:text-xl py-2 sm:py-4 font-bold mb-6"
                    >
                      Back
                    </button>
                    <button
                      disabled={!isActive || isSwapping}
                      onClick={() => {
                        setFlag(1)
                        //
                      }}
                      className={classNames(
                        isActive
                          ? theme.currentTheme === "dogTheme"
                            ? "bg-dogTheme-primary"
                            : theme.currentTheme === "lightTheme"
                            ? "bg-lightTheme-gradient"
                            : "bg-darkTheme-gradient"
                          : "bg-[#ddd]",
                        "mt-6 w-full rounded-md text-sm sm:text-xl py-2 sm:py-4 font-bold mb-6"
                      )}
                    >
                      Review Order
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {flag === 1 && (
            <div>
              <div className="flex flex-col gap-3 justify-center items-center text-center">
                <div className="w-[80%] flex flex-row justify-between pt-5 relative">
                  <div className="w-3 h-3 rounded-full bg-[#555555] z-10"></div>
                  <div className="w-3 h-3 rounded-full bg-[#555555] z-10"></div>
                  <div className="w-3 h-3 rounded-full bg-dogTheme-primary z-10"></div>
                  <div className="w-full h-[2px] absolute bg-[#555555] top-[80%] z-0"></div>
                </div>
                <div className="md:text-2xl text-xl py-3">STEP 3 - CONFIRM</div>
              </div>
              <div>
                <div className="mx-4">
                  <p className="mt-3 sm:mt-6 text-sm sm:text-lg">
                    You are selling
                  </p>
                  <div className="flex flex-row items-center justify-between mt-6 gap-[5px]">
                    <p className="text-xl sm:text-4xl">
                      {formattedQuote?.inputAmount === "0.0000"
                        ? ""
                        : Number(formattedQuote?.inputAmount).toLocaleString()}
                    </p>
                    <div className="flex items-center rounded-md p-2">
                      <img
                        alt="Token Logo"
                        src={inputToken?.logo}
                        className="rounded-full sm:h-10 sm:w-10 w-6 h-6"
                      />
                      <span className="font-bold ml-4">
                        {inputToken?.symbol}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-6">
                    <p className="mt-3 sm:mt-6 text-sm sm:text-lg">
                      You are buying
                    </p>
                  </div>
                  <div className="flex flex-row items-center justify-between mt-6 gap-[5px]">
                    <p className="text-xl sm:text-4xl">
                      {Number(formattedQuote?.outputAmount).toLocaleString()}
                    </p>

                    <div className="flex items-center rounded-md p-2">
                      <img
                        alt="Token Logo"
                        src={outputToken?.logo}
                        className="rounded-full sm:h-10 sm:w-10 w-6 h-6"
                      />
                      <span className="font-bold ml-4">
                        {outputToken?.symbol}
                      </span>
                    </div>
                  </div>
                  <p className="mt-3 sm:mt-6 text-xs sm:text-sm text-center">
                    Platform fee:{" "}
                    {Number(formattedQuote?.platformFeeBps).toLocaleString()}{" "}
                    bps | Fee Amount: {formattedQuote.feeAmount}
                  </p>
                  <p className="mt-3 sm:mt-6 text-xs sm:text-sm text-center">
                    priceImpactPct: {formattedQuote?.priceImpactPct}
                  </p>

                  <div className="flex gap-2">
                    <button
                      disabled={isSwapping}
                      onClick={() => {
                        setFlag(0)
                      }}
                      className="bg-[#888888] mt-6 w-full rounded-md text-sm sm:text-xl py-2 sm:py-4 font-bold mb-6"
                    >
                      Back
                    </button>
                    {!isSuccess && (
                      <button
                        disabled={isSwapping}
                        onClick={() => {
                          startSwapTokens(true).then((res) => {
                            if (res) {
                              // navigate("/dashboard")
                            }
                          })
                        }}
                        className={classNames(
                          theme.currentTheme === "dogTheme"
                            ? "bg-dogTheme-primary"
                            : theme.currentTheme === "lightTheme"
                            ? "bg-lightTheme-gradient"
                            : "bg-darkTheme-gradient",
                          "mt-6 w-full rounded-md text-sm sm:text-xl py-2 sm:py-4 font-bold mb-6 flex justify-center items-center"
                        )}
                      >
                        {isSwapping ? (
                          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-dogTheme-textStrongColor"></div>
                        ) : (
                          <>{swapButtonLoadingText}</>
                        )}
                      </button>
                    )}
                    {isSuccess && (
                      <button
                        disabled={isSwapping}
                        onClick={() => {
                          navigate("/dashboard")
                        }}
                        className={classNames(
                          theme.currentTheme === "dogTheme"
                            ? "bg-dogTheme-primary"
                            : theme.currentTheme === "lightTheme"
                            ? "bg-lightTheme-gradient"
                            : "bg-darkTheme-gradient",
                          "mt-6 w-full rounded-md text-sm sm:text-xl py-2 sm:py-4 font-bold mb-6"
                        )}
                      >
                        Success
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {/*response?.message && (
                <p className="text-green-500 text-center pb-3">
                  {response.message}{" "}
                  {response?.elapsedTime &&
                    `(${response.elapsedTime} seconds elapsed)`}
                  .
                </p>
              )*/}
              {response?.error && (
                <p className="text-red-500 text-center pb-3">
                  {response.error}
                  <br />
                  {response.details}
                </p>
              )}
              {/* <p>{statusText}</p>
          <p>DEBUG</p>
          <p>
            formated quote:
            {JSON.stringify(formattedQuote)}
          </p>
          <p>
            response:
            {JSON.stringify(response)}
          </p> */}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SwapInterface
