import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ArrowsUpDownIcon,
  ArrowsRightLeftIcon,
  HomeIcon,
  InformationCircleIcon,
  ChartBarIcon,
} from "@heroicons/react/20/solid";
import { TokenListModal } from "../../components/TokenListModal";
import { approvedTokens } from "../../constant";
import useSwap from "../../hooks/useSwap";
import { CheckCircleIcon, ArrowPathIcon } from "@heroicons/react/20/solid";
import DebouncedInput from "./Debounce";
import { useTheme } from "../../context/useTheme";
import {
  ReviewOrderButton,
  BackButton,
  SubmitSwapButton,
} from "../../components/Buttons";
import React from "react";
import Keypad from "./Keypad";
import { AmountTabs } from "./AmountTabs";
import { QuoteResponseView } from "./QuoteResponseView";
import { AvailableBalance } from "./AvailableBalance";
import { WalletAddress } from "./WalletAddress";
import { fetchWallets } from "../../hooks/functions";

const SwapInterface = ({
  setBuyToken,
  account,
  user,
  buyToken,
  isMobile,
  setShowCard,
  showcard,
  setLoading,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { tradeId } = useParams(); // Destructure the parameter from useParams

  const [openTokenListModal, setOpenTokenListModal] = useState(false);
  const [openBuyTokenModal, setOpenBuyTokenModal] = useState(false);
  // const [showKeypad, setShowKeypad] = useState(false)
  const [addresses, setAddresses] = useState([
    { address: account.address, index: 0 },
  ]);
  const [selected, setSelected] = useState(0);

  const init = async () => {
    handleTokenSelect(approvedTokens[0], true); // true means sell token
    if (tradeId) {
      const token = approvedTokens.find((token) => token.contract === tradeId);
      if (token) {
        handleTokenSelect(token, false); // false here means buy token
        setBuyToken(token);
        return;
      }
    }

    handleTokenSelect(approvedTokens[4], false); // false here means buy token
    setBuyToken(approvedTokens[4]);
  };

  useEffect(() => {
    init();
    if (isMobile) {
      setLoading(false);
    }
    // Function to handle window resize event
    return setSteps(0);
  }, []);

  useEffect(() => {
    const fetchWalletAddresses = async () => {
      const data = await fetchWallets(user.uid, account.address);
      setAddresses(data.wallets);
    };

    fetchWalletAddresses();
  }, [user, account]);

  const {
    inputToken,
    outputToken,
    usdValue,
    usdValues,
    response,
    handleMaxAmount,
    handleUsdAmountChange,
    handleTokenSelect,
    swapTokensUI,
    startSwapTokens,
    isSwapping,
    formattedQuote,
    gettingQuote,
    isActive,
    steps,
    setSteps,
    resetSwapInterface,
  } = useSwap(addresses[selected]["address"], selected);

  // const handleMobileInputClick = () => {
  //   // Custom interface logic for mobile devices
  //   setShowKeypad(true)
  //   console.log("Use custom interface for mobile input")
  // }

  return (
    <div className="w-full md:w-[400px] min-h-[550px] md:min-h-[650px] rounded-xl overflow-y-auto md:overflow-hidden flex flex-col px-4 pb-6 bg-panelColor-light dark:bg-panelColor-dark text-textColor-light dark:text-textColor-dark">
      {steps === 0 && (
        <>
          <div className="flex justify-between md:justify-end items-center px-2 py-1 sm:py-2">
            <div className="flex gap-2 pt-4">
              {theme.isMobile && (
                <>
                  <InformationCircleIcon
                    className={`h-6 w-6 mr-2 text-xs md:hidden hover:cursor-pointer`}
                    aria-hidden="true"
                    onClick={() => {
                      setShowCard(!showcard);
                    }}
                  />

                  <HomeIcon
                    className={`h-6 w-6 mr-2 text-xs md:hidden hover:cursor-pointer`}
                    aria-hidden="true"
                    onClick={() => {
                      navigate("/app");
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <div className="w-full flex-grow flex flex-col">
            <div className="flex justify-center items-center">
              <span className="text-4xl text-right"></span>
              {/*Debounce so there is a delay when sending the quote for input typing*/}
              <DebouncedInput
                handleAmountChange={handleUsdAmountChange}
                amount={usdValue}
                disabled={isSwapping}
              />
            </div>
            <AvailableBalance
              inputToken={inputToken}
              response={response}
              isLoading={isSwapping}
            />
            <div className="pt-3">
              <WalletAddress
                addresses={addresses}
                handleSelected={setSelected}
              />
            </div>

            <div className="flex-grow flex flex-col">
              <p className="mt-8 sm:mt-6 text-sm sm:text-lg flex justify-between">
                You are selling
              </p>
              <div className="flex flex-row items-center justify-between gap-[5px]">
                <QuoteResponseView
                  gettingQuote={gettingQuote}
                  amount={formattedQuote?.inputAmount}
                  usdValues={usdValues["inputToken"]}
                  viewMode={1}
                />

                <TokenListModal
                  showStatus={openTokenListModal}
                  isBottomToken={false}
                  setShowStatus={setOpenTokenListModal}
                  selectedToken={inputToken}
                  setSelectedToken={(token) => handleTokenSelect(token, true)}
                  walletAddress={addresses[selected]["address"]}
                />
              </div>
              <div className="flex items-center justify-between mt-3">
                <p className="text-sm sm:text-lg">You are buying</p>
                <div className="py-2">
                  <ArrowsUpDownIcon
                    className="h-5 w-5 sm:h-6 sm:w-6 mr-12 cursor-pointer"
                    aria-hidden="true"
                    onClick={swapTokensUI}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center justify-between mt-6 gap-[5px]">
                <QuoteResponseView
                  gettingQuote={gettingQuote}
                  amount={formattedQuote?.outputAmount}
                  viewMode={1}
                />
                <TokenListModal
                  showStatus={openBuyTokenModal}
                  isBottomToken={false}
                  setShowStatus={setOpenBuyTokenModal}
                  selectedToken={outputToken}
                  setSelectedToken={(token) => {
                    handleTokenSelect(token, false);
                    setBuyToken(token);
                  }}
                  walletAddress={addresses[selected]["address"]}
                />
              </div>
              <AmountTabs
                handleMaxAmount={handleMaxAmount}
                handleUsdAmountChange={handleUsdAmountChange}
              />
              {/* {showKeypad && (
                <div
                  className={classNames(
                    theme.currentTheme === "dogTheme"
                      ? "bg-dogTheme-bgColor"
                      : theme.currentTheme === "lightTheme"
                      ? "bg-lightTheme-bgColor"
                      : "bg-darkTheme-bgColor",
                    "absolute top-0 left-0 w-full h-full z-[99999] flex items-center justify-center"
                  )}
                >
                  <Keypad
                    handleAmountChange={handleUsdAmountChange}
                    amount={usdValue}
                    setShowKeypad={setShowKeypad}
                  >
                    <DebouncedInput
                      handleAmountChange={handleUsdAmountChange}
                      amount={usdValue}
                      onMobileInputClick={() => setShowKeypad(false)}
                      readOnly={true}
                    />
                    <AvailableBalance
                      inputToken={inputToken}
                      response={response}
                      isLoading={isSwapping}
                    />
                  </Keypad>
                </div>
              )} */}

              <div className="flex flex-col flex-grow justify-end md:p-0 mt-2">
                <ReviewOrderButton
                  disabled={!isActive || isSwapping}
                  onClick={() => {
                    setSteps(1);
                    //
                  }}
                  className={`${
                    isActive
                      ? "bg-themeColor-light dark:bg-themeColor-dark text-btnTextColor-light dark:text-btnTextColor-dark"
                      : "bg-[#888888] text-black cursor-not-allowed"
                  }`}
                >
                  Review Order
                </ReviewOrderButton>
              </div>
            </div>
          </div>
        </>
      )}
      {steps === 1 && (
        <>
          <p className="mt-8 text-lg sm:text-xl">You are selling</p>
          <div className="flex flex-row items-end justify-between mt-3 ml-3">
            <QuoteResponseView
              gettingQuote={gettingQuote}
              amount={formattedQuote?.inputAmount}
              usdValues={usdValues["inputToken"]}
              viewMode={2}
            />
            <div className="flex items-center">
              <img
                alt="Token Logo"
                src={inputToken?.logo}
                className="rounded-full sm:h-10 sm:w-10 w-8 h-8"
              />
              <span className="font-bold ml-4 text-xl">
                {inputToken?.symbol}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mt-3">
            <p className="mt-3 sm:mt-6 text-lg sm:text-xl">You are buying</p>
          </div>
          <div className="flex flex-row items-end justify-between mt-3 ml-3">
            <QuoteResponseView
              gettingQuote={gettingQuote}
              amount={formattedQuote?.outputAmount}
              usdValues={usdValues["outputToken"]}
              viewMode={2}
            />

            <div className="flex items-center">
              <img
                alt="Token Logo"
                src={outputToken?.logo}
                className="rounded-full sm:h-10 sm:w-10 w-8 h-8"
              />
              <span className="font-bold ml-4 text-xl">
                {outputToken?.symbol}
              </span>
            </div>
          </div>
          <div className="flex flex-col mt-3">
            <p className="mt-3 sm:mt-6 text-sm font-semibold sm:text-lg flex justify-between">
              <span>Platform fee: </span>
              <span>{Number(formattedQuote?.platformFeeBps) / 100} %</span>
            </p>
            <p className="mt-3 ml-3 sm:mt-6 text-sm sm:text-[16px] flex justify-between">
              <span>Network fee:</span> <span>{formattedQuote.feeAmount}</span>
            </p>
            <p className="mt-3 ml-3 sm:mt-6 text-sm sm:text-[16px] flex justify-between">
              <span>Price Impact %: </span>
              <span>{formattedQuote?.priceImpactPct}%</span>
            </p>
            <p className="mt-3 sm:mt-6 text-sm sm:text-lg font-semibold flex justify-between">
              <span>Time to complete: </span>
              <span>30s</span>
            </p>
          </div>

          <div className="gap-2 flex-grow flex flex-col justify-end">
            <div className="flex flex-row gap-2 mt-6">
              <BackButton
                disabled={isSwapping}
                onClick={() => {
                  setSteps(0);
                }}
              >
                Back
              </BackButton>

              <SubmitSwapButton
                disabled={isSwapping}
                onClick={() => {
                  startSwapTokens(true);
                }}
                className="bg-themeColor-light dark:bg-themeColor-dark text-btnTextColor-light dark:text-btnTextColor-dark"
              >
                {isSwapping ? (
                  <div
                    className={`animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white`}
                  ></div>
                ) : (
                  "Swap Tokens"
                )}
              </SubmitSwapButton>
            </div>
          </div>
          {response?.error && (
            <p className="text-red-500 text-center pb-3">{response.error}</p>
          )}
        </>
      )}
      {steps === 2 && (
        <>
          <div className="flex justify-center mt-5">
            <CheckCircleIcon className="w-[80px] " />
          </div>
          <p className="text-center my-8 text-xl">
            Your Order Was Sent Successfully
          </p>
          <div className="flex flex-col items-center justify-center gap-2">
            <p>
              <span className="text-sm">You sold </span>
              <span className="text-lg">
                {formattedQuote?.inputAmount} {inputToken?.symbol}
              </span>
            </p>
            <p>
              <span className="text-sm">You will receive </span>
              <span className="text-lg">
                {formattedQuote?.outputAmount} {outputToken?.symbol}
              </span>
            </p>
          </div>
          <p className="text-center my-7 text-sm px-10">
            You can navigate away from here, we will let you know when your tx
            is confirmed.
          </p>

          <div className="gap-2 flex-grow flex flex-col justify-end w-full">
            <div className="flex flex-row gap-2 mt-6">
              <BackButton
                disabled={isSwapping}
                className="bg-themeColor-light dark:bg-themeColor-dark text-btnTextColor-light dark:text-btnTextColor-dark"
                onClick={() => {
                  // navigate("/app/trade")
                  window.location.reload();
                }}
              >
                <div className="flex justify-center items-center gap-2">
                  <ArrowsRightLeftIcon className="h-6 w-6" />
                  Go back to Trade
                </div>
              </BackButton>

              {/* <BackButton
                disabled={isSwapping}
                onClick={() => {
                  resetSwapInterface()
                }}
              >
                Back
              </BackButton> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SwapInterface;
