import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../utils/firebase";
import { images } from "../../assets";
import { Spinner } from "../../components/Spinner";

export const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(false);
  const navigate = useNavigate();

  const sendResetEmail = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setIsSent(true);
    } catch (error) {
      console.error("Error sending password reset email: ", error);
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      sendResetEmail();
    }
  };

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      {/* Desktop View */}
      <div className="bg-white overflow-y-auto flex-1">
        <div className="relative isolate gradient min-h-screen">
          <div className="lg:px-8 px-6">
            <div className="mx-auto max-w-2xl pt-16 sm:pt-20">
              <div className="flex items-center justify-center">
                <img src={images.MemexLogo} alt="Memex" className="h-16 mb-3" />
              </div>
              <div className="text-center block">
                <h1 className="text-5xl font-bold text-black leading-[60px]">
                  Sign into your <br />
                  Memex account
                </h1>
              </div>
            </div>

            {/* Auth box */}
            <div className="mt-4 border-x border-t rounded-md bg-[#010101] opacity-[0.65] max-w-sm mx-auto">
              <div className="text-gray-100 py-2 p-6 mx-auto min-h-[250px]">
                <p className="text-xl imd:text-2xl text-center py-3">
                  Forgot your password?
                </p>
                <p className="text-white text-base pt-5 text-left ml-2">
                  Email
                </p>
                <div className="flex mt-3 border border-white rounded-md justify-between items-center">
                  <input
                    type="email"
                    className="rounded-md pl-6 bg-transparent w-full focus:border-[#010101] focus:shadow-none focus:ring-0 border-none text-white"
                    placeholder="achithebest@example.com"
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyUp={handleKeyUp}
                  />
                </div>
                {isSent && (
                  <p className="my-5 text-center">
                    The email has been sent. Check your Inbox!
                  </p>
                )}
                <div className="w-full flex justify-center gap-2 pt-2">
                  <button
                    className="mt-3 text-black text-base bg-[#f7f9f9] hover:bg-[#ffffff]/80 rounded-full w-full py-2 max-w-[120px]"
                    onClick={() => navigate("/sign-in")}
                  >
                    Back
                  </button>
                  <button
                    className="mt-3 text-base bg-themeColor-light dark:bg-themeColor-dark text-btnTextColor-light dark:text-btnTextColor-dark hover:bg-opacity-80 dark:hover:bg-opacity-80 rounded-full w-full py-2 max-w-[120px]"
                    onClick={sendResetEmail}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
            {/* end auth box */}
          </div>
        </div>
      </div>
      {/* End Desktop View */}

      {/* loading spinner */}
      {loading && <Spinner loading={loading} />}
      {/* End loading spinner */}
    </div>
  );
};
