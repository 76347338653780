import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../../utils/firebase";
import { updateName } from "../../hooks/functions";
import { images } from "../../assets";
import { SignInButtons } from "../../components/SignInWith";

export const SignupForm = ({
  openTermsModal,
  setOpenTermsModal,
  loading,
  setLoading,
  referralCode,
}) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (isChecked) setOpenTermsModal(true);
  }, [isChecked]);

  useEffect(() => {
    //check if passwords match
    if (confirmPassword !== "" && password !== "") {
      password !== confirmPassword
        ? setError("Password and confirm password don't match!")
        : setError("");
    }
  }, [password, confirmPassword]);

  const handleCheckBox = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = async () => {
    setError("");
    if (email.trim() === "") {
      console.log("Email field is empty");
      setError("Email field is empty");
      return;
    }
    if (password !== confirmPassword) {
      console.log("Password and confirm password don't match!");
      setError("Password and confirm password don't match!");
      return;
    }
    if (!isChecked) {
      console.log("You have to agree with Terms & Service agreement");
      setError("You have to agree with Terms & Service agreement");
      return;
    }

    setLoading(true);

    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        await updateName(firstName, lastName);
        await sendEmailVerification(auth.currentUser);

        //update name
        setLoading(false);
        navigate("/verification");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setLoading(false);
        console.log(errorCode, errorMessage);
        setError(errorMessage);
      });
  };

  return (
    <div className="px-4 pb-36 md:pb-0">
      {referralCode && (
        
          <div className="text-gray-100 p-6 mx-auto">
            <p className="text-center text-sm font-semibold">
              Referral Code: {referralCode}
            </p>
          </div>
        
      )}
      <p className="pt-5 text-white flex">First name</p>
      <div className="flex border rounded-md border-solid border-white mt-2">
        <input
          type="text"
          className="flex bg-transparent py-2 w-full text-white focus:border-[#010101] focus:shadow-none focus:ring-0 border-none"
          placeholder="First name"
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <p className="pt-5 text-white flex">Last name</p>
      <div className="flex border rounded-md border-solid border-white mt-2">
        <input
          type="text"
          className="flex bg-transparent py-2 w-full text-white focus:border-[#010101] focus:shadow-none focus:ring-0 border-none"
          placeholder="Last name"
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <p className="pt-5 text-white flex">Email</p>
      <div className="flex border rounded-md border-solid border-white mt-2">
        <input
          type="email"
          className="flex bg-transparent py-2 w-full text-white focus:border-[#010101] focus:shadow-none focus:ring-0 border-none"
          placeholder="achithebest@example.com"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <p className="pt-5 text-white flex">Password</p>
      <div className="flex border rounded-md border-solid border-white mt-2">
        <input
          type="password"
          className="flex bg-transparent py-2 w-full text-white focus:border-[#010101] focus:shadow-none focus:ring-0 border-none"
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <p className="pt-5 text-white flex">Confirm password</p>
      <div className="flex border rounded-md border-solid border-white mt-2">
        <input
          type="password"
          className="flex bg-transparent py-2 w-full text-white focus:border-[#010101] focus:shadow-none focus:ring-0 border-none"
          placeholder="Confirm password"
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </div>
      {error && <p className="text-red-700 pt-3 text-center">{error}</p>}
      <div
        className="flex gap-1 justify-start items-start px-2 pt-2"
        onClick={handleCheckBox}
      >
        <input
          type="checkbox"
          className="mt-1 border-0 outline-0 focus:shadow-none focus:outline-0 focus:ring-0 focus:border-0 hover:cursor-pointer"
          checked={isChecked}
          onChange={handleCheckBox}
        />
        <label className="hover:cursor-pointer text-white">
          I agree I have read this Terms & Service agreement
        </label>
      </div>
      <div className="flex gap-3">
        <button
          className="text-black bg-[#f7f9f9] hover:bg-[#ffffff]/80 rounded-full py-2 w-full mt-4"
          onClick={() => {
            navigate("/");
          }}
        >
          Back
        </button>
        <button
          className="text-white bg-themeColor-light hover:bg-themeColor-light/70 rounded-full py-2 w-full mt-4"
          onClick={handleSubmit}
        >
          Create
        </button>
      </div>

      <p className="text-center pt-4 text-white">
        Already have an account?{" "}
        <span
          className="hover:underline cursor-pointer text-themeColor-light"
          onClick={() => navigate("/sign-in")}
        >
          Sign In
        </span>
      </p>

      <div className="mt-3 flex items-center justify-between">
        <img src={images.Line} alt="Line" className="w-24 h-auto" />
        <p className="text-white">Or</p>
        <img src={images.Line} alt="Line" className="w-24 h-auto" />
      </div>

      <SignInButtons />
    </div>
  );
};
