import { useState, useEffect } from "react";
import { db, auth } from "../../utils/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { BotCard } from "./BotCard";
import { CreateBot } from "./CreateBot";
import { AddToken } from "./AddToken";
import { ClaimTokens } from "./ClaimTokens";
import { BotLoader } from "../../components/ContentLoader";
import { updateBotBalances } from "../../hooks/functions";
import { getApprovedTokens } from "../../hooks/handleApprovedTokens";

export const Bot = () => {
  const [statusMessage, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [botList, setBotList] = useState({ bots: [] });
  const [approvedTokensList, setApprovedTokensList] = useState({});

  useEffect(() => {
    let isMounted = true;
    const fetchTokens = async () => {
      const res = await getApprovedTokens();
      if (isMounted) setApprovedTokensList(res);
    };

    fetchTokens();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    const currentUser = auth.currentUser;
    const pathToDocuments = `bots/${currentUser?.uid}`;
    const unsubscribe = onSnapshot(
      doc(db, pathToDocuments),
      (querySnapshot) => {
        let data = querySnapshot.data();
        let bots = { bots: [] };
        if (data === undefined || data === null) {
          setBotList(bots);
        } else if (Object.keys(data).length === 0) {
          setBotList(bots);
        } else {
          setBotList(data);
        }
        setLoading(false);
      }
    );

    // updateBotBalances();

    return unsubscribe;
  }, []);

  return (
    <div className="flex flex-col w-full gap-4">
      {loading ? (
        <BotLoader />
      ) : (
        <>
          {/* Buttons */}
          <div className="w-full flex flex-col lp:flex-row gap-3 items-center">
            <CreateBot handleStatusMessage={setStatusMessage} />
            {auth.currentUser?.uid === "34e0ofLHx6Od3CXOD7Ayy26cUU83" && (
              <>
                <AddToken />
                <ClaimTokens />
              </>
            )}
          </div>

          {/* Status Message */}
          <div className="text-center text-[14px] text-green-500">
            {statusMessage}
          </div>

          {/* Bots */}
          <div className="w-full grid grid-cols-1 lp:grid-cols-2 tb:grid-cols-3 pc:grid-cols-4 gap-4 justify-items-center items-center">
            {botList.bots.length > 0 &&
              botList.bots.map((item, index) => (
                <BotCard
                  key={index}
                  info={item}
                  tokenList={approvedTokensList}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};
