import axios from 'axios';
import { useEffect, useState } from 'react';

export const useTokenInfo = (contract) => {
    const [tokenInfo, setTokenInfo] = useState({
        mint: "",
        tokenMetadata: {
            offChainInfo: {
                description: "",
                extensions: {
                    website: "",
                    discord: "",
                    twitter: "",
                    telegram: "",
                },
            },
        },
    });

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!contract) {
            setLoading(false);
            return;
        }

        const source = axios.CancelToken.source();

        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.get(`https://api.solana.fm/v1/tokens/${contract}`,                
                {
                    cancelToken: source.token,
                });                
                setTokenInfo(response.data);
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.error("Error fetching token info:", error);
                    setError(error);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        return () => {
            source.cancel('Component unmounted or contract changed');
        };
    }, [contract]);

    return { tokenInfo, loading, error };
};
