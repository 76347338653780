import { useState, useEffect } from "react"
import { swapTokens, getBalanceDirect, sendTransaction } from "./functions"
import { formatTokenValue } from "../utils/formats"
import { approvedTokens } from "../constant"
import axios from "axios"
import { useAuth } from "../context/useAuth"

const useSwapWidget = (account, iTokenIndex = 0, oTokenIndex = 4) => {
  const [inputToken, setInputToken] = useState(approvedTokens[iTokenIndex])
  const [outputToken, setOutputToken] = useState(approvedTokens[oTokenIndex])
  const [amount, setAmount] = useState("")
  const [usdValues, setUsdValues] = useState({ inputToken: 0, outputToken: 0 }) // Add this line
  const [usdValue, setUsdValue] = useState("")
  const [quote, setQuote] = useState(null)
  const [formattedQuote, setFormattedQuote] = useState({
    inputAmount: "0.0000",
    outputAmount: "0.0000",
    slippageBps: "",
    platformFeeBps: "",
    feeAmount: "",
    priceImpactPct: "",
  }) // Add this line
  const [response, setResponse] = useState({})
  const [swapButtonLoadingText, setSwapButtonLoadingText] =
    useState("Swap Tokens")
  const [gettingQuote, setGettingQuote] = useState(false) // Add this line
  const [isSwapping, setIsSwapping] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const auth = useAuth()
  const [steps, setSteps] = useState(0)

  const resetSwapInterface = () => {
    handleTokenSelect(approvedTokens[iTokenIndex], true)
    setOutputToken(approvedTokens[oTokenIndex])
    setAmount("")
    setUsdValue("")
    setUsdValues({ inputToken: 0, outputToken: 0 })
    setQuote(null)
    setFormattedQuote({
      inputAmount: "0.0000",
      outputAmount: "0.0000",
      slippageBps: "",
      platformFeeBps: "",
      feeAmount: "",
      priceImpactPct: "",
    })
    setResponse({})
    setSwapButtonLoadingText("Swap Tokens")
    setGettingQuote(false)
    setIsSwapping(false)
    setIsSuccess(false)
    setIsActive(false)
    setSteps(0)
  }

  const getSolanaBalance = async () => {
    const balance = await getBalanceDirect(account.address, "sol", "sol")
    if (balance.amount == 0) {
      setIsActive(false)
      setResponse({ error: "You need more Solana" })
    }
  }

  const initialLoad = async () => {
    // await getSolanaBalance()
    await handleTokenSelect(inputToken, true)
    await handleTokenSelect(outputToken, false)
  }

  useEffect(() => {
    initialLoad()
  }, [])

  // useEffect(() => {
  //   if (Number(inputToken.balance) < Number(formattedQuote.inputAmount)) {
  //     setIsActive(false)
  //     setResponse({ error: "Insufficient balance" })
  //   } else if (Number(amount) <= 0) {
  //     setIsActive(false)
  //     Number(amount) !== 0 && setResponse({ error: "Invalid amount" })
  //   } else {
  //     setIsActive(true)
  //     setResponse({})
  //   }
  // }, [formattedQuote, usdValue])

  const jupiterApiUrl = "https://quote-api.jup.ag/v6/"

  const formatQuote = (quote) => {
    if (quote) {
      const inputAmount = formatTokenValue({
        amount: quote.inAmount,
        decimals: inputToken.decimals,
      })
      const outputAmount = formatTokenValue({
        amount: quote.outAmount,
        decimals: outputToken.decimals,
      })

      const feeAmount = formatTokenValue({
        amount: quote.platformFee?.amount,
        decimals: outputToken.decimals,
      })

      let feeAmountString = ""

      if (feeAmount != "0.0000") {
        feeAmountString = `${feeAmount} ${outputToken.symbol}`
      }

      let formattedPriceImpact = quote.priceImpactPct || 0

      formattedPriceImpact = formattedPriceImpact * 100
      formattedPriceImpact = formattedPriceImpact.toFixed(2)

      const formattedQuote = {
        inputAmount,
        outputAmount,
        slippageBps: quote.slippageBps,
        platformFeeBps: quote?.platformFee?.feeBps || 0,
        feeAmount: feeAmountString,
        priceImpactPct: formattedPriceImpact,
      }
      setFormattedQuote(formattedQuote)
    }
  }

  const fetchSwapQuote = async (
    inputMint,
    outputMint,
    amountInSmallestUnit,
    slipBps
  ) => {
    if (Number(amountInSmallestUnit) <= 0) {
      return
    }
    if (!inputMint || !outputMint) {
      return
    }
    setGettingQuote(true)
    const platformFeeBps = 2
    let quoteUrl = `${jupiterApiUrl}quote?inputMint=${inputMint}&outputMint=${outputMint}&amount=${amountInSmallestUnit}&slippageBps=${slipBps}&onlyDirectRoutes=false&asLegacyTransaction=false&platformFeeBps=${platformFeeBps}`
    try {
      const { data } = await axios.get(quoteUrl)
      setQuote(data)
      formatQuote(data)
      setGettingQuote(false)
      return data
    } catch (error) {
      console.error("Error fetching swap quote:", error)
      setQuote(null)
      setGettingQuote(false)
      throw error // Rethrow to handle it in the calling context
    }
  }

  const handleMaxAmount = async () => {
    if (inputToken) {
      handleAmountChange({ target: { value: inputToken.balance, mode: "max" } })
      const inputUsdValue = inputToken.usdPrice * inputToken.balance || 0.0
      setUsdValue(inputUsdValue.toFixed(2))
    }
  }

  const handleUsdAmountChange = async (event) => {
    let value = event.target.value || 0
    value = Number(value.toString().replaceAll(",", ""))

    if (isNaN(value)) return

    // if (Object.keys(account).length === 0) return

    // Ensure value is a string
    value = value.toString()

    // Check if value starts with '0' and the second character is not a '.'
    if (value.startsWith("0") && value.length > 1 && value[1] !== ".") {
      value = value.replace(/^0+/, "") // Remove leading zeros
    }

    const response = await axios.get(
      `https://api.dexscreener.com/latest/dex/tokens/${inputToken.contract}`
    )

    const usdPrice = response.data.pairs[0].priceUsd

    let inputTokenUsdPrice = usdPrice || 0.0
    let inputTokens = parseFloat(value) / inputTokenUsdPrice
    inputTokens = Math.round(inputTokens * 10000) / 10000
    setUsdValue(value)
    handleAmountChange({ target: { value: inputTokens, mode: "custom" } })
  }

  const handleAmountChange = async (event) => {
    let value = event.target.value || 0
    const mode = event.target.mode || "custom"
    setAmount(value) // Update the state with the new amount from the input
    // Only proceed if both tokens are defined and the value is greater than 0
    if (inputToken && outputToken && Number(value) > 0) {
      const decimals = inputToken.decimals
      let amountInSmallestUnit = value * Math.pow(10, decimals)
      // Calculate the amount in the smallest unit based on the token decimals
      amountInSmallestUnit = Math.round(amountInSmallestUnit)
      // if (mode === "max") {
      //   amountInSmallestUnit = inputToken.nativeBalance
      // }
      try {
        // Fetch the swap quote using provided token details and amount
        const quoteResponse = await fetchSwapQuote(
          inputToken.contract,
          outputToken.contract,
          amountInSmallestUnit,
          50 // Assuming this is some sort of deadline or slippage parameter
        )

        // Calculate and update USD values based on the fetched quote
        let inputUsdValue = inputToken.usdPrice * value || 0.0
        let outputUsdValue = 0.0

        const outputAmountInUnits =
          quoteResponse.outAmount / Math.pow(10, outputToken.decimals)

        outputUsdValue = outputToken.usdPrice * outputAmountInUnits
        //format usd values so that they are displayed with 2 decimal places
        inputUsdValue = inputUsdValue.toFixed(2)
        outputUsdValue = outputUsdValue.toFixed(2)

        setUsdValues({
          inputToken: inputUsdValue,
          outputToken: outputUsdValue,
        })

        if (quoteResponse && quoteResponse.outAmount) {
        } else {
          throw new Error("Invalid quote response")
        }
      } catch (error) {
        console.error("Error fetching quote:", error)
        setQuote(null)
      }
    } else {
      // Handle the scenario where input or output tokens are not properly defined or value is non-positive
      console.warn("Invalid tokens or amount")
      setQuote({
        inAmount: "0",
        outAmount: "0",
        platformFee: {
          amount: "0",
          feeBps: "",
        },
        priceImpactPct: "",
        slippageBps: "",
      })
      setIsSwapping(false)
    }
  }

  const handleTokenSelect = async (token, isInputToken = true) => {
    if (!token) {
      console.error("No token provided")
      return
    }
    setResponse({})
    handleUsdAmountChange({ target: { value: 0 } })
    setIsSwapping(true)

    // Update the selected token in the corresponding state
    if (isInputToken) {
      setInputToken(token)
    } else {
      setOutputToken(token)
    }

    // try {
    //   const tokenType = token.symbol === "SOL" ? "sol" : "spl"
    //   //use getBalance to use firebase functions, or getBalanceDirect to get it from the backend (faster)
    //   const balance = await getBalanceDirect(
    //     //await getBalance(
    //     account.address,
    //     token.contract,
    //     tokenType
    //   )

    //   if (balance && balance.decimals !== undefined) {
    //     token.balance = formatTokenValue({
    //       amount: balance.amount,
    //       decimals: balance.decimals,
    //     })
    //     token.nativeBalance = balance.amount
    //     token.usdPrice = balance.usdPrice || 0
    //   } else {
    //     token.balance = 0
    //     token.nativeBalance = 0
    //     console.warn("Invalid balance information for token:", token.symbol)
    //   }

    //   if (isInputToken) {
    //     setInputToken(token)
    //   } else {
    //     setOutputToken(token)
    //   }
    // } catch (error) {
    //   console.error("Failed to select token:", error)
    // }

    //

    setIsSwapping(false)
  }

  const updateBalances = async () => {
    if (inputToken) await handleTokenSelect(inputToken, true)
    if (outputToken) await handleTokenSelect(outputToken, false)
  }

  const swapTokensUI = async () => {
    if (isSwapping) return
    setIsSwapping(true)
    const tempToken = inputToken
    setOutputToken(inputToken)
    setInputToken(outputToken)
    await handleTokenSelect(outputToken, true)
    await handleTokenSelect(tempToken, false)
    setAmount("")
    setQuote(null)
    setIsSwapping(false)
    setResponse({})
  }

  const startSwapTokens = async (simulate) => {
    if (!inputToken || !outputToken || !amount || !quote) {
      console.error("Missing inputs for swap")
      return
    }

    setIsSwapping(true)

    setSwapButtonLoadingText(`${simulate ? "Simulating Swap" : "Swapping"}...`)

    try {
      const { txLink, responseMessage, status, base64SerializedTransaction } =
        await swapTokens(quote, account.address, simulate)

      if (status === "success" && simulate) {
        setResponse({ message: "Transaction simulation successful", txLink })
        console.log("Transaction simulation successful")
      }

      if (status === "failed" && simulate) {
        setSwapButtonLoadingText("Simulation Failed")

        //check responseMessage for error details. If it is empty, just show "Transaction simulation failed". If it says insufficient anywhere in the response, say "Insufficient balance of token" and show the token symbol. If it says "invalid" anywhere in the response, say "Invalid transaction. Please try again.
        if (responseMessage.toLowerCase().includes("insufficient")) {
          setResponse({
            error: `Insufficient balance`,
            details: responseMessage,
          })
        } else if (responseMessage.toLowerCase().includes("invalid")) {
          setResponse({
            error: "Invalid transaction. Please try again.",
            details: responseMessage,
          })
        } else {
          setResponse({
            error: "Transaction simulation failed",
            details: responseMessage,
          })
        }

        console.error("Transaction simulation failed:", responseMessage)

        /*setResponse({
          error: "Transaction simulation failed",
          details: responseMessage,
        });*/

        setIsSuccess(false)
        return false
      }

      if (base64SerializedTransaction) {
        setSwapButtonLoadingText("Swapping...")

        /*
        const response = await sendTransaction(base64SerializedTransaction, currentUser.uid, account.address);
        const txId = response?.transaction.signatures[0];
        
        if (response?.transaction.signatures[0]) {
          const txLink = `https://solscan.io/tx/${txId}`;

          //add 5 second delay to allow transaction to be confirmed

          setResponse({ message: `Transaction sent`, txLink: txLink });
          setIsSuccess(true);
          setSwapButtonLoadingText("Confirm Order");

          return true;
        } else {
          setSwapButtonLoadingText("Failed. Retry?");
          setResponse({ error: "Transaction failed", details: response });
          setIsSuccess(false);
          return false;
        }*/

        sendTransaction(
          base64SerializedTransaction,
          auth.currentUser.uid,
          account.address,
          quote
        )
        setIsSuccess(true)
        setSwapButtonLoadingText("Confirm Order")
        setSteps(2)
        setResponse({ message: `Transaction sent`, txLink: "" })

        //add 5 second delay to allow transaction to be confirmed
        /*setTimeout(() => {                    
          setSwapButtonLoadingText("Confirm Order");
        }, 2300);
        */
      }
    } catch (error) {
      console.error("Swap failed:", error)
      setResponse({ error: "Swap failed" })
      setSwapButtonLoadingText("Swap Failed")
    } finally {
      setIsSwapping(false)
    }
  }

  return {
    inputToken,
    outputToken,
    setInputToken,
    setOutputToken,
    amount,
    usdValue,
    setAmount,
    quote,
    response,
    swapButtonLoadingText,
    handleMaxAmount,
    handleAmountChange,
    handleUsdAmountChange,
    handleTokenSelect,
    swapTokensUI,
    startSwapTokens,
    updateBalances,
    isSwapping,
    isSuccess,
    isActive,
    formattedQuote,
    usdValues,
    gettingQuote,
    steps,
    setSteps,
    resetSwapInterface,
  }
}

export default useSwapWidget
