import { TrendingTokenBox } from "../../components/TrendingToken"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper/modules"
import { useState, useEffect } from "react"
import axios from "axios"
import "swiper/css"
import Heading from "../../components/Heading"
export const TrendingTokens = ({ isLoading, setLoading }) => {
  const [trendingTokens, setTrendingTokens] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(
        "https://us-central1-achi-inu.cloudfunctions.net/getTopTokensApi"
      )

      setTrendingTokens(res.data)
      setLoading(false)
    }
    fetchData()
  }, [])

  const slideTokensMd = (data) => {
    const groupedItems = []

    for (let i = 0; i < data.length; i += 6) {
      groupedItems.push(data.slice(i, i + 6))
    }

    return (
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {groupedItems.map((group, groupIndex) => (
          <SwiperSlide key={groupIndex}>
            {Array.from({ length: Math.ceil(group.length / 3) }).map(
              (_, rowIndex) => (
                <div
                  key={rowIndex}
                  className="w-full flex gap-5 justify-between py-1"
                >
                  {group
                    .slice(rowIndex * 3, rowIndex * 3 + 3)
                    .map((item, itemIndex) => (
                      <TrendingTokenBox
                        key={itemIndex}
                        name={item.name}
                        onBoxData={item.data}
                        logoUrl={item.logo}
                        symbol={item.symbol}
                      />
                    ))}
                </div>
              )
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    )
  }

  const slideTokensSm = (data) => {
    const groupedItems = []

    for (let i = 0; i < data.length; i += 6) {
      groupedItems.push(data.slice(i, i + 6))
    }

    return (
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {groupedItems.map((group, groupIndex) => (
          <SwiperSlide key={groupIndex}>
            {Array.from({ length: Math.ceil(group.length / 2) }).map(
              (_, rowIndex) => (
                <div
                  key={rowIndex}
                  className="w-full flex gap-5 justify-between py-1"
                >
                  {group
                    .slice(rowIndex * 2, rowIndex * 2 + 2)
                    .map((item, itemIndex) => (
                      <TrendingTokenBox
                        key={itemIndex}
                        name={item.name}
                        onBoxData={item.data}
                        logoUrl={item.logo}
                        symbol={item.symbol}
                      />
                    ))}
                </div>
              )
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    )
  }

  const slideTokensXs = (data) => {
    return (
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {data &&
          data.map((item, key) => {
            if (item)
              return (
                <SwiperSlide key={key}>
                  <div className="w-full flex justify-center">
                    <TrendingTokenBox
                      key={key}
                      name={item.name}
                      onBoxData={item.data}
                      logoUrl={item.logo}
                      symbol={item.symbol}
                    />
                  </div>
                </SwiperSlide>
              )
            return null
          })}
      </Swiper>
    )
  }

  return (
    <div className="sm:pb-3 w-full rounded-xl">
      {!isLoading && (
        <>
          <div className="w-full py-2 imd:py-4 px-2 imd:px-5">
            <Heading>Trending Tokens</Heading>
          </div>{" "}
          <div className="w-full hidden md:flex justify-center md:mb-0">
            {trendingTokens && slideTokensMd(trendingTokens)}
          </div>
          <div className="w-full hidden imd:flex md:hidden justify-center md:mb-0">
            {trendingTokens && slideTokensSm(trendingTokens)}
          </div>
          <div className="w-full flex imd:hidden justify-center md:mb-0">
            {trendingTokens && slideTokensXs(trendingTokens)}
          </div>
        </>
      )}
    </div>
  )
}
