import { useState, useEffect } from "react";
import { icons } from "../../assets";
import { getBalanceDirect } from "../../hooks/functions";

export const TokenListItem = ({
  item,
  walletAddress,
  handleSelectedToken,
  handleShowStatus,
}) => {
  const [balance, setBalance] = useState();
  const [loading, setLoading] = useState(false);
  // Fetch Balance
  useEffect(() => {
    const fetchBalance = async () => {
      try {
        setLoading(true);
        const tokenType = item.symbol === "SOL" ? "sol" : "spl";
        const data = await getBalanceDirect(
          walletAddress,
          item.contract,
          tokenType
        );
        if (data && data.decimals !== undefined) {
          setBalance(Number(data["amount"] / Math.pow(10, data["decimals"])));
        } else {
          setBalance(0);
        }
        setLoading(false);
      } catch (error) {
        setBalance(0);
        setLoading(false);
      }
    };
    fetchBalance();
  }, []);

  return (
    <div
      className="flex items-center justify-between pb-2 cursor-pointer border-[1px] border-transparent hover:border-white hover:rounded-md px-4"
      onClick={() => {
        handleSelectedToken(item);
        handleShowStatus(false);
      }}
    >
      <div className="flex items-center">
        <img
          alt="logo"
          src={item.logo}
          className="w-8 h-auto rounded-full mr-4"
        />
        <div>
          <p className="font-semibold text-start">{item.symbol}</p>

          {loading ? (
            <div className="animate-pulse">
              <div className="w-12 h-4 bg-gray-300 rounded"></div>
            </div>
          ) : (
            <p className="text-sm text-start">{balance}</p>
          )}
        </div>
      </div>
      <img alt="arrow" src={icons.GrayRightArrowIcon} className="w-4 h-auto" />
    </div>
  );
};
