
import { images } from "../../assets";
export  const AuthIntro = ({ isMobile }) => {
    return isMobile ? (
      <>
        <div className="flex items-center justify-center">
          <img src={images.MemexWhiteLogo} alt="Memex" className="h-10 mt-5 mb-3"/>
        </div>
        <p className="text-white text-2xl px-2">
          Sign into your <br /> Memex account.
        </p>
      </>
    ) : (
      <div className="mx-auto max-w-2xl pt-16 sm:pt-20">
        <div className="flex items-center justify-center">
          <img src={images.MemexLogo} alt="Memex" className="h-16 mb-3"/>
        </div>
        <div className="text-center">
          <h1 className="text-5xl font-bold text-black leading-[60px]">
            Sign into your <br />Memex account
          </h1>
        </div>
      </div>
    );
  };