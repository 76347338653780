import React, { useState, useCallback } from "react"
import { FiCheckCircle, FiXCircle } from "react-icons/fi"

const Amounts = ({
  txDetails,
  setTxDetails,
  validations,
  setValidations,
  balance,
  symbol,
}) => {
  const validateAmount = useCallback(
    (newAmount) => {
      if (newAmount <= 0 || newAmount === "") {
        setValidations((prev) => ({
          ...prev,
          amount: false,
          amountError: "Invalid amount",
        }))
      } else if (newAmount > balance) {
        setValidations((prev) => ({
          ...prev,
          amount: false,
          amountError: "Insufficient funds",
        }))
      } else {
        setValidations((prev) => ({
          ...prev,
          amount: true,
          amountError: "",
        }))
      }
    },
    [balance, setValidations]
  )

  const onChangeAmount = (e) => {
    const newAmount = e.target.value
    setTxDetails((prev) => ({
      ...prev,
      amount: newAmount,
      amountUSD: newAmount * txDetails.tokenPrice,
    }))
    validateAmount(newAmount)
  }

  return (
    <div className="mt-2 w-full relative">
      <input
        className="rounded-md bg-transparent focus:border-[#aaa] focus:shadow-none focus:ring-0 w-full text-[16px]"
        placeholder="Enter amount"
        onChange={onChangeAmount}
        value={txDetails.amount || ""}
        type="number"
        step="any"
        //className={validations.amount ? "input-valid" : "input-invalid"}
      />
      <div className="absolute top-[6px] right-[6px] h-[30px]">
        <span className="flex justify-center items-center py-1 text-white w-[30px] h-[30px] text-center">
          {validations.amount ? (
            <FiCheckCircle color="green" />
          ) : (
            <FiXCircle color="red" />
          )}
        </span>
      </div>
      {!validations.amount && (
        <div className="form-error-message">{validations.amountError}</div>
      )}
    </div>
  )
}

export default Amounts
