import { useState, useEffect } from "react";
import { getBalanceDirect } from "../../hooks/functions";

export const PercentageTabs = ({
  status,
  mode,
  handleAmount,
  baseToken,
  tokenList,
  walletAddress
}) => {
  const [balance, setBalance] = useState(0)
  useEffect(() => {
    const fetchBalance = async () => {
      const contract = tokenList[baseToken] ? tokenList[baseToken]["contract"] : "";
      const tokenType = baseToken === "SOL" ? "sol" : "spl";

      try {
        const data = await getBalanceDirect(walletAddress, contract, tokenType);
        if (data && data.decimals !== undefined) {
          setBalance(Number(data["amount"] / Math.pow(10, data["decimals"])));
        } else {
          setBalance(0);
        }
      } catch (error) {
        setBalance(0);
      }
    };

    fetchBalance();
  }, [baseToken, tokenList, walletAddress]);

  const tabs = [
    {
      text: "25%",
      value: 0.25,
      active: true,
    },
    {
      text: "50%",
      value: 0.5,
      active: true,
    },
    {
      text: "75%",
      value: 0.75,
      active: true,
    },
    {
      text: "100%",
      value: 1,
      active: true,
    },
  ];

  const handleClick = (value) => {
    const amount = balance * value
    handleAmount(amount);
  };

  return (
    <div className={`flex gap-1 justify-center items-center`}>
      {tabs.map((item, key) => {
        if (mode === "Swap" && item.value !== 1) {
          item.active = false;
        }
        if (!status) {
          item.active = false;
        }
        return (
          <span
            key={key}
            className={`text-xs py-[1px] px-2 rounded-md ${
              item.active
                ? "bg-themeColor-light hover:cursor-pointer hover:bg-themeColor-light/70 text-btnTextColor-light"
                : "bg-panelColor-light hover:cursor-not-allowed text-textColor-light opacity-60"
            }`}
            onClick={() => {
              if (item.active) {
                handleClick(item.value);
              }
            }}
          >
            {item.text}
          </span>
        );
      })}
    </div>
  );
};
