import { useEffect, useState } from "react"
import { useTheme } from "../../context/useTheme"
import { convertToMegaAndKilo, classNames } from "../../utils/formats"
import { images } from "../../assets"
import { useAuth } from "../../context/useAuth"
import {
  GlobeAltIcon,
  ChartBarIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid"
import {useTokenInfo} from "../../hooks/useTokenInfo"
import { useDexscreener } from "../../hooks/useDexscreener"

const DataWidget = ({ buyToken, priceQuery, tokenInfo, style, buyPercentage }) => {
  const theme = useTheme()  
  

  const truncateString = (str, num) => {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + "..."
  }

  return (
    <>  
    <div className="flex justify-between w-full mt-2">
      {/* Token */}
      <div className="text-sm md:text-[16px] flex">
        <span className="font-semibold">24h Vol : &nbsp;</span>
        <span className="font-light">
          {buyToken["symbol"] !== "SOL"
            ? convertToMegaAndKilo(Number(priceQuery.volume["h24"]))
            : ""}
        </span>
      </div>
      {/* Market Cap (fdv) */}
      <div className="text-sm md:text-[16px]">
        <span className="font-semibold">Market Cap : </span>
        <span className="font-light">
          $
          {buyToken["symbol"] !== "SOL"
            ? convertToMegaAndKilo(Number(priceQuery["fdv"]))
            : ""}
        </span>
      </div>
    </div>
    {/* Percentage Bar for Tokens (Buys vs Sells) */}
    <div
      className="w-full relative h-[6px] rounded-xl border-0 bg-white"
    >
      <div
        style={style}
        className="absolute top-0 left-0 h-[6px] rounded-xl border-0 bg-themeColor-light dark:bg-themeColor-dark"
      ></div>
    </div>
    <div className="w-full flex justify-between items-end text-[12px] md:text-[14px] font-medium pt-1">
      {/* Buys */}
      <span className="mb-1">
        Buys{" "}
        {Math.round(
          (100 * priceQuery["txns"]["h24"]["buys"]) /
            (priceQuery["txns"]["h24"]["buys"] +
              priceQuery["txns"]["h24"]["sells"])
        )}
        %
      </span>
      {/* Sells */}
      <span className="mb-1">
        Sells{" "}
        {Math.round(
          (100 * priceQuery["txns"]["h24"]["sells"]) /
            (priceQuery["txns"]["h24"]["buys"] +
              priceQuery["txns"]["h24"]["sells"])
        )}
        %
      </span>
    </div>
    {/* Percentage Bar for Volumes (Buys vs Sells) */}
    <div
      className="w-full relative h-[6px] rounded-xl border-0 mt-3 bg-white"
    >
      <div
        style={style}
        className="absolute top-0 left-0 h-[6px] rounded-xl border-0 bg-themeColor-light dark:bg-themeColor-dark"
      ></div>
    </div>
    <div className="w-full flex justify-between items-end text-[12px] md:text-[14px] font-medium pt-1">
      {/* Buys */}
      <span className="mb-1">
        24hr Buy Vol{" "}
        {convertToMegaAndKilo(
          Math.round(
            (priceQuery.volume["h24"] *
              priceQuery["txns"]["h24"]["buys"]) /
              (priceQuery["txns"]["h24"]["buys"] +
                priceQuery["txns"]["h24"]["sells"])
          )
        )}
      </span>
      {/* Sells */}
      <span className="mb-1">
        24h Sell Vol{" "}
        {convertToMegaAndKilo(
          Math.round(
            (priceQuery.volume["h24"] *
              priceQuery["txns"]["h24"]["sells"]) /
              (priceQuery["txns"]["h24"]["buys"] +
                priceQuery["txns"]["h24"]["sells"])
          )
        )}
      </span>
    </div>
    {/* Percentage Bar for Makers (Buys vs Sells) */}
    <div
      className="w-full relative h-[6px] rounded-xl border-0 mt-3 bg-white"
    >
      <div
        style={style}
        className="absolute top-0 left-0 h-[6px] rounded-xl border-0 bg-themeColor-light dark:bg-themeColor-dark"
      ></div>
    </div>
    <div className="w-full flex justify-between items-end text-[12px] md:text-[14px] font-medium pt-1">
      {/* Buys */}
      <span className="mb-1">
        Buyers {convertToMegaAndKilo(priceQuery["txns"]["h24"]["buys"])}
      </span>
      {/* Sells */}
      <span className="mb-1">
        Sellers {convertToMegaAndKilo(priceQuery["txns"]["h24"]["sells"])}
      </span>
    </div>
    {/* Contract Address */}
    <div className="w-full mt-3">
      <div className="text-sm md:text-[16px] font-semibold mb-2">
        Contract Address
      </div>
      <div className="text-xs md:text-sm font-normal break-words">
        {priceQuery?.baseToken?.address !== undefined ? priceQuery.baseToken.address : ""}
      </div>
    </div>
    {/* Description */}
    {tokenInfo?.tokenMetadata?.offChainInfo?.description && (
      <div className="w-full mt-3">
        <div className="text-sm md:text-[16px] font-semibold my-2">
          Description
        </div>
        <div className="text-xs md:text-sm font-normal">
          {truncateString(
            tokenInfo?.tokenMetadata?.offChainInfo?.description || " ",
            200
          )}
        </div>
      </div>
    )}
    {/* Socials */}
    {tokenInfo?.tokenMetadata?.offChainInfo?.extensions?.twitter && (
      <div className="w-full flex gap-4 justify-start items-center mt-3">
        <div className="text-sm md:text-[16px] font-semibold">
          Socials :
        </div>
        <div className="flex gap-4">
          {tokenInfo?.tokenMetadata?.offChainInfo?.extensions
            ?.website && (
            <a
              href={
                tokenInfo?.tokenMetadata?.offChainInfo?.extensions
                  ?.website || ""
              }
            >
              <GlobeAltIcon className="cursor-pointer w-6 h-6 text-black" />
            </a>
          )}
          {tokenInfo?.tokenMetadata?.offChainInfo?.extensions
            ?.discord && (
            <a
              href={
                tokenInfo?.tokenMetadata?.offChainInfo?.extensions
                  ?.discord || ""
              }
            >
              <img
                src={images.Discord}
                alt="Discord"
                className="cursor-pointer w-6 h-6"
              />
            </a>
          )}
          {tokenInfo?.tokenMetadata?.offChainInfo?.extensions
            ?.telegram && (
            <a
              href={
                tokenInfo?.tokenMetadata?.offChainInfo?.extensions
                  ?.telegram || ""
              }
            >
              <img
                src={images.Telegram}
                alt="Telegram"
                className="cursor-pointer w-6 h-6"
              />
            </a>
          )}
          {tokenInfo?.tokenMetadata?.offChainInfo?.extensions
            ?.twitter && (
            <a
              href={
                tokenInfo?.tokenMetadata?.offChainInfo?.extensions
                  ?.twitter
              }
            >
              <img
                src={images.Twitter}
                alt="Twitter"
                className="cursor-pointer w-6 h-6"
              />
            </a>
          )}
        </div>
      </div>
    )}
  </>
  )
}

export default DataWidget
