import React, { useEffect, useState } from "react";
import { logos } from "../../assets";
import { useSearchParams } from "react-router-dom";
import { applyActionCode, sendEmailVerification } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../utils/firebase";

export const Verification = () => {
  const [loading, setLoading] = useState(true);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState(null);
  const [buttonText, setButtonText] = useState("Resend verification email");
  const [verified, setVerified] = useState(false);
  const [searchParams] = useSearchParams();
  const [seconds, setSeconds] = useState(5);

  const navigate = useNavigate();

  useEffect(() => {
    const oobCode = searchParams.get("oobCode");
    const mode = searchParams.get("mode");
    

    if (!oobCode || mode !== "verifyEmail") {
      return;
    }

    const verifyEmail = async () => {
      try {
        console.log("Applying action code:", oobCode);
        await applyActionCode(auth, oobCode);
        setVerified(true);
        console.log("Email verified, navigating to /app");
        navigate("/app", { replace: true });
        //refresh page if not redirected. Update countdown timer
        const interval = setInterval(() => {
          setSeconds((prev) => prev - 1);
        }, 1000);
        setTimeout(() => {
          clearInterval(interval);
        }, 5000);
        setTimeout(() => {
          window.location.reload();
        }, 5000);

        

      } catch (err) {
        console.error("Error verifying email:", err);
        setError(`Invalid or expired verification code. Please try again. ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, [searchParams, navigate]);

  const handleSendEmailVerification = async () => {    
    if (auth.currentUser && !emailSent) {
      try {
        setButtonText("Sending email...");
        console.log("Sending verification email");
        await sendEmailVerification(auth.currentUser);
        console.log("Email sent");
        setButtonText("Email sent");
        setEmailSent(true);
        setLoading(false);
        setError(null);
      } catch (error) {
        console.error("Error sending email verification:", error);
        setError(error.message);
      }
    }
  };

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <div className="bg-white overflow-y-auto flex-1">
        <div className="relative isolate gradient min-h-screen">
          <div className="lg:px-8 px-6">
            <div className="mx-auto max-w-2xl pt-16 sm:pt-20">
              <div className="flex items-center justify-center">
                <img src={logos.Logo} alt="ACHI" />
                <p className="text-5xl">ACHI</p>
              </div>
              <div className="text-center">
                <h1 className="text-4xl font-bold tracking-tight text-black pt-5">
                  To proceed, please verify your email.
                </h1>
                <p className="text-lg text-[#666666] pt-5">
                  If you didn't receive the email, please check your spam folder
                  or try again later.
                </p>
                {!verified ? (
                  <p className="pt-5">
                    <button
                      disabled={emailSent}
                      className="text-white bg-[#ff8700] rounded-full py-2 w-full"
                      onClick={handleSendEmailVerification}
                    >
                      {buttonText}
                    </button>
                  </p>
                ) : (
                  <p className="text-green-500 pt-5">Email verified! Refresh page if you are not redirected in {seconds} seconds</p>
                )}
                <div>{error && <p className="text-red-500">{error}</p>}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
