export const SelectMode = ({status, selectedMode, handleMode}) => {
  return (
    <div className="flex-grow flex flex-row gap-2 justify-around items-center text-[12px] px-2 lp:px-4 pc:px-0">
      <div className="flex gap-1 w-1/3 justify-start items-center">
        <input
          type="radio"
          className={`focus:ring-transparent focus:outline-none w-3 h-3 ${
            status
              ? "text-themeColor-light hover:cursor-pointer"
              : "text-gray-600 hover:cursor-not-allowed"
          }`}
          checked={selectedMode === "Swap"}
          onChange={() => {
            handleMode("Swap");
          }}
          disabled={!status}
        />
        <p
          className={`text-center  ${
            status ? "hover:cursor-pointer" : "hover:cursor-not-allowed"
          }`}
          onClick={() => {
            if (status) handleMode("Swap");
          }}
        >
          Volume
        </p>
      </div>
      <div className="flex gap-1 w-1/3 justify-start items-center">
        <input
          type="radio"
          className={`focus:ring-transparent focus:outline-none w-3 h-3 ${
            status
              ? "text-themeColor-light hover:cursor-pointer"
              : "text-gray-600 hover:cursor-not-allowed"
          }`}
          checked={selectedMode === "AMM"}
          onChange={() => {
            handleMode("AMM");
          }}
          disabled={!status}
        />
        <p
          className={`text-center  ${
            status ? "hover:cursor-pointer" : "hover:cursor-not-allowed"
          }`}
          onClick={() => {
            if (status) handleMode("AMM");
          }}
        >
          AMM
        </p>
      </div>
      <div className="flex gap-1 w-1/3 justify-start items-center">
        <input
          type="radio"
          className={`focus:ring-transparent focus:outline-none w-3 h-3 ${
            status
              ? "text-themeColor-light hover:cursor-pointer"
              : "text-gray-600 hover:cursor-not-allowed"
          }`}
          checked={selectedMode === "Trade"}
          onChange={() => {
            handleMode("Trade");
          }}
          disabled={!status}
        />
        <p
          className={`text-center  ${
            status ? "hover:cursor-pointer" : "hover:cursor-not-allowed"
          }`}
          onClick={() => {
            if (status) handleMode("Trade");
          }}
        >
          Trade
        </p>
      </div>
    </div>
  );
};
