import React, { useState, useEffect } from "react"

export const QuoteResponseView = ({
  gettingQuote,
  amount,
  usdValues = null,
  viewMode,
}) => {
  const formatNumber = (num) => {
    if (num === undefined || num === null || isNaN(num)) {
      return "Invalid number"
    }

    if (num === "0.0000") {
      return ""
    }
    const number = Number(num)

    if (number >= 1) {
      if (number > 1000) {
        let temp = number.toFixed(0)
        return Number(temp).toLocaleString()
      } else if (Number.isInteger(number)) {
        return number.toLocaleString()
      } else {
        return number.toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
      }
    } else {
      let decimalPlaces = -Math.floor(Math.log10(number)) + 1
      decimalPlaces = Math.min(decimalPlaces, 6) // Ensure the decimal places do not exceed a reasonable limit
      return number.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: decimalPlaces,
      })
    }
  }

  const [showUSD, setShowUSD] = useState(false)

  useEffect(() => {
    if (usdValues > 0) {
      setShowUSD(true)
    }
  }, [usdValues])

  return (
    <>
      {viewMode === 1 && (
        <div>
          <span className="text-xl sm:text-2xl">
            {gettingQuote ? (
              <div className="animate-pulse h-full flex flex-col">
                <div className={`w-20 h-6 bg-gray-300 rounded`}></div>
              </div>
            ) : showUSD ? (
              <>
                {formatNumber(amount)}
                <span className="text-sm sm:text-[16px] ml-2 opacity-80">
                  {gettingQuote ? (
                    ""
                  ) : (
                    <span>USD ${formatNumber(usdValues)}</span>
                  )}
                </span>
              </>
            ) : (
              formatNumber(amount)
            )}
          </span>
        </div>
      )}
      {viewMode === 2 && (
        <div className="flex flex-col gap-2">
          {showUSD && (
            <span className="text-2xl font-semibold">
              {gettingQuote ? "" : <span>USD ${formatNumber(usdValues)}</span>}
            </span>
          )}
          <span className="text-xl opacity-80 font-medium">
            {gettingQuote ? (
              <div className="animate-pulse h-full flex flex-col">
                <div className={`w-40 h-6 bg-gray-300 rounded`}></div>
              </div>
            ) : (
              formatNumber(amount)
            )}
          </span>
        </div>
      )}
      {viewMode === 3 && (
        <div>
          <span className="text-[18px]">
            {gettingQuote ? (
              <div className="animate-pulse h-full flex flex-col">
                <div className={`w-40 h-6 bg-gray-300 rounded`}></div>
              </div>
            ) : (
              formatNumber(amount)
            )}
          </span>
        </div>
      )}
    </>
  )
}
