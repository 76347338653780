import { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/20/solid"
import { classNames } from "../../utils/formats"
import { useTheme } from "../../context/useTheme"

export const WidgetTerms = ({ openTermsModal, setOpenTermsModal }) => {
  const theme = useTheme()

  return (
    <Transition.Root show={openTermsModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 flex items-center justify-center"
        onClose={() => setOpenTermsModal(true)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={classNames(
              theme.currentTheme === "dogTheme"
                ? "bg-dogTheme-bgColor"
                : theme.currentTheme === "lightTheme"
                ? "bg-lightTheme-bgColor"
                : "bg-darkTheme-bgColor",
              "fixed inset-0 bg-opacity-75 transition-opacity"
            )}
          />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-hidden">
          <div className="flex min-h-full items-center justify-center p-4 text-center md:items-center md:p-0">
            <Transition.Child
              as={Fragment}
              unmount={false}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel
                className={classNames(
                  theme.currentWidgetTheme === "light"
                    ? "bg-lightWidget-bgColor text-lightWidget-textColor"
                    : "bg-darkWidget-bgColor text-darkWidget-textColor",
                  "fixed transform overflow-hidden rounded-lg pr-1 pb-4 pt-2 text-left shadow-xl transition-all md:my-8 w-[90vw] md:w-[50vw] h-[80vh] md:pb-6"
                )}
              >
                <div className="flex mr-2 h-12 items-center justify-end">
                  <XMarkIcon
                    className="h-6 w-6  cursor-pointer"
                    aria-hidden="true"
                    onClick={() => setOpenTermsModal(false)}
                  />
                </div>
                <div className="w-full h-[90%] overflow-y-auto flex flex-col px-5 py-2 gap-2">
                  <div className="">
                    <p className="text-lg mb-2 font-semibold">
                      Acceptance of Terms:
                    </p>
                    <p className="text-[16px]">
                      By accessing the website, whitepaper, and other
                      informational materials, you confirm that you have
                      reviewed and accept these terms. Accessing these materials
                      will be deemed an acceptance of all terms, including
                      confirmations that you are not based in a jurisdiction
                      where such access would be prohibited or restricted.
                    </p>
                  </div>
                  <div className="">
                    <p className="text-lg mb-2 font-semibold">
                      Non-Legally Binding Information:
                    </p>
                    <p className="text-[16px]">
                      The information on the website and in the Token
                      Documentation is intended for community discussion and is
                      not legally binding. No commitment is implied to enter
                      into any contract or binding legal commitment concerning
                      the acquisition of ACHI based on this information.
                    </p>
                  </div>
                  <div className="">
                    <p className="text-lg mb-2 font-semibold">
                      Deemed Representations and Warranties:
                    </p>
                    <p className="text-[16px]">
                      By accessing the website, you represent and warrant to
                      ACHI INU, LLC, its distributors, their respective
                      affiliates, and the early contributors of ACHI as follows:
                      <br />
                      1. You have not and shall not rely on any statement in the
                      Token Documentation or website for any decision to acquire
                      ACHI.
                      <br />
                      2. You will ensure compliance with all applicable laws and
                      regulations at your own expense.
                      <br />
                      3. You understand that ACHI may have no value and is not
                      intended for investment.
                    </p>
                  </div>
                  <div className="">
                    <p className="text-lg mb-2 font-semibold">
                      Limitation of Liability for Lost Funds:
                    </p>
                    <p className="text-[16px]">
                      ACHI INU, LLC, its distributors, their respective
                      affiliates, and the early contributors of ACHI are not
                      liable for the loss of funds or assets, data, or any
                      financial losses that occur from using, accessing, or
                      interacting with the website or any associated services.
                      This includes losses that arise from unauthorized access,
                      technical failures, or other users' actions.
                    </p>
                  </div>
                  <div className="">
                    <p className="text-lg mb-2 font-semibold">
                      Disclaimer of Liability:
                    </p>
                    <p className="text-[16px]">
                      To the maximum extent permitted by law, ACHI INU, LLC and
                      its service providers shall not be liable for indirect,
                      special, incidental, consequential, or exemplary damages,
                      including lost profits or data, arising from your use of
                      the website or associated services. This disclaimer
                      applies regardless of the foreseeability of such losses
                      and notwithstanding the failure of any agreed or other
                      remedy of its essential purpose.
                    </p>
                  </div>
                  <div className="">
                    <p className="text-lg mb-2 font-semibold">
                      Regulatory and Compliance Obligations:
                    </p>
                    <p className="text-[16px]">
                      You shall comply with all applicable laws, regulations,
                      and ordinances. ACHI INU, LLC may terminate your access to
                      the website and services if any governmental authority
                      imposes regulations or penalties on ACHI.
                    </p>
                  </div>
                  <div className="">
                    <p className="text-lg mb-2 font-semibold">Termination:</p>
                    <p className="text-[16px]">
                      ACHI INU, LLC may terminate this agreement immediately at
                      its sole discretion if you fail to comply with these terms
                      or become insolvent.
                    </p>
                  </div>
                  <div className="">
                    <p className="text-lg mb-2 font-semibold">Severability:</p>
                    <p className="text-[16px]">
                      If any term of this Agreement is invalid or unenforceable,
                      it shall not affect the validity or enforceability of the
                      remaining terms.
                    </p>
                  </div>
                  <div className="">
                    <p className="text-lg mb-2 font-semibold">
                      Survival of Terms:
                    </p>
                    <p className="text-[16px]">
                      Provisions of these Terms which by their nature should
                      apply beyond their terms will remain in force after any
                      termination or expiration of this Agreement.
                    </p>
                  </div>
                  <div className="">
                    <p className="text-lg mb-2 font-semibold">
                      Disclaimer of Fund Security:
                    </p>
                    <p className="text-[16px]">
                      ACHI is designed solely for interaction within its
                      ecosystem and does not represent any monetary value or
                      financial security. The token is non-refundable and does
                      not confer any rights related to ACHI INU, LLC or its
                      assets.
                    </p>
                  </div>
                  <div className="">
                    <p className="text-lg mb-2 font-semibold">
                      Risk Acknowledgment:
                    </p>
                    <p className="text-[16px]">
                      You acknowledge and accept the risks associated with
                      digital assets, including the potential loss of all funds.
                      You agree to assume all risks associated with using,
                      holding, or transacting in ACHI within its ecosystem.
                    </p>
                  </div>

                  <div className="">
                    <button
                      className={classNames(
                        theme.currentWidgetTheme === "light"
                          ? `bg-lightWidget-primaryColor hover:bg-lightWidget-primaryColor/70`
                          : `bg-darkWidget-primaryColor hover:bg-darkWidget-primaryColor/70`,
                        "rounded-full py-2 w-full"
                      )}
                      onClick={() => setOpenTermsModal(false)}
                    >
                      I Agree
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
