import QRCode from "qrcode.react"
import { ClipboardDocumentIcon } from "@heroicons/react/20/solid"
import { useState } from "react"

const Receive = ({ account }) => {
  const [copied, setCopied] = useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(account.address)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000) // Reset the copied state after 2 seconds
  }

  return (
    <div className="absolute inset-0 flex justify-center px-2">
      <div
        className="flex flex-col items-center mx-2 tb:mx-0 my-auto p-4 text-center max-w-[450px] w-full rounded-xl bg-panelColor-light dark:bg-panelColor-dark text-textColor-light dark:text-textColor-dark"
      >
        <p className="text-2xl font-medium mb-5">Receive SOL</p>
        <div>
          <QRCode
            value={account.address} // The URL or text you want to encode in the QR code
            size={180} // The size of the QR code
            level={"H"} // The error correction level: 'L', 'M', 'Q', 'H'
            includeMargin={true} // Whether to include a margin around the QR code
            imageSettings={{
              src: "https://huskystudios.ams3.cdn.digitaloceanspaces.com/walletX/assets/logos/5426.png", // Path to your logo image
              x: null, // Optionally specify the x position
              y: null, // Optionally specify the y position
              height: 48, // Height of the logo
              width: 48, // Width of the logo
              excavate: true, // Optionally clear the QR code beneath the logo image
            }}
          />
        </div>
        <div className="px-6 w-full flex flex-col gap-3 break-words text-wrap mt-3">
          <span className="text-xl font-medium">Address</span>
          <div className="gap-2">
            <span className="text-xs text-wrap break-words whitespace-normal">
              {account.address}
            </span>
            <button
              onClick={copyToClipboard}
              className="focus:outline-none relative top-2 ml-2"
            >
              <ClipboardDocumentIcon className="h-6 w-6 hover:opacity-80" />
            </button>
          </div>
          {copied && <span className="text-sm text-green-500">Copied!</span>}
        </div>
      </div>
    </div>
  )
}

export default Receive
