import { useDataWidget } from "../../hooks/useDataWidget"
import { useTheme } from "../../context/useTheme"
import { useDexscreener } from "../../hooks/useDexscreener"
import { Chart } from "react-google-charts"
import { useMemo, useState } from "react"
import { convertToMegaAndKilo } from "../../utils/formats"
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ArrowDownIcon,
  ArrowUpIcon,
} from "@heroicons/react/20/solid"
import { classNames } from "../../utils/formats"

export const WidgetTokenPriceChart = ({ token }) => {
  const [isOpen, setIsOpen] = useState(false)
  const chart = useDataWidget({ buyToken: token })
  const price = useDexscreener(token.contract)
  const theme = useTheme()

  const options = useMemo(
    () => ({
      legend: "none",
      backgroundColor: theme.currentWidgetThemeColors(theme.currentWidgetTheme)
        .bg,
      colors: [
        theme.currentWidgetThemeColors(theme.currentWidgetTheme).primary,
      ],
      hAxis: { gridlines: { color: "transparent" } },
      vAxis: { gridlines: { color: "transparent" } },
      chartArea: { width: "100%", height: "100%" },
    }),
    [theme]
  )

  const handleOpen = () => {
    setIsOpen((prevState) => !prevState)
  }

  return (
    <div
      className={classNames(
        isOpen ? "translate-y-0" : "-translate-y-180px",
        "w-full transition transform duration-500 absolute z-[9]"
      )}
    >
      <div
        className={classNames(
          theme.currentWidgetTheme === "light"
            ? `bg-lightWidget-bgColor`
            : `bg-darkWidget-bgColor`,
          "w-full flex flex-col pt-4 px-4"
        )}
      >
        {/* Token Name */}

        {/* Token Price */}
        <div className="flex justify-start items-end text-center text-[16px] font-bold ml-2 gap-3 mb-2">
          <div className="text-left text-xl font-bold">{token["name"]}</div>
          <div>
            $
            {Number(
              Math.round(price.priceQuery["priceUsd"] * 10000) / 10000
            ).toLocaleString("en-US", {
              style: "decimal",
              maximumSignificantDigits: 4,
            })}
          </div>
          <div className="text-[14px] font-medium pb-[1px]">
            {price.priceQuery["priceChange"]["h24"] > 0
              ? "+" +
                Number(price.priceQuery["priceChange"]["h24"]).toLocaleString()
              : Number(price.priceQuery["priceChange"]["h24"]).toLocaleString()}
            %
          </div>
        </div>
        {chart.chartData && (
          <div className="h-[100px] w-full overflow-hidden">
            <Chart
              chartType="LineChart"
              width="100%"
              height="100%"
              data={chart.chartData}
              options={options}
            />
          </div>
        )}
        <div className="flex justify-between w-full mt-2 px-2">
          {/* Token */}
          <div className="text-sm flex">
            <span className="font-semibold">24h Vol : &nbsp;</span>
            <span className="font-light">
              {token["symbol"] !== "SOL"
                ? convertToMegaAndKilo(Number(price.priceQuery.volume["h24"]))
                : ""}
            </span>
          </div>
          {/* Market Cap (fdv) */}
          <div className="text-sm">
            <span className="font-semibold">Market Cap : </span>
            <span className="font-light">
              $
              {token["symbol"] !== "SOL"
                ? convertToMegaAndKilo(Number(price.priceQuery["fdv"]))
                : ""}
            </span>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          theme.currentWidgetTheme === "light"
            ? isOpen
              ? `hover:bg-lightWidget-panelColor bg-lightWidget-bgColor shadow-lg`
              : `hover:bg-lightWidget-panelColor bg-lightWidget-bgColor hover:shadow-lg`
            : isOpen
            ? `hover:bg-darkWidget-panelColor bg-darkWidget-bgColor shadow-lg shadow-gray-700`
            : `hover:bg-darkWidget-panelColor bg-darkWidget-bgColor hover:shadow-lg hover:shadow-gray-700`,
          "w-full flex justify-center hover:cursor-pointer py-1 rounded-b-xl"
        )}
        onClick={handleOpen}
      >
        {isOpen ? (
          <ArrowUpIcon className="w-4 h-4 hover:opacity-70 hover:cursor-pointer" />
        ) : (
          <ArrowDownIcon className="w-4 h-4 hover:opacity-70 hover:cursor-pointer" />
        )}
      </div>
    </div>
  )
}
