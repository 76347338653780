import bs58 from "bs58"

export function isValidSolanaPublicKey(keyString) {
  try {
    const decoded = bs58.decode(keyString)
    return decoded.length === 32 // Solana public keys must be 32 bytes long
  } catch (error) {
    // If decoding fails, the string is not a valid base58 encoded value
    return false
  }
}

export const formatUSD = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount)
}

export const formatToken = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 4,
  }).format(amount)
}

export const formatPercent = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 2,
  }).format(amount / 100)
}

export const formatNumber = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 0,
  }).format(amount)
}

export const formatMarketCap = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(amount)
}

export const formatDecimal = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 2,
  }).format(amount)
}

export const formatDateTime = (unixTs) => {
  let localDateTime = new Date(unixTs * 1000)
  let date = localDateTime.toLocaleDateString()
  let time = localDateTime.toLocaleTimeString()

  return `${date} ${time}`
}

export const formatAddress = (address) => {
  let displayAddress = address
  if (address?.length > 20) {
    displayAddress =
      address.substring(0, 6) +
      "..." +
      address.substring(address?.length - 4, address?.length)
  }
  return displayAddress
}

export const formatTokenValue = (token) => {
  const tokenNumber = parseInt(token.amount)
  const decimals = parseInt(token.decimals)
  const formattedToken = tokenNumber / Math.pow(10, decimals)
  //limit to 4 decimal places
  return formattedToken.toFixed(4)
  //return formattedToken;
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export function convertToMegaAndKilo(number, position = 2) {
  if (number >= 1000000000) {
    return (number / 1000000000).toFixed(position) + "B" // For billions
  } else if (number >= 1000000) {
    return (number / 1000000).toFixed(position) + "M" // For millions
  } else if (number >= 1000) {
    return (number / 1000).toFixed(position) + "K" // For thousands
  } else {
    if (isNaN(number)) {
      return "N/A"
    }
    number = number.toFixed(2)
    return number.toString() // For numbers less than 1000
  }
}

export function formatPrice(number) {
  if (number >= 1) {
    return ((number / 100) * 100).toFixed(1)
  } else if (number < 1 / 1000) {
    return ((number / 100) * 100).toFixed(6)
  } else return ((number / 100) * 100).toFixed(4)
}
