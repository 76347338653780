import { useState, useEffect } from "react";

export const ResultToken = ({
  baseTokenSymbol,
  targetTokenSymbol,
  direction, // True - baseToken, False - targetToken
  handleDirection,
  status, // True - Not running, False - running
}) => {
  const [selectedSymbol, setSelectedSymbol] = useState(true);

  useEffect(() => {
    setSelectedSymbol(direction);
  }, [direction]);

  return (
    <div className="flex-grow flex flex-row gap-2 items-center text-[12px] px-2 lp:px-4 pc:px-0">
      <div className="flex w-1/2 gap-1 justify-start items-center">
        <input
          type="radio"
          className={`focus:ring-transparent focus:outline-none w-3 h-3 ${
            status
              ? "text-themeColor-light hover:cursor-pointer"
              : "text-gray-600 hover:cursor-not-allowed"
          }`}
          checked={selectedSymbol}
          onChange={() => {
            handleDirection(true);
          }}
          disabled={!status}
        />
        <p
          className={`text-center  ${
            status ? "hover:cursor-pointer" : "hover:cursor-not-allowed"
          }`}
          onClick={() => {
            if (status) handleDirection(true);
          }}
        >
          {baseTokenSymbol}
        </p>
      </div>
      <div className="flex w-1/2 gap-1 justify-start items-center">
        <input
          type="radio"
          className={`focus:ring-transparent focus:outline-none w-3 h-3 ${
            status
              ? "text-themeColor-light hover:cursor-pointer"
              : "text-gray-600 hover:cursor-not-allowed"
          }`}
          checked={!selectedSymbol}
          onChange={() => {
            handleDirection(false);
          }}
          disabled={!status}
        />
        <p
          className={`text-center  ${
            status ? "hover:cursor-pointer" : "hover:cursor-not-allowed"
          }`}
          onClick={() => {
            if (status) handleDirection(false);
          }}
        >
          {targetTokenSymbol}
        </p>
      </div>
    </div>
  );
};
