import { Link } from "react-router-dom";
import { classNames } from "../../utils/formats";
import { useTheme } from "../../context/useTheme";
import { useState } from "react";


export const AmountTabs = ({handleMaxAmount, handleUsdAmountChange }) => {

    const theme = useTheme();

    const [amountTabs, setAmountTabs] = useState([
        { name: "Custom", value: "", current: true },
        { name: "$50", value: "50", current: false },
        { name: "$100", value: "100", current: false },
        { name: "$500", value: "500", current: false },
        { name: "MAX", value: "", current: false },
      ]);


    return (
        <div className="mt-6 rounded-md border border-black">
        <nav
          className="flex justify-between flex-wrap"
          aria-label="Tabs"
        >
          {amountTabs.map((tab) => (
            <Link
              key={tab.name}
              className={`${tab.current ? "bg-themeColor-light dark:bg-themeColor-dark text-btnTextColor-light dark:text-btnTextColor-dark rounded-md" : ""} rounded-md sm:px-3 py-3 px-1 font-medium text-[12px] sm:text-[13px] w-[20%] text-center`}
              aria-current={tab.current ? "page" : undefined}
              onClick={(e) => {
                e.preventDefault();
                setAmountTabs((_prev) => {
                  const temp = [..._prev];
                  for (let i = 0; i < temp.length; i++) {
                    if (temp[i].name === tab.name) {
                      temp[i].current = true;
                    } else {
                      temp[i].current = false;
                    }
                  }
                  return temp;
                });

                if (tab.name === "MAX") {
                  handleMaxAmount();
                } else {
                  handleUsdAmountChange({
                    target: { value: tab.value },
                  });
                }
              }}
            >
              {tab.name}
            </Link>
          ))}
        </nav>
      </div>
    )
}