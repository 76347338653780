import { useTheme } from "../../context/useTheme"
import { useNavigate } from "react-router-dom"
import { convertToMegaAndKilo, classNames } from "../../utils/formats"

export const TrendingTokenBox = ({ onBoxData, logoUrl, name, symbol }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const buyPercentage = Math.round(
    (100 * onBoxData["txns"]["h24"]["buys"]) /
      (onBoxData["txns"]["h24"]["buys"] + onBoxData["txns"]["h24"]["sells"])
  )

  const style = {
    width: `${buyPercentage}%`,
  }

  // console.log(onBoxData)

  const handleSelectedToken = (contract) => {
    navigate(`trade/${contract}`)
  }

  return (
    <div
      className="max-w-[400px] w-full imd:w-[48%] md:w-[33%] relative group overflow-hidden px-3 py-2 sm:px-4 sm:py-2 mb-3 rounded-xl cursor-pointer bg-panelColor-light dark:bg-panelColor-dark text-textColor-light dark:text-textColor-dark"
      onClick={() => handleSelectedToken(onBoxData.baseToken.address)}
    >
      <div className="flex flex-col gap-1">
        <div className="flex flex-row">
          {/* Logo */}
          <div className="flex flex-row w-[30%] justify-center items-center">
            <img
              src={logoUrl}
              alt="Logo"
              className="max-w-[100px] w-full rounded-[50%] p-2"
            />
          </div>
          <div className="flex flex-col w-[70%] justify-center items-center px-2">
            {/* Token Name */}
            <p className="w-full text-center text-[17px] font-extrabold">
              {name}
            </p>
            {/* Token Price */}
            <div className="flex justify-center items-center text-center text-[20px] md:text-[17px] xl:text-[20px] font-bold">
              $
              {Number(
                Math.round(onBoxData["priceUsd"] * 10000) / 10000
              ).toLocaleString("en-US", {
                style: "decimal",
                maximumSignificantDigits: 4,
              })}
            </div>
            {/* Token Price Change Percentage */}
            <div className="flex flex-row justify-center items-center text-center">
              <div className="text-[15px] font-medium">
                {onBoxData["priceChange"]["h24"] > 0
                  ? "+" +
                    Number(onBoxData["priceChange"]["h24"]).toLocaleString()
                  : Number(onBoxData["priceChange"]["h24"]).toLocaleString()}
                %
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full">
          {/* Market Cap (fdv) */}
          <div className="text-[11px]">
            <span className="font-semibold">Market Cap : </span>
            <span className="font-light">
              $
              {symbol !== "SOL"
                ? convertToMegaAndKilo(Number(onBoxData["fdv"]))
                : ""}
            </span>
          </div>
          {/* Volumn */}
          <div className="text-[11px] flex">
            <span className="font-semibold">24h Vol : </span>
            <span className="font-light">
              {symbol !== "SOL"
                ? convertToMegaAndKilo(Number(onBoxData.volume["h24"]))
                : ""}
            </span>
          </div>
        </div>
        {/* Percentage Bar (Buys vs Sells) */}
        <div
          className="w-full relative h-[6px] rounded-xl border-0 bg-white"
        >
          <div
            style={style}
            className="absolute top-0 left-0 h-[6px] rounded-xl border-0 bg-themeColor-light dark:bg-themeColor-dark"
          ></div>
        </div>
        <div className="w-full flex justify-between items-end text-[12px] xl:text-[14px] font-medium pt-1">
          {/* Buys */}
          <span className="mb-1">
            Buys{" "}
            {Math.round(
              (100 * onBoxData["txns"]["h24"]["buys"]) /
                (onBoxData["txns"]["h24"]["buys"] +
                  onBoxData["txns"]["h24"]["sells"])
            )}
            %
          </span>
          {/* Sells */}
          <span className="mb-1">
            Sells{" "}
            {Math.round(
              (100 * onBoxData["txns"]["h24"]["sells"]) /
                (onBoxData["txns"]["h24"]["buys"] +
                  onBoxData["txns"]["h24"]["sells"])
            )}
            %
          </span>
        </div>
      </div>
    </div>
  )
}
