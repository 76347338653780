import { Fragment, useState, useEffect } from "react"
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth"
import { auth } from "../../utils/firebase"
import { logos } from "../../assets"
import { images } from "../../assets"
import { createAccount } from "../../hooks/functions"
import { Spinner } from "../../components/Spinner"
import { Terms } from "./Terms"
import { useTheme } from "../../context/useTheme"
import { AuthIntro } from "../../components/AuthIntro"
import { SignupForm } from "./SignUpForm"
import { useNavigate, useSearchParams } from "react-router-dom";



export const Signup = () => {
  const [openTermsModal, setOpenTermsModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [searchParams] = useSearchParams();
  const [mode, setMode] = useState(null);
  const [referralCode, setReferralCode] = useState(null)

  const { isMobile } = useTheme()

  useEffect(() => {
    const modeTemp = searchParams.get("mode")
    const code = searchParams.get("code")
    console.log("modeTemp", modeTemp)
    console.log("code", code)
    if (modeTemp) {
      setMode(modeTemp)
    }
    if (code) {
      setReferralCode(code)
    }
  }, [searchParams])

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      {/* Desktop View */}
      <div className="bg-white md:block hidden overflow-y-auto flex-1">
        <div className="relative isolate gradient min-h-screen pb-10">
          <div className="lg:px-8 px-6 pb-5">
            <AuthIntro isMobile={isMobile} />


            {/* Auth box */}
            <div className="mt-4 border-[#ff8700] border-x border-t rounded-md bg-[#010101] opacity-[0.65] max-w-sm mx-auto">
              <div className="text-gray-100 p-6 mx-auto">
                <SignupForm
                  loading={loading}
                  setLoading={setLoading}
                  openTermsModal={openTermsModal}
                  setOpenTermsModal={setOpenTermsModal}
                  referralCode={referralCode}
                />
              </div>
            </div>
            {/* end auth box */}
          </div>
        </div>
      </div>
      {/* End Desktop View */}

      {/* Mobile view */}
      <div className="md:hidden relative isolate gradient overflow-y-scroll">
        <div className="bg-[#010101] opacity-[0.65] min-h-screen text-center pb-3">
          <AuthIntro isMobile={isMobile} />
          <hr className="w-full h-[2px] mt-5" />
          <SignupForm
            loading={loading}
            setLoading={setLoading}
            openTermsModal={openTermsModal}
            setOpenTermsModal={setOpenTermsModal}
            referralCode={referralCode}
          />
        </div>
      </div>
      {/* End Mobile view */}

      {/* loading spinner */}
      {loading && <Spinner loading={loading} />}
      {/* End loading spinner */}

      {/* Terms & Policy Modal */}
      <Terms
        openTermsModal={openTermsModal}
        setOpenTermsModal={setOpenTermsModal}
      />
    </div>
  )
}
