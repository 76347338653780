import axios from "axios"
import { useEffect, useState } from "react"

export const useDexscreener = (contract) => {
  const [priceQuery, setPriceQuery] = useState({
    priceUsd: 0,
    priceChange: { m5: 0, h1: 0, h6: 0, h24: 0 },
    txns: { h24: { buys: 0, sells: 0 } },
    volume: { h24: 0 },
    fdv: 0,
  })
  const [buyPercentage, setBuyPercentage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!contract) {
      setLoading(false)
      return
    }

    const source = axios.CancelToken.source()

    const fetchData = async () => {
      setLoading(true)
      setError(null)
      try {
        const response = await axios.get(
          `https://api.dexscreener.com/latest/dex/tokens/${contract}`,
          {
            cancelToken: source.token,
          }
        )

        const firstPair = response.data.pairs[0]

        setPriceQuery(firstPair)
        setBuyPercentage(
          Math.round(
            (100 * firstPair.txns.h24.buys) /
              (firstPair.txns.h24.buys + firstPair.txns.h24.sells)
          )
        )
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message)
        } else {
          console.error("Error fetching token info:", error)
          setError(error)
        }
      } finally {
        setLoading(false)
      }
    }

    fetchData()

    return () => {
      source.cancel("Component unmounted or contract changed")
    }
  }, [contract])

  return {priceQuery, buyPercentage, loading, error}
}
