import { Link, useNavigate, useParams } from "react-router-dom";
import DataCard from "../../components/Data/DataCard";
import { useState } from "react";

export const Data = () => {
  const { dataId } = useParams(); // Destructure the parameter from useParams

  const buyToken = {
    contract: dataId,
  };

  const [loading, setLoading] = useState(false);

  console.log("dataId", dataId);

  return (
    <div 
        className="p-4 max-w-4xl mx-auto">

    <DataCard buyToken={buyToken} isLoading={loading} setLoading={setLoading} showcard={true} setShowCard={null} />
    </div>
  );
};
