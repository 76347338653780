import { useState, useEffect, useCallback } from 'react';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { useAuth } from '../context/useAuth';
import { db } from '../utils/firebase';
import {
  getUpdateUserBalances,  
  updateWalletSignatures,
} from "./functions";


export const useGetTransactions = (account) => {
  const [txs, setTxs] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  const fetchTransactions = useCallback(async (controller) => {
    if (!currentUser || !account?.address) return;

    const pathToDocuments = `users/${currentUser.uid}/accounts/${account.address}/transactions`;
    const collectionRef = collection(db, pathToDocuments);
    const q = query(collectionRef, orderBy("blockTime", "desc"), limit(50));

    try {
      const querySnapshot = await getDocs(q, { signal: controller.signal });
      const transactions = [];
      querySnapshot.forEach((doc) => {
        transactions.push({ ...doc.data(), uid: doc.id });
      });

      const tempTxArray = [];

      transactions.forEach((tx) => {
        const humanDate = new Date(tx.blockTime * 1000).toLocaleString();
        const date = humanDate.split(",")[0];
        const index = tempTxArray.findIndex((txGroup) => txGroup.date === date);
        if (index === -1) {
          tempTxArray.push({ date, transactions: [tx] });
        } else {
          tempTxArray[index].transactions.push(tx);
        }
      });
      setTxs(tempTxArray);
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error("Error fetching transactions: ", error);
      }
    } finally {
      setLoading(false);
    }
  }, [currentUser, account]);

  useEffect(() => {
    const controller = new AbortController();
    fetchTransactions(controller);

    return () => controller.abort();
  }, [fetchTransactions]);

  const refresh = () => {
    setLoading(true);
    const controller = new AbortController();
    fetchTransactions(controller);
    updateWalletSignatures(account.address);

    return () => controller.abort();
  };

  return { txs, loading, refresh };
};
