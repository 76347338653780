import React, { useState } from "react"
import {
  auth,
  ga_provider,
  tw_provider,
  signInWithPopup,
} from "../../utils/firebase"
import { useNavigate } from "react-router-dom"
import { images } from "../../assets"
import { useTheme } from "../../context/useTheme"
import { classNames } from "../../utils/formats"

export const ExtProviderButtons = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const theme = useTheme()

  const handleSignIn = async (provider) => {
    setLoading(true)
    try {
      const result = await signInWithPopup(auth, provider)
      // The signed-in user info.
      const user = result.user
      navigate("/swap")
      // You can handle additional logic here (e.g., storing user info in your app's state)
    } catch (error) {
      setError(error)
      console.error("Error signing in:", error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="flex items-center justify-center w-full mt-4 gap-3">
        <button
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `border-lightWidget-primaryColor hover:bg-lightWidget-primaryColor/70`
              : `border-darkWidget-primaryColor hover:bg-darkWidget-primaryColor/70`,
            "flex items-center justify-center gap-2 rounded-full w-full py-1 border"
          )}
          onClick={() => handleSignIn(ga_provider)}
          disabled={loading}
        >
          <div className="flex justify-start text-left">
            <img src={images.GoogleLogoRound} alt="Google" className="h-8" />
          </div>
        </button>
        <button
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `border-lightWidget-primaryColor hover:bg-lightWidget-primaryColor/70`
              : `border-darkWidget-primaryColor hover:bg-darkWidget-primaryColor/70`,
            "flex items-center justify-center gap-2 rounded-full w-full py-2 border"
          )}
          onClick={() => handleSignIn(tw_provider)}
          disabled={loading}
        >
          <div className="flex justify-start text-left">
            <img
              src={
                theme.currentWidgetTheme === "dark"
                  ? images.XLogo
                  : images.BlackXLogo
              }
              alt="X"
              className="h-6 w-6"
            />
          </div>
        </button>
      </div>
      {error && (
        <div className="text-red-500 text-sm mt-2">{error.message}</div>
      )}
    </>
  )
}
