import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
  confirmPasswordReset,  
} from "firebase/auth"
import { auth } from "../../utils/firebase"

export const ResetPassword = () => {
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [error, setError] = useState("")
    const [isSuccess, setIsSuccess] = useState(false)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
  
    useEffect(() => {
      const oobCode = searchParams.get("oobCode")
      if (!oobCode) {
        navigate("/")
      }
    }, [navigate, searchParams])
  
    const resetPassword = async () => {
      const oobCode = searchParams.get("oobCode")
  
      if (password !== confirmPassword) {
        setError("Passwords did not match!")
        return
      }
  
      try {
        if (oobCode) {
          await confirmPasswordReset(auth, oobCode, password)
          setError("")
          setIsSuccess(true)
        }
      } catch (err) {
        if (err.code === "auth/weak-password") {
          setError("Password should be at least 6 characters")
        } else {
          setError("Something is wrong. Try again later")
        }
      }
    }
  
    return (
        <div className="mt-4 border-x border-t rounded-md bg-[#010101] opacity-[0.65] max-w-sm mx-auto">  
      <div className="text-gray-100 py-2 md:p-6 mx-auto min-h-[300px]">
        <p className="text-2xl text-center py-3">Reset your password</p>
        <p className="text-white text-base pt-5 text-left ml-2">Password</p>
        <div className="flex mt-3 border border-white rounded-md justify-between items-center">
          <input
            type="password"
            placeholder="password"
            className="rounded-md pl-6 bg-transparent w-full focus:border-[#010101] focus:shadow-none focus:ring-0 border-none text-white"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <p className="text-white text-base pt-5 text-left ml-2">
          Confirm Password
        </p>
        <div className="flex mt-3 border border-white rounded-md justify-between items-center">
          <input
            type="password"
            placeholder="password"
            className="rounded-md pl-6 bg-transparent w-full focus:border-[#010101] focus:shadow-none focus:ring-0 border-none text-white"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        {error && <p className="my-5 text-center text-red-600">{error}</p>}
        {isSuccess && (
          <p className="my-5 text-center text-white">
            Success! <br />
            Your Password was changed successfully!
          </p>
        )}
        <button
          className="mt-3 bg-themeColor-light dark:bg-themeColor-dark text-btnTextColor-light dark:text-btnTextColor-dark hover:bg-opacity-80 dark:hover:bg-opacity-80 rounded-full w-full py-2"
          onClick={resetPassword}
        >
          Reset Password
        </button>
        {isSuccess && (
          <button
            className="mt-3 bg-themeColor-light dark:bg-themeColor-dark text-btnTextColor-light dark:text-btnTextColor-dark hover:bg-opacity-80 dark:hover:bg-opacity-80 rounded-full w-full py-2"
            onClick={() => {
              navigate("/sign-in")
            }}
          >
            Go to Sign In
          </button>
        )}
      </div>
    </div>
    )
  }
  