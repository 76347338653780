import { forwardRef } from "react"
import Joyride, { STATUS } from "react-joyride"

export const Onboarding = forwardRef((props, ref) => {
  const onboardingSteps = [
    {
      content: (
        <div className="w-full flex flex-col gap-4 items-center justify-center">
          <img
            src="https://huskystudios.ams3.cdn.digitaloceanspaces.com/walletX/assets/logos/5426.png"
            className="rounded-full"
          />
          <p className="text-xl md:text-2xl">Welcome to Solana!</p>
          <p className="text-sm md:text-[18px] md:leading-6">
            Dive into the fast, secure world of Solana and discover the future
            of digital currency!
          </p>
        </div>
      ),
      styles: {
        options: {
          width: 300,
        },
      },
      placement: "center",
      target: "body",
    },
    {
      content: (
        <div className="w-full flex flex-col gap-4 items-center justify-center">
          <p className="text-xl md:text-2xl">Click here!</p>
        </div>
      ),
      placement: "top",
      styles: {
        buttonNext: {
          display: "none",
        },
        buttonBack: {
          display: "none",
        },
        options: {
          width: 300,
        },
      },
      target: ".menu-icon",
    },
  ]

  // const currentUser = useAuth()

  const handleJoyrideCallback = (data) => {
    // console.log(data.status)
    // const { status, type } = data
    // const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]
    // if (finishedStatuses.includes(status)) {
    //   const userDocRef = doc(db, "users", currentUser.uid)
    //   updateDoc(userDocRef, {
    //     onBoarding: false,
    //   })
    // }
  }
  return (
    <Joyride
      ref={ref}
      callback={handleJoyrideCallback}
      continuous
      scrollToFirstStep
      disableOverlayClose={true}
      hideCloseButton={true}
      run={props.onboarding}
      steps={onboardingSteps}
      styles={{
        options: {
          zIndex: 5000,
          textColor: "#000",
          primaryColor: "#ff8700",
        },
      }}
    />
  )
})
