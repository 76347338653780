import { BounceLoader, ClipLoader } from "react-spinners"
import { useTheme } from "../../context/useTheme"

export const Loader = () => {
  return (
    <ClipLoader
      color="#ffffff"
      size={50}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  )
}

export const Spinner = ({ loading }) => {
  const theme = useTheme()

  return (
    <div className="z-30 fixed inset-0 bg-opacity-75 transition-opacity opacity-100 flex items-center justify-center bg-gray-500">
      <BounceLoader
        color={theme.currentThemePrimaryColor()}
        cssOverride={{
          borderColor: theme.currentThemePrimaryColor(),
          zIndex: 31,
        }}
        loading={loading}
        size={50}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  )
}
