import { db } from "../utils/firebase";
import { getDoc, updateDoc, doc } from "firebase/firestore";

export const getApprovedTokens = async () => {
    const pathToTokens = 'approvedTokens/tokens'
    const tokenDocRef = doc(db, pathToTokens)
    const tokenDoc = await getDoc(tokenDocRef)

    if (tokenDoc.exists()) {
        return tokenDoc.data()
    } else {
        return {}
    }
}

export const addApprovedToken = async (payload) => {
    const pathToTokens = 'approvedTokens/tokens'
    const tokenDocRef = doc(db, pathToTokens)
    const tokenDoc = await getDoc(tokenDocRef)

    if (tokenDoc.exists()) {
        const data =  tokenDoc.data()
        data[payload["symbol"]] = payload
        await updateDoc(tokenDocRef, data)
        return true
    } else {
        return false
    }
}