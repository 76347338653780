import { useState, useMemo } from "react";
import { classNames } from "../../utils/formats";
import { useTheme } from "../../context/useTheme";
import SharePosition from "./SharePosition";
import Heading from "../../components/Heading";

const PaginationTable = ({
  data,
  theme,
  setSelectedTokenSymbol,
  setOpenSharePositionModal,
}) => {
  const pageSize = 5;
  const totalRows = data.length;
  const totalPages = Math.ceil(totalRows / pageSize);

  const [currentPage, setCurrentPage] = useState(1);

  // Calculate start and end index for current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize, totalRows);

  // Generate table rows for current page
  const generateRows = () => {
    const rows = [];
    for (let i = startIndex; i < endIndex; i++) {
      const holding = data[i];
      const costPrice = holding.costPrice || null;
      const pnl = holding.pnlPerc || 0;

      rows.push(
        <>
          {theme.isMobile && (
            <div
              className="hover:cursor-pointer flex flex-row w-full rounded-lg my-1 items-center justify-start overflow-x-hidden bg-panelColor2-light dark:bg-panelColor2-dark text-textColor-light dark:text-textColor-dark"
              onClick={() => {
                setSelectedTokenSymbol(i);
                setOpenSharePositionModal(true);
              }}
            >
              <div className="w-[15%] px-2 md:px-4 py-1 sm:py-2 align-middle text-[10px] sm:text-sm whitespace-nowrap text-left text-blueGray-700 ">
                <div className="flex flex-col items-center">
                  {holding.logo && (
                    <img
                      src={holding.logo}
                      alt="logo"
                      className="w-6 h-6 rounded-full"
                    />
                  )}
                  <span className="text-[10px] imd:text-sm">
                    {holding.symbol}
                  </span>
                </div>
              </div>
              <div className="w-[35%] px-4 sm:px-6 py-1 sm:py-2 align-middle text-[10px] imd:text-sm whitespace-nowrap">
                <div className="flex flex-col items-start">
                  <span>
                    {Number(
                      Math.round(holding.amount * 1000) / 1000
                    ).toLocaleString()}
                  </span>
                  <span>
                    $
                    {Number(
                      Math.round(holding.value * 100) / 100
                    ).toLocaleString()}
                  </span>
                </div>
              </div>
              <div className="w-[25%] px-4 sm:px-6 py-1 sm:py-2 align-center text-[10px] imd:text-sm whitespace-nowrap">
                <div className="flex flex-col items-start">
                  <span>
                    $
                    {Number(
                      Math.round(holding.price * 10000) / 10000
                    ).toLocaleString("en-US", {
                      style: "decimal",
                      maximumSignificantDigits: 4,
                    })}
                  </span>
                  <span>
                    $
                    {costPrice ? (
                      Number(
                        Math.round(costPrice * 10000) / 10000
                      ).toLocaleString("en-US", {
                        style: "decimal",
                        maximumSignificantDigits: 4,
                      })
                    ) : (
                      <span className="text-gray-500">N/A</span>
                    )}
                  </span>
                </div>
              </div>
              <div className="w-[25%] px-4 sm:px-6 py-1 sm:py-2 align-middle text-[10px] imd:text-sm whitespace-nowrap">
                {costPrice ? (
                  <div className="flex flex-col items-start">
                    <span
                      className={pnl > 0 ? "text-green-500" : "text-red-500"}
                    >
                      {holding.pnlAmount > 0 ? "$" : "-$"}
                      {Number(
                        Math.abs(holding.pnlAmount).toFixed(2)
                      ).toLocaleString()}{" "}
                    </span>
                    <span
                      className={pnl > 0 ? "text-green-500" : "text-red-500"}
                    >
                      {pnl.toFixed(2)}%
                    </span>
                  </div>
                ) : (
                  <span className="text-gray-500">UNCH</span>
                )}
              </div>
            </div>
          )}
          {!theme.isMobile && (
            <div
              className="hover:cursor-pointer flex flex-row w-full rounded-lg my-1 items-center justify-start overflow-x-hidden bg-panelColor2-light dark:bg-panelColor2-dark text-textColor-light dark:text-textColor-dark"
              onClick={() => {
                // setSelectedTokenSymbol(i);
                // setOpenSharePositionModal(true);
              }}
            >
              <div className="w-[10%] px-2 md:px-4 py-1 sm:py-2 align-middle text-[10px] sm:text-sm whitespace-nowrap text-left text-blueGray-700 ">
                <div className="flex flex-col items-center">
                  {holding.logo && (
                    <img
                      src={holding.logo}
                      alt="logo"
                      className="w-6 h-6 rounded-full"
                    />
                  )}
                  <span className="text-[10px] imd:text-sm">
                    {holding.symbol}
                  </span>
                </div>
              </div>
              <div className="w-[20%] px-4 sm:px-6 py-1 sm:py-2 align-middle text-[10px] imd:text-sm whitespace-nowrap">
                <div className="flex justify-center">
                  {Number(
                    Math.round(holding.amount * 1000) / 1000
                  ).toLocaleString()}
                </div>
              </div>
              <div className="w-[15%] px-4 sm:px-6 py-1 sm:py-2 align-middle text-[10px] imd:text-sm whitespace-nowrap">
                <div className="flex justify-center">
                  $
                  {Number(
                    Math.round(holding.value * 100) / 100
                  ).toLocaleString()}
                </div>
              </div>
              <div className="w-[15%] px-4 sm:px-6 py-1 sm:py-2 align-center text-[10px] imd:text-sm whitespace-nowrap">
                <div className="flex justify-center">
                  <span>
                    $
                    {Number(
                      Math.round(holding.price * 10000) / 10000
                    ).toLocaleString("en-US", {
                      style: "decimal",
                      maximumSignificantDigits: 4,
                    })}
                  </span>
                </div>
              </div>
              <div className="w-[15%] px-4 sm:px-6 py-1 sm:py-2 align-center text-[10px] imd:text-sm whitespace-nowrap">
                <div className="flex justify-center">
                  <span>
                    $
                    {costPrice ? (
                      Number(
                        Math.round(costPrice * 10000) / 10000
                      ).toLocaleString("en-US", {
                        style: "decimal",
                        maximumSignificantDigits: 4,
                      })
                    ) : (
                      <span className="text-gray-500">N/A</span>
                    )}
                  </span>
                </div>
              </div>
              <div className="w-[25%] px-4 sm:px-6 py-1 sm:py-2 align-middle text-[10px] imd:text-sm whitespace-nowrap">
                <div className="flex justify-center">
                  {costPrice ? (
                    <>
                      <span
                        className={pnl > 0 ? "text-green-500" : "text-red-500"}
                      >
                        {holding.pnlAmount > 0 ? "$" : "-$"}
                        {Number(
                          Math.abs(holding.pnlAmount).toFixed(2)
                        ).toLocaleString()}{" "}
                      </span>
                      <span
                        className={pnl > 0 ? "text-green-500" : "text-red-500"}
                      >
                        &nbsp;&nbsp;(&nbsp;{pnl.toFixed(2)}%&nbsp;)
                      </span>
                    </>
                  ) : (
                    <span className="text-gray-500">UNCH</span>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
    return rows;
  };

  // Event handler for previous page button
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  // Event handler for next page button
  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <>
      <div className="items-center bg-transparent w-full border-collapse flex flex-col">
        {theme.isMobile && (
          <div className="flex flex-row w-full">
            <div className="w-[15%] px-4 sm:px-6 align-middle py-1 sm:py-3 text-[10px] imd:text-sm uppercase whitespace-nowrap font-semibold text-left flex items-center justify-center">
              <div className="flex flex-col items-center">
                <span>TOKEN</span>
              </div>
            </div>
            <div className="w-[35%] px-4 sm:px-6 align-middle py-1 sm:py-3 text-[10px] imd:text-sm uppercase whitespace-nowrap font-semibold text-left">
              <div className="flex flex-col">
                <span>QUANTITY</span>
                <span>VALUE</span>
              </div>
            </div>
            <div className="w-[25%] px-4 sm:px-6 align-middle py-1 sm:py-3 text-[10px] imd:text-sm uppercase whitespace-nowrap font-semibold text-left">
              <div className="flex flex-col">
                <span>FAIR PRICE</span>
                <span>AVG PRICE</span>
              </div>
            </div>
            <div className="w-[25%] px-4 sm:px-6 align-middle py-1 sm:py-3 text-[10px] imd:text-sm uppercase whitespace-nowrap font-semibold text-left flex justify-start items-center md:block">
              <div className="flex flex-col">
                <span className="hidden sm:block">UNREALIZED</span>
                <span>PNL</span>
              </div>
            </div>
          </div>
        )}
        {!theme.isMobile && (
          <div className="flex flex-row w-full">
            <div className="w-[10%] px-4 sm:px-6 align-middle py-1 sm:py-3 text-[10px] imd:text-sm uppercase whitespace-nowrap font-semibold text-left flex items-center justify-center">
              TOKEN
            </div>
            <div className="w-[20%] px-4 sm:px-6 align-middle py-1 sm:py-3 text-[10px] imd:text-sm uppercase whitespace-nowrap font-semibold text-left flex items-center justify-center">
              QUANTITY
            </div>
            <div className="w-[15%] px-4 sm:px-6 align-middle py-1 sm:py-3 text-[10px] imd:text-sm uppercase whitespace-nowrap font-semibold text-left flex items-center justify-center">
              VALUE
            </div>
            <div className="w-[15%] px-4 sm:px-6 align-middle py-1 sm:py-3 text-[10px] imd:text-sm uppercase whitespace-nowrap font-semibold text-left flex items-center justify-center">
              FAIR PRICE
            </div>
            <div className="w-[15%] px-4 sm:px-6 align-middle py-1 sm:py-3 text-[10px] imd:text-sm uppercase whitespace-nowrap font-semibold text-left flex items-center justify-center">
              AVG PRICE
            </div>
            <div className="w-[25%] px-4 sm:px-6 align-middle py-1 sm:py-3 text-[10px] imd:text-sm uppercase whitespace-nowrap font-semibold text-left flex items-center justify-center">
              UNREALIZED PNL
            </div>
          </div>
        )}

        <div className="overflow-y-auto w-full">{generateRows()}</div>
      </div>
      <div className="w-full flex flex-row gap-3 text-[12px] md:text-[16px] justify-end pr-5">
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          Prev
        </button>
        <span>
          {currentPage}&nbsp;/&nbsp;{totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </>
  );
};

export const Holdings = ({ account }) => {
  const [openSharePositionModal, setOpenSharePositionModal] = useState(false);
  const [selectedTokenSymbol, setSelectedTokenSymbol] = useState(0);
  const theme = useTheme();

  const accountProfitAndLoss = account?.accountProfitAndLoss;

  const accountHoldings = useMemo(() => {
    if (account?.accountHoldings) {
      return account.accountHoldings
        .filter((holding) => holding.amount !== 0) // Filter out holdings with zero balance
        .map((holding) => {
          const symbol = holding.symbol;
          const profitAndLoss = accountProfitAndLoss
            ? accountProfitAndLoss[symbol]
            : undefined;
          if (profitAndLoss) {
            return {
              ...holding,
              costPrice: profitAndLoss.costPrice,
              marketValue: holding.amount * holding.price,
              costValue: profitAndLoss.costPrice * holding.amount,
              pnlAmount:
                holding.amount * (holding.price - profitAndLoss.costPrice),
              pnlPerc:
                ((holding.price - profitAndLoss.costPrice) /
                  profitAndLoss.costPrice) *
                100,
            };
          }

          return holding;
        })
        .sort((a, b) => b.value - a.value);
    }

    return [];
  }, [account, accountProfitAndLoss]);

  return (
    <>
      <div className="w-full">
        <div className="relative flex flex-col min-w-0 break-words w-full rounded-xl px-1 py-2 sm:p-2 md:p-3 min-h-[240px] sm:min-h-[350px] bg-panelColor-light dark:bg-panelColor-dark text-textColor-light dark:text-textColor-dark">
          <div className="rounded-t mb-0 px-3 py-1 sm:py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full max-w-full flex-grow flex-1">
                <Heading>Your Holdings</Heading>
              </div>
            </div>
          </div>

          <div className="block w-full overflow-x-hidden">
            {accountHoldings && (
              <PaginationTable
                data={accountHoldings}
                theme={theme}
                setSelectedTokenSymbol={setSelectedTokenSymbol}
                setOpenSharePositionModal={setOpenSharePositionModal}
              />
            )}
          </div>
        </div>
      </div>
      {accountHoldings && (
        <SharePosition
          openModal={openSharePositionModal}
          setOpenModal={setOpenSharePositionModal}
          tokenNum={selectedTokenSymbol}
          data={accountHoldings}
        />
      )}
    </>
  );
};
