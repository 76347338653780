import React, { useEffect, useState } from "react";
import { getBalanceDirect, sendTransaction } from "../../hooks/functions";
import { buildNativeTx } from "../../hooks/functions";
import Amounts from "./Amounts";
import RecipientAddress from "./RecipientAddress";
import { ConfirmSendButton } from "../../components/Buttons";
import { fetchWallets } from "../../hooks/functions";
import { Address } from "./Address";

const SendMobile = ({ user, account }) => {
  const [sending, setSending] = useState(false);
  const [sendingButtonLoadingText, setSendingButtonLoadingText] =
    useState("Confirm");
  const [response, setResponse] = useState({});
  const [txDetails, setTxDetails] = useState({ tokenPrice: 0, value: 0 });
  const [validations, setValidations] = useState({
    address: false,
    addressError: "",
    amount: false,
    amountError: "",
  });
  const [balance, setBalance] = useState(0);
  const [addresses, setAddresses] = useState([
    { address: account.address, index: 0 },
  ]);
  const [selected, setSelected] = useState(0);

  const getSolanaBalance = async () => {
    let address = null; // Initialize variable to store the result

    for (const item of addresses) {
      if (item.index == selected) {
        address = item.address;
        break;
      }
    }

    const balance = await getBalanceDirect(address, "sol", "sol");
    setBalance(balance.confirmed);
  };

  useEffect(() => {
    const fetchWalletAddresses = async () => {
      const data = await fetchWallets(user.uid, account.address);
      setAddresses(data.wallets);
    };

    fetchWalletAddresses();
  }, [user, account]);

  useEffect(() => {
    getSolanaBalance();
  }, [selected]);

  const buildTransaction = async () => {
    setSending(true);
    setResponse({});
    setSendingButtonLoadingText("Sending...");
    if (!validations.address || !validations.amount) {
      setResponse({
        error: "Invalid transaction details",
        details: "Check recipient address and amount.",
      });
      setSending(false);
      setSendingButtonLoadingText("Confirm");
      return;
    }
    try {
      const lamports = txDetails.amount * 1e9; // Assumes amount is already validated
      const { status, base64SerializedTransaction, responseMessage } =
        await buildNativeTx(txDetails.recipientAddress, lamports, selected);
      setSendingButtonLoadingText("Confirming...");
      if (status === "failed") {
        setSendingButtonLoadingText("Failed");
        console.error("Transaction failed", responseMessage);
        //if(InsufficientFundsForRent)
        if (responseMessage.includes("InsufficientFundsForRent")) {
          setResponse({
            error: "Failed!",
            details:
              "Insufficient funds for transaction fees, try reducing the amount",
          });
        } else {
          setResponse({
            error: "Transaction failed",
            details: responseMessage,
          });
        }

        setSending(false);
        setSendingButtonLoadingText("Confirm");
        return;
      } else {
        const sendData = {
          to: txDetails.recipientAddress,
          amount: txDetails.amount,
        };

        let address = null; // Initialize variable to store the result

        for (const item of addresses) {
          if (item.index == selected) {
            address = item.address;
            break;
          }
        }

        sendTransaction(
          base64SerializedTransaction,
          user.uid,
          address,
          sendData
        );
        /*
        if (response?.transaction.signatures[0]) {
          const txLink = `https://solscan.io/tx/${response.transaction.signatures[0]}`
          setSendingButtonLoadingText("Confirm")
          setResponse({ message: `Transaction sent`, txLink: txLink })
          // setOpenSendModal(false)
          setTxDetails({ tokenPrice: 0, value: 0 })
          setSending(false)
        } else {
          setSendingButtonLoadingText("Failed")
          setResponse({ error: "Transaction failed", details: response })
          setSending(false)
        }*/
        setSendingButtonLoadingText("Confirm");
        setResponse({ message: `Transaction sent`, txLink: "" });
        setTxDetails({ tokenPrice: 0, value: 0 });
        setSending(false);
      }
    } catch (error) {
      console.error("Transaction failed", error.message);
      setResponse({ error: "Transaction failed", details: error.message });
    } finally {
      setSending(false);
    }
  };

  // const handleFetchButton = async () => {
  //   const uid = "34e0ofLHx6Od3CXOD7Ayy26cUU83"
  //   const accountUid = "B7Dr8GnGhrB9xJkHFxpbzFPWKRk6suJ3PtPw2Qq9sjm9"
  //   await fetchWallets(uid, accountUid)
  // }

  return (
    <div className="absolute inset-0 flex justify-center px-2">
      <div
        className="flex flex-col my-auto p-4 text-center max-w-[400px] w-full rounded-xl bg-panelColor-light dark:bg-panelColor-dark text-textColor-light dark:text-textColor-dark"
      >
        <div className="w-full">
          <div className="mt-5 flex flex-col gap-2 justify-center items-center">
            <p className="text-2xl font-medium">Send SOL</p>

            <div className="mt-4 w-full flex justify-center items-center">
              <img src="https://huskystudios.ams3.cdn.digitaloceanspaces.com/walletX/assets/logos/5426.png" className="rounded-full" />
            </div>
            <Address addresses={addresses} handleSelected={setSelected} />
            <RecipientAddress
              {...{
                txDetails,
                setTxDetails,
                validations,
                setValidations,
              }}
            />

            <Amounts
              {...{
                txDetails,
                setTxDetails,
                validations,
                setValidations,
                balance: balance,
                symbol: "SOL",
              }}
            />

            <div className="mt-2 pr-2 w-full text-right">
              <p>
                Available &nbsp;&nbsp;{balance.toFixed(6)}
                &nbsp;&nbsp;SOL
              </p>
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-row gap-2 w-full">
          <ConfirmSendButton
            type="button"
            className="bg-themeColor-light dark:bg-themeColor-light text-btnTextColor-light dark:text-btnTextColor-dark"
            disabled={sending || !validations.address || !validations.amount}
            onClick={buildTransaction}
          >
            {sendingButtonLoadingText}
          </ConfirmSendButton>
        </div>
        <div className="mt-4 text-black w-full">
          {response.message && (
            <p className="text-green-500">{response.message}</p>
          )}
          {response.error && (
            <p className="text-red-500">
              {response.error}
              <br />
              {response.details}
            </p>
          )}
        </div>
       
      </div>
    </div>
  );
};

export default SendMobile;
