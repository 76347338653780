import { CheckCircleIcon } from "@heroicons/react/20/solid"
import { useTheme } from "../../context/useTheme"
import { classNames } from "../../utils/formats"
import { useNavigate } from "react-router-dom"

export const WidgetSuccess = ({
  formattedQuote,
  inputToken,
  outputToken,
  handleSteps,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()

  return (
    <div className="w-full flex-grow flex flex-col px-4 py-6">
      <div className="flex justify-center mt-5">
        <CheckCircleIcon className="w-[80px]" />
      </div>
      <p className="text-center my-8 text-xl">
        Your Order Was Sent Successfully
      </p>
      <div className="flex flex-col items-center justify-center gap-2">
        <p>
          <span className="text-sm">You sold </span>
          <span className="text-lg">
            {formattedQuote?.inputAmount} {inputToken?.symbol}
          </span>
        </p>
        <p>
          <span className="text-sm">You will receive </span>
          <span className="text-lg">
            {formattedQuote?.outputAmount} {outputToken?.symbol}
          </span>
        </p>
      </div>
      <p className="text-center my-7 text-sm px-10">
        You can navigate away from here, we will let you know when your tx is
        confirmed.
      </p>
      <div className="flex flex-grow items-end justify-center">
        <button
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `bg-lightWidget-primaryColor hover:bg-lightWidget-primaryColor/70`
              : `bg-darkWidget-primaryColor hover:bg-darkWidget-primaryColor/70`,
            "w-2/3 rounded-md font-bold py-3 hover:cursor-pointer text-[14px]"
          )}
          onClick={() => navigate("/app")}
        >
          Go to Dashboard
        </button>
      </div>
    </div>
  )
}
