import { icons } from "../../assets"

export const Setting = () => {
  return (
    <div className="bg-[#808080] min-h-screen">
      <div className="sm:p-10 p-7">
        <p className="text-4xl font-bold text-white">Settings</p>
        {/* ---------------------currency------------------------- */}
        <p className="sm:pt-10 pt-5 text-white text-2xl font-bold">
          Change language
        </p>
        <p className="pt-2 text-white opacity-50">
          Select your preferred language
        </p>
        <div className="mt-3 pr-4 border-white rounded-md border flex items-center justify-between max-w-lg">
          <select className="text-white bg-transparent rounded-md border-none focus:border-white focus:shadow-none focus:ring-0 bg-none w-full">
            <option>USD - US Dollar</option>
          </select>
          <img
            alt="DownArrowIcon"
            src={icons.DownArrowIcon}
            className="w-4 h-auto"
          />
        </div>
        <div className="sm:block hidden mt-6 border-t border-[#e2e2e2] max-w-lg"></div>

        {/* ---------------------language------------------------- */}
        <p className="sm:pt-10 pt-5 text-white text-2xl font-bold">
          Change language
        </p>
        <p className="pt-2 text-white opacity-50">
          Select your preferred language
        </p>
        <div className="mt-3 pr-4 border-white rounded-md border flex items-center justify-between max-w-lg">
          <select className="text-white bg-transparent rounded-md border-none focus:border-white focus:shadow-none focus:ring-0 bg-none w-full">
            <option>English</option>
          </select>
          <img
            alt="DownArrowIcon"
            src={icons.DownArrowIcon}
            className="w-4 h-auto"
          />
        </div>
        <div className="sm:block hidden mt-6 border-t border-[#e2e2e2] max-w-lg"></div>

        {/* ---------------------logout------------------------- */}
        <p className="sm:pt-10 pt-5 text-white text-2xl font-bold">
          Logout from everywhere
        </p>
        <p className="pt-2 text-white opacity-50">
          Logout from all sessions & devices
        </p>
        <button className="text-white bg-transparent rounded-md text-lg font-bold border py-2 px-16 mt-3 sm:w-fit w-full">
          Logout
        </button>
        <div className="sm:block hidden mt-6 border-t border-[#e2e2e2] max-w-lg"></div>

        {/* ---------------------delete account------------------------- */}
        <p className="sm:pt-10 pt-5 text-white text-2xl font-bold">
          Delete account
        </p>
        <p className="pt-2 text-white opacity-50">Delete your Achi account</p>
        <button className="text-white bg-transparent rounded-md text-lg font-bold border py-2 px-7 mt-3 sm:w-fit w-full">
          Delete account
        </button>
      </div>
    </div>
  )
}
