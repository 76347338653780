import { useEffect, useState } from "react";
import { db } from "../utils/firebase";
import { doc, getDoc } from "firebase/firestore";

export const useDataWidget = ({ buyToken }) => {
  const [chartData, setChartData] = useState([
    ["x", "Price(USD)"],
    [0, 0],
  ]);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    if (!buyToken || !buyToken.contract) {
      setError("Invalid buyToken object or missing contract.");
      return;
    }

    const lowerCaseMint = buyToken.contract.toLowerCase();
    const pathToChartData = `priceData/${lowerCaseMint}`;

    const fetchData = async () => {
      try {
        setLoading(true);
        // Fetching chart data from document
        const chartDocRef = doc(db, pathToChartData);
        const chartDocSnapshot = await getDoc(chartDocRef);
        if (!chartDocSnapshot.exists) {
          throw new Error(`No historical data found for mint: ${lowerCaseMint}`);
        }

        const historicalData = chartDocSnapshot.data().historicalData || [];
        const data = historicalData.map((entry) => [
          new Date(entry.timestamp),
          parseFloat(entry.priceUsd),
        ]);

        if (isMounted) {
          setChartData([["x", "Price(USD)"], ...data]);
          setError(null); // Clear any previous error
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
        if (isMounted) {
          setError("Error fetching data. Please try again later.");
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [buyToken]);

  return { chartData, error, loading };
};
