import { reduceString } from "../../utils/maskString";

export const Address = ({ addresses, handleSelected }) => {
  const handleSelectChange = (event) => {
    handleSelected(event.target.value);
  };
  return (
    <select
      onChange={handleSelectChange}
      className="rounded-md bg-[#eee] focus:border-[#aaa] focus:shadow-none focus:ring-0 w-full text-[16px] mt-4 bg-transparent"
    >
      {addresses.length > 0 &&
        addresses.map((item, key) => (
          <option key={key} value={item.index} className="bg-panelColor-light dark:bg-panelColor-dark">
            {reduceString(item.address, 20)}
          </option>
        ))}
    </select>
  );
};
