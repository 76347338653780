import React, { useState, useEffect } from "react"
import { useTheme } from "../../context/useTheme"
import { classNames } from "../../utils/formats"

// Custom debounce hook
function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    // Cancel the timeout if value changes (also on component unmount), this is how we prevent debounced value from changing if value is changed within the delay period.
    return () => {
      clearTimeout(handler)
    }
  }, [value, delay]) // Only re-call effect if value or delay changes

  return debouncedValue
}

// Component that uses the custom debounce hook
function WidgetDebouncedInput({ amount }) {
  const [inputValue, setInputValue] = useState(amount)
  const theme = useTheme()
  // useEffect(() => {
  //   // Effect for doing something with the debounced input
  //   handleAmountChange({ target: { value: debouncedInputValue } })
  //   // Here you can call an API or perform some other action
  // }, [debouncedInputValue]) // Only re-run if debounced value changes

  //onclicking the input field, select the value
  const handleFocus = (e) => {
    if (e.target.value === "0") {
      e.target.value = ""
    } else {
      e.target.select()
    }
  }

  // Handle input click for mobile devices
  const handleClick = (e) => {
    handleFocus(e)
  }

  // Handle input change and format value
  const handleChange = (event) => {
    let value = event.target.value || "0"
    value = Number(value.replaceAll(",", "")).toLocaleString()
  }

  useEffect(() => {
    let valueFormatted = Number(amount.replaceAll(",", "")).toLocaleString()

    setInputValue(valueFormatted)
  }, [amount])

  return (
    <div className="flex items-center justify-center pt-2 relative">
      <input
        type="text"
        className={classNames(
          theme.currentWidgetTheme === "light"
            ? `text-lightWidget-textColor`
            : `text-darkWidget-textColor`,
          "bg-transparent text-center outline-none border-b border-l-0 border-t-0 border-r-0 focus:border-b focus:outline-none focus:ring-0 text-3xl px-3 py-2 max-w-[180px]"
        )}
        size={9}
        readOnly={true}
        value={inputValue}
        onClick={handleClick}
        onChange={handleChange}
      />
      <span
        className={classNames(
          theme.currentWidgetTheme === "light"
            ? `text-lightWidget-textColor`
            : `text-darkWidget-textColor`,
          "text-2xl opacity-70 absolute right-0"
        )}
      >
        $
      </span>
    </div>
  )
}

export default WidgetDebouncedInput
