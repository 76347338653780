import { forwardRef } from "react"
import Joyride, { STATUS } from "react-joyride"
import { doc, updateDoc } from "firebase/firestore"
import { db } from "../../utils/firebase"

export const Guide = forwardRef((props, ref) => {
  const guideSteps = [
    {
      content: (
        <div className="w-full flex flex-col gap-4 items-center justify-center">
          <p className="text-xl md:text-2xl">Solana: The Currency of Choice</p>
          <p className="text-sm md:text-[18px] md:leading-6">
            On the Solana network, SOL is as fundamental as USD is in the U.S.
            For simplicity, we denominate all values in your preferred currency,
            making it easy to manage and understand your transactions.
          </p>
        </div>
      ),
      placement: "top",
      styles: {
        options: {
          width: 300,
        },
      },
      target: ".third-btn",
    },
    {
      content: (
        <div className="w-full flex flex-col gap-4 items-center justify-center">
          <p className="text-xl md:text-2xl">Power Up Your Wallet With SOL</p>
          <p className="text-sm md:text-[18px] md:leading-6">
            To activate and use your wallet on the Solana network, think of SOL
            like fuel for your car—it's essential! You can easily fill up with
            SOL using a Card, ACH, Apple Pay, Google Pay, or one of over 140
            other currencies.
          </p>
        </div>
      ),
      placement: "top",
      styles: {
        options: {
          width: 300,
        },
      },
      target: ".forth-btn",
    },
    {
      content: (
        <div className="w-full flex flex-col gap-4 items-center justify-center">
          <p className="text-xl md:text-2xl">Trading Made Simple</p>
          <p className="text-sm md:text-[18px] md:leading-6">
            Swap SOL for other cryptocurrencies directly from your wallet in
            just a few clicks—trading made easy!
          </p>
        </div>
      ),
      placement: "top",
      styles: {
        options: {
          width: 300,
        },
      },
      target: ".second-btn",
    },
    {
      content: (
        <div className="w-full flex flex-col gap-4 items-center justify-center">
          <p className="text-xl md:text-2xl">Trading Made Simple</p>
          <p className="text-sm md:text-[18px] md:leading-6">
            When you are trading, you are swapping SOL for your desired token.
          </p>
        </div>
      ),
      placement: "top",
      styles: {
        options: {
          width: 300,
        },
      },
      target: ".second-btn",
    },
  ]

  const handleGuideCallback = (data) => {
    const { status, type } = data
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]
    if (finishedStatuses.includes(status)) {
      const userDocRef = doc(db, "users", props.uid)
      updateDoc(userDocRef, {
        onBoarding: false,
      })
    }
  }
  return (
    <Joyride
      ref={ref}
      callback={handleGuideCallback}
      continuous
      scrollToFirstStep
      disableOverlayClose={true}
      hideCloseButton={true}
      run={props.onboarding}
      steps={guideSteps}
      styles={{
        options: {
          zIndex: 20000,
          textColor: "#000",
          primaryColor: "#ff8700",
        },
      }}
    />
  )
})
