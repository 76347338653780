import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

export const TokensListModal = ({
  showStatus,
  setShowStatus,
  setSelectedToken,
  tokenList,
}) => {
  const [query, setQuery] = useState("");
  const [approvedTokens, setApprovedTokens] = useState([]);

  const filteredTokens =
    query === ""
      ? approvedTokens
      : approvedTokens.filter((token) => {
          const lists =
            token.name.toLowerCase().includes(query.toLowerCase()) ||
            token.symbol.toLowerCase().includes(query.toLowerCase()) ||
            token.contract.toLowerCase().includes(query.toLowerCase());
          return lists;
        });

  useEffect(() => {
    const res = tokenList;
    const list = [];
    for (let key in res) {
      list.push(res[key]);
    }
    setApprovedTokens(list);
  }, [tokenList]);

  useEffect(() => {
    showStatus && setQuery("");
  }, [showStatus]);

  return (
    <Transition.Root show={showStatus} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setShowStatus(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="bg-panelColor-light dark:bg-panelColor-dark text-textColor-light dark:text-textColor-dark relative transform px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full sm:my-8 sm:w-full sm:max-w-md sm:p-6 h-[80vh] max-h-[600px] overflow-auto">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6"
                    >
                      Approved token list
                    </Dialog.Title>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="bg-panelColor2-light dark:bg-panelColor2-dark text-textColor-light dark:text-textColor-dark block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-0 focus:ring-inset sm:text-sm sm:leading-6 focus:border-none focus:outline-none"
                        placeholder="Search name or symbol or address"
                        onChange={(e) => setQuery(e.target.value)}
                      />
                    </div>
                    <div className="mt-6 flex flex-col gap-2">
                      {filteredTokens.map((item) => (
                        <div
                          key={item.symbol}
                          className="flex items-center justify-between cursor-pointer py-1 px-4 border-[1px] border-transparent hover:border-white hover:rounded-md"
                          onClick={() => {
                            setSelectedToken(item.symbol);
                            setShowStatus(false);
                          }}
                        >
                          <div className="flex items-center">
                            <img
                              alt="logo"
                              src={item.logo}
                              className="w-8 h-auto rounded-full mr-4"
                            />
                            <div>
                              <p className="font-semibold text-start">
                                {item.symbol}
                              </p>
                              <p className="text-sm text-start">{item.name}</p>
                            </div>
                          </div>
                          <ChevronRightIcon className="w-4 h-4" />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 ">
                  <button
                    type="button"
                    className="bg-themeColor-light dark:bg-themeColor-dark text-btnTextColor-light dark:text-btnTextColor-dark inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={() => {
                      setShowStatus(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
