import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { ClipLoader } from "react-spinners";
import { addApprovedToken } from "../../hooks/handleApprovedTokens";
import axios from "axios"

export const AddTokenModal = ({ openModal, setOpenModal }) => {
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [logo, setLogo] = useState("");
  const [decimals, setDecimals] = useState(0);
  const [cmcId, setCmcId] = useState("");
  const [contract, setContract] = useState("");
  const [loading, setLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useState("")
  const [tokenAccountMessage, setTokenAccountMessage] = useState("")

  const handleName = (event) => {
    const value = event.target.value;
    setName(value);
  };

  const handleSymbol = (event) => {
    const value = event.target.value;
    setSymbol(value);
  };

  const handleLogo = (event) => {
    const value = event.target.value;
    setLogo(value);
  };

  const handleDecimals = (event) => {
    const value = event.target.value;
    setDecimals(value);
  };

  const handleCmcId = (event) => {
    const value = event.target.value;
    setCmcId(value);
  };

  const handleContract = (event) => {
    const value = event.target.value;
    setContract(value);
  };

  const handleAddToken = async () => {
    setLoading(true)
    setStatusMessage("")
    const payload = {
        "name": name,
        "symbol": symbol,
        "logo": logo,
        "decimals": decimals,
        "cmc_id": cmcId,
        "contract": contract
    }
    const res = await addApprovedToken(payload)

    const result = await axios.post("https://backend-ytpq6wu7ia-ey.a.run.app/createTokenAccount", {contract: contract})

    setTokenAccountMessage(result["data"]["payload"]["message"])  
    setLoading(false)
    if (res) {
        setStatusMessage("New Token Added")
    } else {
        setStatusMessage("Failed")
    }
  }

  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setOpenModal(true)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="bg-panelColor-light fixed inset-0 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-0 text-center md:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <Dialog.Panel className="bg-bgColor-light dark:bg-bgColor-dark text-textColor-light dark:text-textColor-dark relative transform rounded-xl text-left shadow-xl transition-all mx-3 px-3 pb-8 w-full max-w-sm">
                <div className="flex mr-2 h-12 items-center justify-end">
                  <XMarkIcon
                    className="h-6 w-6 cursor-pointer mt-2"
                    aria-hidden="true"
                    onClick={() => setOpenModal(false)}
                  />
                </div>
                <div className="flex flex-col gap-3 items-center">
                  <p className="text-xl">Add a New Token</p>
                  <input
                    type="text"
                    placeholder="Name: Achi"
                    value={name}
                    onChange={handleName}
                    aria-describedby="address-helper-text"
                    className="rounded-md bg-transparent focus:shadow-none focus:ring-0 w-full text-[16px]"
                  />
                  <input
                    type="text"
                    placeholder="Symbol: ACHI"
                    value={symbol}
                    onChange={handleSymbol}
                    aria-describedby="address-helper-text"
                    className="rounded-md bg-transparent focus:shadow-none focus:ring-0 w-full text-[16px]"
                  />
                  <input
                    type="text"
                    placeholder="Logo: https://..."
                    value={logo}
                    onChange={handleLogo}
                    aria-describedby="address-helper-text"
                    className="rounded-md bg-transparent focus:shadow-none focus:ring-0 w-full text-[16px]"
                  />
                  <input
                    type="number"
                    placeholder="Decimals: 9"
                    value={decimals === 0 ? "" : decimals}
                    onChange={handleDecimals}
                    aria-describedby="address-helper-text"
                    className="rounded-md bg-transparent focus:shadow-none focus:ring-0 w-full text-[16px]"
                  />
                  <input
                    type="text"
                    placeholder="cmc_id: 28755"
                    value={cmcId}
                    onChange={handleCmcId}
                    aria-describedby="address-helper-text"
                    className="rounded-md bg-transparent focus:shadow-none focus:ring-0 w-full text-[16px]"
                  />
                  <input
                    type="text"
                    placeholder="Contract: HvQK....."
                    value={contract}
                    onChange={handleContract}
                    aria-describedby="address-helper-text"
                    className="rounded-md bg-transparent focus:shadow-none focus:ring-0 w-full text-[16px]"
                  />
                  <p className="text-green-500">{statusMessage}</p>
                  <p className="text-green-500">{tokenAccountMessage}</p>
                  <button
                    onClick={handleAddToken}
                    disabled={loading}
                    className="flex justify-center items-center w-[200px] rounded-xl py-2 bg-themeColor-light hover:bg-themeColor-light/70 text-btnTextColor-light"
                  >
                    {loading ? (
                      <ClipLoader
                        color="#ffffff"
                        size={24}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : (
                      "Add"
                    )}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
