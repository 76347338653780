import { reduceString } from "../../utils/maskString";

export const WalletAddress = ({ addresses, handleSelected }) => {
    const handleSelectChange = (event) => {
      handleSelected(event.target.value);
    };
    return (
      <select
        onChange={handleSelectChange}
        className="rounded-md bg-panelColor2-light dark:bg-panelColor2-dark border-0 focus:shadow-none focus:ring-0 w-full text-[16px] hover:cursor-pointer"
      >
        {addresses.length > 0 &&
          addresses.map((item, key) => (
            <option key={key} value={item.index} className="bg-panelColor2-light dark:bg-panelColor2-dark">
              {reduceString(item.address, 24)}
            </option>
          ))}
      </select>
    );
  };