import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { signInWithEmailAndPassword } from "firebase/auth"
import { auth } from "../../utils/firebase"
import { images } from "../../assets"
import { ExtProviderButtons } from "./auth_ext_provider"
import { useTheme } from "../../context/useTheme"
import { classNames } from "../../utils/formats"

export const WidgetSignIn = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loginError, setLoginError] = useState(false)
  const [loading, setLoading] = useState(false)
  const theme = useTheme()

  //check to see if user is already logged in

  const handleSignIn = async (e) => {
    e.preventDefault()
    setLoading(true)
    try {
      setLoading(true)
      await signInWithEmailAndPassword(auth, email, password)
      navigate("/swap")
    } catch (err) {
      setLoading(false)
      setLoginError("Failed to log in")
    }
  }

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      handleSignIn(e)
    }
  }

  return (
    <>
      <p className="pt-5 flex">Email</p>
      <div className="flex rounded-md mt-2">
        <input
          type="text"
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `border-lightWidget-primaryColor hover:border-lightWidget-primaryColor/70`
              : `border-darkWidget-primaryColor hover:border-darkWidget-primaryColor/70`,
            "flex py-2 w-full focus:shadow-none focus:ring-0 border bg-transparent rounded-md"
          )}
          placeholder="achithebest@example.com"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <p className="pt-5 flex">Password</p>
      <div className="flex rounded-md mt-2">
        <input
          type="password"
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `border-lightWidget-primaryColor hover:border-lightWidget-primaryColor/70`
              : `border-darkWidget-primaryColor hover:border-darkWidget-primaryColor/70`,
            "flex py-2 w-full focus:shadow-none focus:ring-0 border bg-transparent rounded-md"
          )}
          placeholder="password"
          onChange={(e) => setPassword(e.target.value)}
          onKeyUp={handleKeyUp}
        />
      </div>

      {loginError && (
        <p className="text-[#f00] mt-2">Input correct credentials</p>
      )}
      <div className="flex gap-3">
        <button
          className="bg-[#bbbbbb] hover:bg-[#bbbbbb]/80 rounded-full py-2 w-full mt-4"
          onClick={() => navigate("/swap")}
        >
          Back
        </button>
        <button
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `bg-lightWidget-primaryColor hover:bg-lightWidget-primaryColor/70`
              : `bg-darkWidget-primaryColor hover:bg-darkWidget-primaryColor/70`,
            "rounded-full py-2 w-full mt-4"
          )}
          onClick={handleSignIn}
        >
          Login
        </button>
      </div>

      <p
        className="text-right text-[14px] mt-3 hover:cursor-pointer hover:underline"
        onClick={() => navigate("/swap/reset")}
      >
        forgot password?
      </p>
      <div className="mt-3 flex items-center justify-between">
        <img
          src={
            theme.currentWidgetTheme === "light"
              ? images.BlackLine
              : images.Line
          }
          alt="Line"
          className="w-24 h-auto"
        />
        <p>Or</p>
        <img
          src={
            theme.currentWidgetTheme === "light"
              ? images.BlackLine
              : images.Line
          }
          alt="Line"
          className="w-24 h-auto"
        />
      </div>

      <ExtProviderButtons />

      <button
        className={classNames(
          theme.currentWidgetTheme === "light"
            ? `bg-lightWidget-primaryColor hover:bg-lightWidget-primaryColor/70`
            : `bg-darkWidget-primaryColor hover:bg-darkWidget-primaryColor/70`,
          "mt-3 mb-12 rounded-full w-full py-2"
        )}
        onClick={() => navigate("/swap/signup")}
      >
        Create new account
      </button>
    </>
  )
}
