export const SelectDuration = ({status, selectedDuration, handleDuration}) => {
  return (
    <div className="flex-grow flex flex-row gap-2 justify-around items-center text-[12px] px-2 lp:px-4 pc:px-0">
      <div className="flex gap-1 w-1/2 justify-start items-center">
        <input
          type="radio"
          className={`focus:ring-transparent focus:outline-none w-3 h-3 ${
            status
              ? "text-themeColor-light hover:cursor-pointer"
              : "text-gray-600 hover:cursor-not-allowed"
          }`}
          checked={selectedDuration === 1}
          onChange={() => {
            handleDuration(1);
          }}
          disabled={!status}
        />
        <p
          className={`text-center  ${
            status ? "hover:cursor-pointer" : "hover:cursor-not-allowed"
          }`}
          onClick={() => {
            if (status) handleDuration(1);
          }}
        >
          1 Day
        </p>
      </div>
      <div className="flex gap-1 w-1/2 justify-start items-center">
        <input
          type="radio"
          className={`focus:ring-transparent focus:outline-none w-3 h-3 ${
            status
              ? "text-themeColor-light hover:cursor-pointer"
              : "text-gray-600 hover:cursor-not-allowed"
          }`}
          checked={selectedDuration === 7}
          onChange={() => {
            handleDuration(7);
          }}
          disabled={!status}
        />
        <p
          className={`text-center  ${
            status ? "hover:cursor-pointer" : "hover:cursor-not-allowed"
          }`}
          onClick={() => {
            if (status) handleDuration(7);
          }}
        >
          1 Week
        </p>
      </div>
    </div>
  );
};
