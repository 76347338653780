import { Fragment, useEffect, useState } from "react"

import { useTheme } from "../../context/useTheme"
import { CoinbaseButton } from "../../components/CoinbaseOnRamp"
import { classNames } from "../../utils/formats"
import { images } from "../../assets"

export const Deposit = ({ account, setLoading }) => {
  const [onramperState, setOnramperState] = useState(0)
  const theme = useTheme()
  const onRamperHeight = "70vh"

  return (
    <div className="absolute inset-0 flex justify-center px-2">
      <div
        className="w-full my-auto md:max-w-[700px] p-4 text-center rounded-xl bg-panelColor-light dark:bg-panelColor-dark text-textColor-light dark:text-textColor-dark"
      >
        {onramperState === 0 && (
          <div className="w-full flex flex-col gap-3 justify-center items-center">
            <div className="font-bold text-[18px] md:text-2xl mt-3 text-center">
              How do you want to deposit?
            </div>
            <div
              className="border rounded-xl w-[70%] flex flex-col items-center justify-center"
            >
              <CoinbaseButton
                address={account.address}
                setLoading={setLoading}
              />
              <hr
                className="w-full h-[2px] border"
              />
              <div
                className="w-full rounded-br-xl rounded-bl-xl flex justify-center items-center hover:cursor-pointer py-3"
                onClick={() => {
                  setOnramperState(1)
                }}
              >
                {theme.currentTheme !== "dark" ? (
                  <img
                    src={images.OnramperLogo}
                    className="w-[50%] hover:cursor-pointer"
                  />
                ) : (
                  <img
                    src={images.OnramperLogoWhite}
                    className="w-[50%] hover:cursor-pointer"
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {onramperState === 1 && (
          <iframe
            title="onRamper Widget"
            src={`https://buy.onramper.com?wallets=SOL:${account.address}&onlyCryptoNetworks=solana&apiKey=pk_prod_01HSXVQTS1574EJYWDXSC51EK2&mode=buy&themeName=${theme.currentTheme}`}
            style={{
              width: "100%",
              height: onRamperHeight,
            }}
          />
        )}
      </div>
    </div>
  )
}
