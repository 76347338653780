import React, { useState, useEffect } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../utils/firebase';

const Sparkline = ({ mint, trend }) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    const fetchSparkline = async () => {
      try {
        const mintToLowerCase = mint.toLowerCase();
        const sparklineRef = ref(storage, `sparklines/${mintToLowerCase}.svg`);
        const url = await getDownloadURL(sparklineRef);
        setUrl(url);
      } catch (error) {
        console.error('Error fetching sparkline URL:', error);
      }
    };

    fetchSparkline();
  }, [mint]);

  const filterClass = trend === 'up' ? "hue-rotate(85deg) saturate(80%) brightness(0.85)"
        : "hue-rotate(300deg) saturate(190%) brightness(0.7) contrast(270%)"

  return (
    <div>
      {url ? (
        <img
        style={{ filter: filterClass }}
        src={url} alt={`Sparkline for ${mint}`} className={`w-20 h-8`} />
      ) : (
        <p className='text-[12px] text-gray-500 italic'>
            Loading sparkline...</p>
      )}
    </div>
  );
};

export default Sparkline;