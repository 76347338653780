import Heading from "../Heading"

export const Welcome = ({ user }) => {

  let welcomeMessage = "Welcome back, "

  if (user?.onBoarding) {
    welcomeMessage = "Welcome, "
  }

  return (
    <div className="w-full py-3 imd:py-5 px-2 imd:px-5">
      <Heading>
        {welcomeMessage}
        {user?.firstName}.
      </Heading>
    </div>
  )
}
