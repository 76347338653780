import { useState, useEffect } from "react";

export const usePortfolioData = ({ account }) => {
  const [duration, setDuration] = useState("1d");
  const [percentage, setPercentage] = useState(0);
  const [data, setData] = useState([["x", "Price(USD)"]]);

  const calculateMovingAverage = (data, windowSize) => {
    if (data.length < windowSize) return data;

    const result = [];
    for (let i = windowSize - 1; i < data.length; i++) {
      const windowData = data.slice(i - windowSize + 1, i + 1);
      const sum = windowData.reduce((acc, value) => acc + value[1], 0);
      const average = sum / windowData.length;
      result.push([data[i][0], average]);
    }

    return result;
  };

  const removeOutliers = (data) => {
    const values = data.map(item => item[1]);
    const sortedValues = [...values].sort((a, b) => a - b);
    const q1 = sortedValues[Math.floor((sortedValues.length / 4))];
    const q3 = sortedValues[Math.ceil((sortedValues.length * (3 / 4))) - 1];
    const iqr = q3 - q1;
    const lowerBound = q1 - 1.5 * iqr;
    const upperBound = q3 + 1.5 * iqr;

    return data.filter(item => item[1] >= lowerBound && item[1] <= upperBound);
  };

  const resampleData = (data, interval) => {
    const resampledData = [];
    let currentTime = data[0][0].getTime();
    const endTime = data[data.length - 1][0].getTime();

    while (currentTime <= endTime) {
      const nextTime = currentTime + interval;
      const windowData = data.filter(([time]) => time.getTime() >= currentTime && time.getTime() < nextTime);

      if (windowData.length > 0) {
        const averageValue = windowData.reduce((acc, value) => acc + value[1], 0) / windowData.length;
        resampledData.push([new Date(currentTime), averageValue]);
      }

      currentTime = nextTime;
    }

    return resampledData;
  };

  useEffect(() => {
    const portfolio = account?.portfolioHistory || [];
    if (!portfolio.length) {
      setData([["x", "Price(USD)"], [new Date(), 0]]);
      setPercentage(0);
      return;
    }

    const currentTime = new Date().getTime();

    const timeDiffs = {
      "1d": 86400000,
      "1w": 604800000,
      "1m": 2592000000,
      "3m": 7776000000,
      "1y": 31536000000,
    };

    const intervalDurations = {
      "1d": 3600000, // 1 hour
      "1w": 86400000, // 1 day
      "1m": 86400000, // 1 day
      "3m": 604800000, // 1 week
      "1y": 2592000000, // 1 month
    };

    const timeAgo = currentTime - timeDiffs[duration];
    const filteredData = portfolio
      .map(({ timestamp, value }) => [new Date(timestamp * 1000), value])
      .filter(([date]) => date.getTime() > timeAgo);

    if (filteredData.length === 0) {
      setData([["x", "Price(USD)"], [new Date(), 0]]);
      setPercentage(0);
    } else {
      const latestDataPoint = filteredData[filteredData.length - 1];

      // Remove the latest data point for resampling and outlier removal
      const dataWithoutLatest = filteredData.slice(0, -1);

      // Resample data to regular intervals
      const resampledData = resampleData(dataWithoutLatest, intervalDurations[duration]);

      // Remove outliers
      const dataWithoutOutliers = removeOutliers(resampledData);

      // Apply moving average to smooth the data
      const smoothedData = calculateMovingAverage(dataWithoutOutliers, 5); // Adjust window size as needed

      // Add the latest data point back
      const finalData = [["x", "Price(USD)"], ...resampledData, latestDataPoint];
      setData(finalData);

      // Calculate percentage change between the first and the last values
      const lastValue = finalData[finalData.length - 1]?.[1] ?? 0;
      const firstValue = finalData[1]?.[1] ?? 0;
      const percentageChange = firstValue
        ? ((lastValue - firstValue) / firstValue) * 100
        : 0;

      setPercentage(Number.isNaN(percentageChange) ? 0 : percentageChange.toFixed(2));
    }
  }, [account?.portfolioHistory, duration]); // Add account?.portfolioHistory to dependencies

  return { duration, setDuration, percentage, data };
};
