import { useState } from "react"
import { classNames } from "../../utils/formats"
import { useTheme } from "../../context/useTheme"
import { usePriceData } from "../../hooks/usePriceData"
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid"
import { convertToMegaAndKilo, formatPrice } from "../../utils/formats"
import { MarketLoader } from "../ContentLoader"

export const MarketView = () => {
  const theme = useTheme()
  const { priceData, loading } = usePriceData()
  const [interval] = useState("h24")

  return (
    <>
      {loading && <MarketLoader />}
      {!loading && priceData && (
        <>
          {" "}
          <div className="mb-0 px-4 py-3 flex flex-col gap-2">
            <p className="text-xl md:text-4xl font-semibold">Market</p>
            <p className="text-xs md:text-xl text-right w-full">
              Last updated:{" "}
              {priceData?.timestamp &&
                new Date(priceData.timestamp).toLocaleString()}
            </p>
          </div>
          <div className="flex items-center w-full font-bold text-xs py-2">
            <div className="px-5 w-[40%]">Token</div>
            <div className="w-[30%] md:w-[45%] pl-2">
              Price
              <br />
              Change
            </div>
            <div className="w-[30%] md:w-[30%] text-right px-3">
              24H
              <br />
              MCap
            </div>
          </div>
          <div className="w-full flex flex-col overflow-x-hidden md:overflow-x-auto overflow-y-auto px-1">
            <div>
              {priceData.data.map((token, index) => (
                <div
                  key={index}
                  //onClick={() => navigate(`/app/data/${token.mint}`)} PHASE LATER
                  className="hover:cursor-pointer w-full flex flex-row justify-center items-center my-2 px-3 py-1 rounded-xl text-xs sm:text-sm md:text-xl bg-panelColor2-light dark:bg-panelColor2-dark"
                >
                  {/* Logo and Name */}
                  <div className="py-2 w-[40%] md:w-[25%]">
                    <div className="flex items-center justify-start">
                      <img
                        src={token.logo}
                        alt={token.name}
                        className="w-6 h-6 mr-2 rounded-full"
                      />
                      {token.name}
                    </div>
                  </div>
                  {/* Price and Percentage */}
                  <div className="w-[35%] md:w-[45%] lg:w-[35%] xl:w-[30%]">
                    <div className="flex flex-col lg:flex-row items-start md:items-center justify-start md:justify-between mx-1">
                      ${formatPrice(token.data.priceUsd)}
                      {token.data.priceChange[interval] > 0 ? (
                        <span className="text-green-500 text-xs">
                          {" "}
                          ({token.data.priceChange[interval]}%) ▲
                        </span>
                      ) : (
                        <span className="text-red-500 text-xs">
                          {" "}
                          ({token.data.priceChange[interval]}%) ▼
                        </span>
                      )}
                    </div>
                  </div>
                  {/* Volume and Fdv */}
                  <div className="w-[25%] md:w-[30%] lg:w-[40%] flex flex-col imd:flex-row md:flex-col lg:flex-row items-end imd:justify-between md:justify-start lg:justify-between lg:pl-6 xl:px-6">
                    <div>
                      {convertToMegaAndKilo(token.data.volume[interval])}
                    </div>
                    <div>
                      $
                      {convertToMegaAndKilo(
                        token.symbol == "SOL" ? token.marketCap : token.data.fdv // Solana doesn't have fdv
                      )}
                    </div>
                  </div>
                  {/* Link */}
                  <div className="hidden xl:flex xl:w-[5%]">
                    <div className="flex items-center justify-start">
                      <a
                        href={token.data.url}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="flex items-center"
                      >
                        <ArrowTopRightOnSquareIcon
                          className={classNames(
                            theme.defaultPanel,
                            "h-4 w-4 sm:h-6 sm:w-6 cursor-pointer"
                          )}
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  )
}
