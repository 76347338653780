import { useState, useEffect, useCallback } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../context/useAuth';
import { db } from '../utils/firebase';

export const useGetTransaction = (account, txId) => {
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  const fetchTransaction = useCallback(async (controller) => {
    if (!currentUser || !account?.address || !txId) return;

    const pathToDocument = `users/${currentUser.uid}/accounts/${account.address}/transactions/${txId}`;
    const docRef = doc(db, pathToDocument);

    try {
      const docSnapshot = await getDoc(docRef, { signal: controller.signal });
      if (docSnapshot.exists()) {
        setTransaction({ ...docSnapshot.data(), uid: docSnapshot.id });
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error("Error fetching transaction: ", error);
      }
    } finally {
      setLoading(false);
    }
  }, [currentUser, account, txId]);

  useEffect(() => {
    const controller = new AbortController();
    fetchTransaction(controller);

    return () => controller.abort();
  }, [fetchTransaction]);

  const refresh = () => {
    setLoading(true);
    const controller = new AbortController();
    fetchTransaction(controller);

    return () => controller.abort();
  };

  return { transaction, loading, refresh };
};
