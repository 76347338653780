import { Outlet } from "react-router-dom"
import { Suspense } from "react"
import { useTheme } from "../../context/useTheme"
import { classNames } from "../../utils/formats"

export const WidgetLayout = () => {
  const theme = useTheme()

  return (
    <div className="w-screen h-screen flex bg-[#f7f9f9]">
      <div
        className={classNames(
          theme.currentWidgetTheme === "light"
            ? `bg-lightWidget-bgColor text-lightWidget-textColor`
            : `bg-darkWidget-bgColor text-darkWidget-textColor`,
          "w-full max-w-[400px] overflow-x-auto overflow-y-auto mx-auto my-auto flex relative h-full max-h-[650px]"
        )}
      >
        <Suspense fallback={<p>Loading...</p>}>
          <Outlet />
        </Suspense>
      </div>
    </div>
  )
}
