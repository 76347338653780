import { ClipLoader } from "react-spinners";

export const Button = ({
  handleClick,
  classNames,
  isActive,
  buttonText,
  size,
}) => {
  return (
    <button
      onClick={handleClick}
      disabled={isActive}
      className={`flex justify-center items-center rounded-xl py-2 text-btnTextColor-light ${
        classNames
          ? classNames
          : "bg-themeColor-light hover:bg-themeColor-light/70"
      } w-[${size ? size : "200px"}]`}
    >
      {isActive ? (
        <ClipLoader
          color="#ffffff"
          size={24}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        buttonText
      )}
    </button>
  );
};
