import { useEffect, useState } from "react"
import {
  ArrowsRightLeftIcon,
  ChartBarIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid"
import { PriceChart } from "../../components/PriceChart"
import { useDataWidget } from "../../hooks/useDataWidget"
import { useTokenInfo } from "../../hooks/useTokenInfo"
import { useDexscreener } from "../../hooks/useDexscreener"
import DataWidget from "../../components/DataWidget"
import {
  TradeLeftLoader,
  TradeRightLoader,
} from "../../components/ContentLoader"
const DataCard = ({
  buyToken,
  showcard,
  setShowCard,
  isLoading,
  setLoading,
}) => {

  const [toggleChart, setToggleChart] = useState(false)

  const chart = useDataWidget({ buyToken })
  const token = useTokenInfo(buyToken.contract)
  const price = useDexscreener(buyToken.contract)

  // const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!chart.loading && !token.loading && !price.loading) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [chart.loading, token.loading, price.loading])

  const style = {
    width: `${price.buyPercentage}%`,
  }

  return (
    <div
      className="w-full md:w-[400px] md:min-h-[650px] md:h-full relative group overflow-hidden px-2 md:px-4 py-5 rounded-xl md:pt-10 bg-panelColor-light dark:bg-panelColor-dark text-textColor-light dark:text-textColor-dark"
    >
      {isLoading ? (
        <TradeLeftLoader />
      ) : (
        <div className="flex flex-col gap-1 w-full relative">
          <div className="w-full flex justify-end gap-3">
            <>
              <ArrowsRightLeftIcon
                className={`h-6 w-6 mr-2 text-xs md:hidden hover:cursor-pointer`}
                aria-hidden="true"
                onClick={() => {
                  setShowCard(!showcard)
                }}
              />
              {toggleChart && (
                <InformationCircleIcon
                  aria-hidden="true"
                  className="w-6 h-6 hover:cursor-pointer"
                  onClick={() => {
                    setToggleChart(false)
                  }}
                />
              )}
              {!toggleChart && (
                <ChartBarIcon
                  aria-hidden="true"
                  className="w-6 h-6 hover:cursor-pointer"
                  onClick={() => {
                    setToggleChart(true)
                  }}
                />
              )}
            </>
          </div>
          <div className="w-full h-[200px] flex justify-center items-center">
            {!toggleChart && (
              <div className="w-full">
                <div className="w-full flex">
                  {/* Logo */}
                  <div className="flex flex-row w-[40%] justify-center items-center overflow-hidden">
                    <img
                      src={buyToken["logo"]}
                      alt="Logo"
                      className="max-w-[100px] w-full rounded-[50%] p-2"
                    />
                  </div>
                  <div className="flex flex-col w-[60%] justify-center items-center px-2">
                    {/* Token Name */}
                    <p className="w-full text-center text-[25px] md:text-[30px] font-extrabold">
                      {buyToken["name"]}
                    </p>
                    {/* Token Price */}
                    <div className="flex justify-center items-center text-center text-[20px] md:text-[25px] font-bold">
                      $
                      {Number(
                        Math.round(price.priceQuery["priceUsd"] * 10000) / 10000
                      ).toLocaleString("en-US", {
                        style: "decimal",
                        maximumSignificantDigits: 4,
                      })}
                    </div>
                    {/* Token Price Change Percentage */}
                    <div className="flex flex-row justify-center items-center text-center">
                      <div className="text-[15px] md:text-[20px] font-medium">
                        {price.priceQuery["priceChange"]["h24"] > 0
                          ? "+" +
                            Number(
                              price.priceQuery["priceChange"]["h24"]
                            ).toLocaleString()
                          : Number(
                              price.priceQuery["priceChange"]["h24"]
                            ).toLocaleString()}
                        %
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {toggleChart && (
              <div className="w-full">
                <div className="w-full flex flex-col">
                  {/* Token Name */}
                  <p className="w-full text-left text-[25px] md:text-[30px] font-extrabold ml-4">
                    {buyToken["name"]}
                  </p>
                  {/* Token Price */}
                  <div className="flex justify-start items-end text-center text-[20px] md:text-[25px] font-bold ml-4 gap-3">
                    <div>
                      $
                      {Number(
                        Math.round(price.priceQuery["priceUsd"] * 10000) / 10000
                      ).toLocaleString("en-US", {
                        style: "decimal",
                        maximumSignificantDigits: 4,
                      })}
                    </div>
                    <div className="text-[15px] md:text-[20px] font-medium pb-[1px] md:pb-[3px]">
                      {price.priceQuery["priceChange"]["h24"] > 0
                        ? "+" +
                          Number(
                            price.priceQuery["priceChange"]["h24"]
                          ).toLocaleString()
                        : Number(
                            price.priceQuery["priceChange"]["h24"]
                          ).toLocaleString()}
                      %
                    </div>
                  </div>
                  {chart.chartData && (
                    <PriceChart chartData={chart.chartData} />
                  )}
                </div>
              </div>
            )}
          </div>
          <DataWidget
            buyToken={buyToken}
            tokenInfo={token.tokenInfo}
            priceQuery={price.priceQuery}
            buyPercentage={price.buyPercentage}
            style={style}
          />
        </div>
      )}
    </div>
  )
}

export default DataCard
