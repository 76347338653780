import { maskString } from "../../utils/maskString";
import { IoCopyOutline } from "react-icons/io5";

export const WalletAddress = ({ address }) => {
  const handlePaste = async (event) => {
    await navigator.clipboard.writeText(address);
  };
  return (
    <div className="flex justify-start items-center gap-1 text-[12px]">
      <span className="mr-1">Wallet :</span>
      <span className="tracking-normal tb:tracking-widest pc:tracking-wider">{maskString(address, 15)}</span>
      <span>
        <IoCopyOutline
          className="w-4 h-4 hover:cursor-pointer hover:opacity-70"
          onClick={handlePaste}
        />
      </span>
    </div>
  );
};
