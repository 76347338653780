import React, { Fragment, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { classNames } from "../../utils/formats"
import { useTheme } from "../../context/useTheme"
import { XMarkIcon } from "@heroicons/react/20/solid"
import QRCode from "qrcode.react"

const SharePosition = ({ openModal, setOpenModal, tokenNum, data }) => {
  const theme = useTheme()

  function QRCodeGenerator({ value }) {
    return (
      <div>
        <QRCode value={value} size={50} />
      </div>
    )
  }

  const pnlPerc = data?.[tokenNum]?.pnlPerc;
  const pnlPercFormatted = (typeof pnlPerc === "number")
    ? (pnlPerc > 0 ? "+" + pnlPerc.toFixed(2) : pnlPerc.toFixed(2))
    : "0.00";
  
  const costPrice = data?.[tokenNum]?.costPrice;
  const fairPriceFormatted = (typeof costPrice === "number")
    ? costPrice.toFixed(2)
    : "0.00";
  


  

  const getDate = () => {
    const now = new Date()
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, "0") // Adding 1 to month because it's zero-indexed
    const day = String(now.getDate()).padStart(2, "0")
    const hours = String(now.getHours()).padStart(2, "0")
    const minutes = String(now.getMinutes()).padStart(2, "0")
    const seconds = String(now.getSeconds()).padStart(2, "0")
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  return (
    <>
      <Transition.Root show={openModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setOpenModal(true)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={classNames(theme.defaultPanel,
                "fixed inset-0 bg-opacity-75 transition-opacity"
              )}
            />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-0 text-center md:items-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                enterTo="opacity-100 translate-y-0 md:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 md:scale-100"
                leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              >
                <Dialog.Panel
                  className={classNames(
                    theme.currentTheme === "dogTheme"
                      ? "bg-dogTheme-panelColor text-dogTheme-textStrongColor"
                      : theme.currentTheme === "lightTheme"
                      ? "bg-lightTheme-gradient text-lightTheme-textStrongColor"
                      : "bg-darkTheme-gradient text-darkTheme-textStrongColor",
                    "relative transform md:rounded-3xl rounded-xl text-left shadow-xl transition-all mx-3 my-8 w-full max-w-sm overflow-visible md:h-auto h-max-[500px]"
                  )}
                >
                  <div className="flex mr-2 h-12 items-center justify-end">
                    <XMarkIcon
                      className={classNames(
                        theme.currentTheme === "dogTheme"
                          ? "text-dogTheme-textStrongColor"
                          : theme.currentTheme === "lightTheme"
                          ? "text-lightTheme-textStrongColor"
                          : "text-darkTheme-textStrongColor",
                        "h-6 w-6 cursor-pointer mr-2 mt-2"
                      )}
                      aria-hidden="true"
                      onClick={() => setOpenModal(false)}
                    />
                  </div>
                  {data.length !== 0 && (
                    <div className="w-full flex flex-col gap-2 relative">
                      {/* Headings */}
                      <div className="flex flex-row gap-3 px-4 justify-start items-end">
                        <div className="text-[25px] font-bold">
                          {data[tokenNum]["name"]}
                        </div>
                        <div className="text-[16px] font-medium pb-[3px]">
                          {data[tokenNum]["symbol"]}
                        </div>
                      </div>
                      {/* Percentage */}
                      <div className="text-[18px] font-semibold px-4 mb-10">
                        {pnlPercFormatted}
                        %
                      </div>
                      {/* Price */}
                      <div className="flex flex-col px-4 text-sm gap-1">
                        <div className="flex">
                          <div className="w-[35%]">Entry Price</div>
                          <div className="w-[65%]">{fairPriceFormatted}</div>                            
                        </div>
                        <div className="flex">
                          <div className="w-[35%]">Fair Price</div>
                          <div className="w-[65%]">
                            ${data[tokenNum]["price"]}
                          </div>
                        </div>
                        <div className="flex">
                          <div className="w-[35%]">Date</div>
                          <div className="w-[65%]">{getDate()}</div>
                        </div>
                      </div>
                      <div
                        className={classNames(
                          theme.currentTheme === "dogTheme"
                            ? "bg-dogTheme-panelColor/70 text-dogTheme-textStrongColor"
                            : theme.currentTheme === "lightTheme"
                            ? "bg-lightTheme-panelColor/70 text-lightTheme-textStrongColor"
                            : "bg-darkTheme-panelColor/70 text-darkTheme-textStrongColor",
                          "flex flex-row px-4 py-2 justify-start items-center md:rounded-br-3xl rounded-br-xl md:rounded-bl-3xl rounded-bl-xl"
                        )}
                      ></div>
                      {/* Affiliate 
                      <div
                        className={classNames(
                          theme.currentTheme === "dogTheme"
                            ? "bg-dogTheme-panelColor/70 text-dogTheme-textStrongColor"
                            : theme.currentTheme === "lightTheme"
                            ? "bg-lightTheme-panelColor/70 text-lightTheme-textStrongColor"
                            : "bg-darkTheme-panelColor/70 text-darkTheme-textStrongColor",
                          "flex flex-row px-4 py-2 justify-start items-center md:rounded-br-3xl rounded-br-xl md:rounded-bl-3xl rounded-bl-xl"
                        )}
                      >
                        <div className="w-[20%]">
                          {QRCodeGenerator("122U3K")}
                        </div>
                        <div className="w-[80%] flex flex-col pl-2">
                          <span className="text-[16px]">
                            Referral Code 122U3K
                          </span>
                          <span className="text-sm">
                            Enjoy the lowest trading fee.
                          </span>
                        </div>
                      </div>*/}
                      {/* background */}
                      <img
                        src={data[tokenNum]["logo"]}
                        alt="logo"
                        className="absolute right-3 bottom-10 max-w-[150px] max-h-[150px] w-[40%] opacity-40 -z-10 rounded-full"
                      />
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default SharePosition
