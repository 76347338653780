import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ResetPassword } from "./ResetPassword";
import { SigninForm } from "./SignIn";

export const Signin = () => {
  const [searchParams] = useSearchParams();
  const [mode, setMode] = useState(null);

  useEffect(() => {
    const modeTemp = searchParams.get("mode")
    if (modeTemp) {
      setMode(modeTemp)
    }
  }, [searchParams])


  return (
    <div className="flex flex-col h-screen overflow-hidden">
      {mode === "resetPassword" ? <ResetPassword />  : <SigninForm />}      
    </div>
  );
};
