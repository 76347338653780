// Notifications.jsx
import React from "react"
import { XMarkIcon } from "@heroicons/react/20/solid"
import { doc, updateDoc } from "firebase/firestore"
import { db } from "../../utils/firebase"
import { useTheme } from "../../context/useTheme"
import { classNames } from "../../utils/formats"

const Notifications = ({ user }) => {
  const theme = useTheme()

  const deleteMessage = async (txId) => {
    const userRef = doc(db, `users/${user.uid}/`)

    const messages = user.messages.filter((message) => message.txId !== txId)
    await updateDoc(userRef, { messages })
  }

  const txLink = `https://solscan.io/tx/`

  return (
    <div>
      {user.messages &&
        user.messages.map(
          (message, index) =>
            !message.read && (
              <div
                key={index}
                className={"bg-slate-400 w-full text-white p-4 shadow-lg z-50 fixed top-0 left-0"
            }
              >
                <div className="max-w-[1024px] flex mx-auto justify-between">
                  <span className="text-sm">
                    📢 {message.summary}{" "}
                    <a
                      href={`${message.txId ? txLink + message.txId : "#"}`}
                      target="_blank"
                      rel="noreferrer"
                      className="underline font-bold ml-2"
                    >
                      View transaction
                    </a>
                  </span>

                  <button
                    onClick={() => deleteMessage(message.txId)}
                    className="focus:outline-none"
                  >
                    <XMarkIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            )
        )}
    </div>
  )
}

export default Notifications
