import { useState, useEffect } from "react"
import WidgetDebouncedInput from "./debounce_input"
import { WidgetTokenListModal } from "./token_list"
import { QuoteResponseView } from "../Trade/QuoteResponseView"
import {
  ArrowsUpDownIcon,
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid"
import useSwapWidget from "../../hooks/useSwapWidget"
import {
  ReviewOrderButton,
  BackButton,
  SubmitSwapButton,
} from "../../components/Buttons"
import { classNames } from "../../utils/formats"
import { useTheme } from "../../context/useTheme"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { approvedTokens } from "../../constant"
import { WidgetDeposit } from "./deposit"
import { WidgetSuccess } from "./success"
import { WidgetKeypad } from "./numpad"
import { WidgetTokenPriceChart } from "./token_price_chart"
import { signOut } from "firebase/auth"
import { auth } from "../../utils/firebase"

export const WidgetInput = ({ account }) => {
  const [openTokenListModal, setOpenTokenListModal] = useState(false)
  const [openBuyTokenModal, setOpenBuyTokenModal] = useState(false)
  const [isSignedIn, setIsSignedIn] = useState(false)

  useEffect(() => {
    if (Object.keys(account).length > 0) {
      setIsSignedIn(true)
    } else {
      setIsSignedIn(false)
    }
  }, [account])

  const navigate = useNavigate()

  function maskString(input) {
    if (input.length <= 6) {
      return input // Return the input as is if it's 6 characters or less
    }

    const firstThree = input.substring(0, 5)
    const lastThree = input.substring(input.length - 5)
    const maxAsterisks = 30
    const middleAsterisks = Math.min(maxAsterisks, input.length - 10)
    const middle = "*".repeat(middleAsterisks)

    return firstThree + middle + lastThree
  }

  const handleLogout = async () => {
    await signOut(auth)
    navigate("/swap")
  }

  const theme = useTheme()

  const {
    inputToken,
    outputToken,
    usdValue,
    usdValues,
    response,
    handleUsdAmountChange,
    handleTokenSelect,
    swapTokensUI,
    startSwapTokens,
    isSwapping,
    formattedQuote,
    gettingQuote,
    steps,
    setSteps,
  } = useSwapWidget(account)

  const { tradeId } = useParams() // Destructure the parameter from useParams
  const [searchParams] = useSearchParams()
  const mode = searchParams.get("mode")

  const init = async () => {
    handleTokenSelect(approvedTokens[0], true) // true means sell token
    if (tradeId) {
      const token = approvedTokens.find((token) => token.contract === tradeId)
      if (token) {
        handleTokenSelect(token, false) // false here means buy token
        return
      }
    }

    handleTokenSelect(approvedTokens[4], false) // false here means buy token
  }

  useEffect(() => {
    init()
  }, [account])

  useEffect(() => {
    if (mode == null || mode == undefined) {
      const widgetTheme = localStorage.getItem("widgetTheme")
      theme.saveWidgetTheme(widgetTheme ? widgetTheme : "light")
    } else {
      theme.saveWidgetTheme(mode)
    }
  }, [mode])

  return (
    <>
      {steps === 0 && (
        <div
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `text-lightWidget-textColor`
              : `text-darkWidget-textColor`,
            "w-full flex flex-col relative"
          )}
        >
          {/* Token Price Chart */}
          <WidgetTokenPriceChart token={outputToken} />

          <div className="w-full flex flex-grow flex-col px-4">
            <div className="flex justify-center items-center mt-3">
              {/*Debounce so there is a delay when sending the quote for input typing*/}
              <WidgetDebouncedInput amount={usdValue} />
            </div>

            <div className="flex-grow flex flex-col">
              <p className="mt-6 text-[12px] flex justify-between">
                You are selling
              </p>
              <div className="flex flex-row items-center justify-between gap-[5px]">
                <QuoteResponseView
                  gettingQuote={gettingQuote}
                  amount={formattedQuote?.inputAmount}
                  usdValues={usdValues["inputToken"]}
                  viewMode={3}
                />

                <WidgetTokenListModal
                  showStatus={openTokenListModal}
                  isBottomToken={false}
                  setShowStatus={setOpenTokenListModal}
                  selectedToken={inputToken}
                  setSelectedToken={(token) => handleTokenSelect(token, true)}
                />
              </div>
              <div className="flex items-center justify-between">
                <p className="text-[12px]">You are buying</p>
                <div className="py-2">
                  <ArrowsUpDownIcon
                    className="h-5 w-5 mr-16 cursor-pointer"
                    aria-hidden="true"
                    onClick={swapTokensUI}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center justify-between gap-[5px]">
                <QuoteResponseView
                  gettingQuote={gettingQuote}
                  amount={formattedQuote?.outputAmount}
                  viewMode={3}
                />
                <WidgetTokenListModal
                  showStatus={openBuyTokenModal}
                  isBottomToken={true}
                  setShowStatus={setOpenBuyTokenModal}
                  selectedToken={outputToken}
                  setSelectedToken={(token) => {
                    handleTokenSelect(token, false)
                  }}
                />
              </div>
              <WidgetKeypad
                handleAmountChange={handleUsdAmountChange}
                amount={usdValue}
              />

              <div className="flex flex-col flex-grow justify-end md:p-0">
                <ReviewOrderButton
                  disabled={usdValue == 0}
                  onClick={() => {
                    if (isSignedIn) {
                      setSteps(3)
                    } else {
                      navigate("/swap/signin")
                    }
                  }}
                  className={classNames(
                    usdValue > 0
                      ? theme.currentWidgetTheme === "light"
                        ? "bg-lightWidget-primaryColor hover:bg-lightWidget-primaryColor/70"
                        : "bg-darkWidget-primaryColor hover:bg-darkWidget-primaryColor/70"
                      : "bg-[#ddd] hover:cursor-not-allowed"
                  )}
                >
                  Buy
                </ReviewOrderButton>
              </div>
              <hr className="h-[2px] my-2 bg-slate-200" />
              <div className="w-full flex gap-2 justify-between px-2 items-center pb-6">
                {Object.keys(account).length === 0 && (
                  <>
                    <button
                      className="w-1/2 rounded-md font-semibold pb-2 hover:cursor-pointer hover:underline"
                      onClick={() => navigate("/swap/signin")}
                    >
                      Sign In
                    </button>
                    <button
                      className="w-1/2 rounded-md font-semibold pb-2 hover:cursor-pointer hover:underline"
                      onClick={() => navigate("/swap/signup")}
                    >
                      Sign Up
                    </button>
                  </>
                )}
                {Object.keys(account).length > 0 && (
                  <>
                    <span className="text-[10px] w-full">
                      Wallet : {account.address}
                    </span>
                    <ArrowRightStartOnRectangleIcon
                      className="w-5 h-5 hover:opacity-80 hover:cursor-pointer"
                      onClick={handleLogout}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {steps === 1 && (
        <div className="w-full flex-grow flex flex-col px-6 py-6">
          <p className="mt-8 text-lg">You are selling</p>
          <div className="flex flex-row items-end justify-between mt-3 ml-3">
            <QuoteResponseView
              gettingQuote={gettingQuote}
              amount={formattedQuote?.inputAmount}
              usdValues={usdValues["inputToken"]}
              viewMode={2}
            />
            <div className="flex items-center">
              <img
                alt="Token Logo"
                src={inputToken?.logo}
                className="rounded-full w-8 h-8"
              />
              <span className="font-bold ml-4 text-xl">
                {inputToken?.symbol}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between mt-3">
            <p className="mt-3 text-lg">You are buying</p>
          </div>
          <div className="flex flex-row items-end justify-between mt-3 ml-3">
            <QuoteResponseView
              gettingQuote={gettingQuote}
              amount={formattedQuote?.outputAmount}
              usdValues={usdValues["outputToken"]}
              viewMode={2}
            />

            <div className="flex items-center">
              <img
                alt="Token Logo"
                src={outputToken?.logo}
                className="rounded-full w-8 h-8"
              />
              <span className="font-bold ml-4 text-xl">
                {outputToken?.symbol}
              </span>
            </div>
          </div>
          <div className="flex flex-col mt-3">
            <p className="mt-3 text-sm font-semibold flex justify-between">
              <span>Platform fee: </span>
              <span>{Number(formattedQuote?.platformFeeBps) / 100} %</span>
            </p>
            <p className="mt-3 ml-3 text-sm flex justify-between">
              <span>Network fee:</span> <span>{formattedQuote.feeAmount}</span>
            </p>
            <p className="mt-3 ml-3 text-sm flex justify-between">
              <span>Price Impact %: </span>
              <span>{formattedQuote?.priceImpactPct}%</span>
            </p>
            <p className="mt-3 text-sm font-semibold flex justify-between">
              <span>Time to complete: </span>
              <span>30s</span>
            </p>
          </div>

          <div className="gap-2 flex-grow flex flex-col justify-end">
            <div className="flex flex-row gap-2 mt-6">
              <BackButton
                disabled={isSwapping}
                className="hover:bg-[#888888]/80"
                onClick={() => {
                  setSteps(0)
                }}
              >
                Back
              </BackButton>

              <SubmitSwapButton
                disabled={isSwapping}
                onClick={() => {
                  startSwapTokens(true)
                }}
                className={classNames(
                  theme.currentWidgetTheme === "light"
                    ? `bg-lightWidget-primaryColor hover:bg-lightWidget-primaryColor/70`
                    : `bg-darkWidget-primaryColor hover:bg-darkWidget-primaryColor/70`,
                  ""
                )}
              >
                {isSwapping ? (
                  <div
                    className={`animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-[bg-[${theme.currentThemePrimaryColor()}]`}
                  ></div>
                ) : (
                  "Swap Tokens"
                )}
              </SubmitSwapButton>
            </div>
          </div>
          {response?.error && (
            <p className="text-red-500 text-center pb-3">{response.error}</p>
          )}
        </div>
      )}
      {steps === 2 && (
        <WidgetSuccess
          formattedQuote={formattedQuote}
          inputToken={inputToken}
          outputToken={outputToken}
          handleSteps={setSteps}
        />
      )}
      {steps === 3 && (
        <WidgetDeposit
          account={account}
          handleSteps={setSteps}
          usdValue={usdValue}
        />
      )}
    </>
  )
}
