import React from "react"

export const AvailableBalance = ({ inputToken, response, isLoading }) => {
  const balanceInUSD = inputToken?.balance
    ? Number(
        Math.round(
          Number(inputToken.usdPrice) * Number(inputToken.balance) * 100
        ) / 100
      ).toLocaleString()
    : ""

  const tokenBalance = inputToken?.balance
    ? Number(inputToken.balance).toLocaleString()
    : ""
  const tokenSymbol = inputToken?.symbol

  return (
    <div className="flex flex-col items-center h-6">
      {!response.error ? (
        <div className="text-center mt-2 text-xs sm:text-sm flex justify-center w-full pb-2">
          Your available balance is{" "}
          {isLoading ? (
            <div className="animate-pulse h-full flex flex-col pl-2">
              <div className="w-28 h-full bg-gray-300 rounded"></div>
            </div>
          ) : (
            <>
              ${balanceInUSD} ({tokenBalance} {tokenSymbol})
            </>
          )}
        </div>
      ) : (
        <div className="text-center mt-2 text-xs sm:text-sm flex justify-center w-full pb-2">
          {!isLoading && response.error && (
            <p className="text-center text-red-500 mt-2 text-xs sm:text-sm">
              {response.error}
            </p>
          )}

          {!isLoading && response.message && (
            <p className="text-center text-green-500 mt-2 text-xs sm:text-sm">
              {response.message}
            </p>
          )}
        </div>
      )}
    </div>
  )
}
