import { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../utils/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";
import {
  HomeIcon,
  PaperAirplaneIcon,
  QrCodeIcon,
  BanknotesIcon,
  DocumentTextIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { classNames } from "../../utils/formats";
import { useTheme } from "../../context/useTheme";
import { ShowPrivateKey } from "./ShowPrivateKey";
import ReferralCodes from "./ReferralCodes";

export const Profile = ({ account, user }) => {
  const [openShowPrivateKeyModal, setOpenShowPrivateKeyModal] = useState(false);
  const theme = useTheme();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [themeName, setThemeName] = useState(
    user?.theme ? user.theme : "light"
  );
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const [togglePassword, setTogglePassword] = useState(false);

  const handleGetPrivateKey = async () => {
    setOpenShowPrivateKeyModal(!openShowPrivateKeyModal);
  };

  const switchTheme = () => {
    if (themeName === "light") {
      document.documentElement.classList.remove("dark");
      theme.saveTheme("light");
    } else if (themeName === "dark") {
      document.documentElement.classList.add("dark");
      theme.saveTheme("dark");
    }
  };

  const saveProfile = async () => {
    setIsSaving(true);
    const currentUser = auth.currentUser;

    // Name Updating
    const uid = currentUser.uid;
    const userRef = doc(db, "users", uid);

    await updateDoc(userRef, {
      firstName: firstName === "" ? user.firstName : firstName,
      lastName: lastName === "" ? user.lastName : lastName,
      displayName: `${firstName === "" ? user.firstName : firstName} ${
        lastName === "" ? user.lastName : lastName
      }`,
      theme: themeName,
    });

    // Password Updating
    if (currentPassword !== "" && currentPassword !== null) {
      if (newPassword === "" || confirmPassword === "") {
        setErrorMessage("Please input password");
        setIsSaving(false);
        return;
      }
      if (newPassword !== confirmPassword) {
        setErrorMessage("Passwords don't match!");
        setIsSaving(false);
        return;
      }

      try {
        const credential = EmailAuthProvider.credential(
          currentUser.email,
          currentPassword
        );
        await reauthenticateWithCredential(currentUser, credential);

        await updatePassword(currentUser, newPassword);
        setErrorMessage("Password updated successfully!");
      } catch (err) {
        setErrorMessage("Error updating password: " + err.message);
      }
    }

    // Theme Updating
    switchTheme();

    setIsSaving(false);
  };

  return (
    <div className="flex flex-col text-textColor-light dark:text-textColor-dark">
      <div>
        <div className="isolate px-4 py-4 lg:px-8">
          {/* Profile */}
          <div className="mx-auto text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
              Profile
            </h2>
          </div>
          <form
            action="#"
            method="POST"
            className="mx-auto mt-5 max-w-xl md:mt-12"
          >
            <div className="grid grid-cols-1 gap-x-8 gap-y-3 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <label className="block text-sm font-semibold leading-6">
                  First name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={user.firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label className="block text-sm font-semibold leading-6">
                  Last name
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={user.lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label className="block text-sm font-semibold leading-6">
                  Email
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={user.email}
                    readOnly
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="verification"
                  className="block text-sm font-semibold leading-6"
                >
                  Wallet address
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    className="block w-full rounded-md border-0 px-3.5 py-2 shadow-sm ring-1 text-gray-900 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={account.address}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </form>

          {/* Theme */}
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl pt-8">
              Theme
            </h2>
          </div>
          <form
            action="#"
            method="POST"
            className="mx-auto mt-8 max-w-xl sm:mt-12"
          >
            <div className="w-full flex justify-around items-center">
              {/* <div className="flex items-center gap-1">
                <input
                  type="radio"
                  name="theme"
                  value="dog"
                  onChange={() => {
                    setThemeName("dogTheme")
                  }}
                  checked={themeName === "dogTheme"}
                />
                <span>Dog</span>
              </div> */}
              <div className="flex items-center gap-1 hover:cursor-pointer">
                <input
                  type="radio"
                  name="theme"
                  value="light"
                  onChange={() => {
                    setThemeName("light");
                  }}
                  checked={themeName === "light"}
                />
                <span
                  onClick={() => {
                    setThemeName("light");
                  }}
                >
                  Light
                </span>
              </div>
              <div className="flex items-center gap-1 hover:cursor-pointer">
                <input
                  type="radio"
                  name="theme"
                  value="dark"
                  onChange={() => {
                    setThemeName("dark");
                  }}
                  checked={themeName === "dark"}
                />
                <span
                  onClick={() => {
                    setThemeName("dark");
                  }}
                >
                  Dark
                </span>
              </div>
            </div>
          </form>

          {/* <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl pt-8">
              Referral Codes
            </h2>
          </div>
          <ReferralCodes user={user} /> */}

          {/* Security */}
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl pt-8">
              Security
            </h2>
          </div>
          {/* <div className="mx-auto max-w-2xl text-center flex justify-center">
              <button
                className={classNames(
                  theme.currentTheme == "dogTheme"
                    ? "bg-dogTheme-primary text-dogTheme-textStrongColor"
                    : theme.currentTheme == "lightTheme"
                    ? "bg-lightTheme-gradient text-lightTheme-textStrongColor"
                    : "bg-darkTheme-gradient text-darkTheme-textStrongColor",
                  "rounded-md px-4 py-2 flex items-center mt-3"
                )}
                onClick={handleGetPrivateKey}
              >
                Get private key
              </button>
            </div> */}
          <div className="max-w-2xl sm:col-span-2 pt-6 py-4 px-3 md:px-12 hover:cursor-pointer mx-auto">
            <div
              className="flex justify-between items-center text-sm font-semibold leading-6"
              onClick={handleGetPrivateKey}
            >
              <span>Get Private Key</span>
              <span>
                <ChevronRightIcon className="w-6 h-6" />
              </span>
            </div>
          </div>

          <button
            onClick={() => setTogglePassword(!togglePassword)}
            className="w-full flex justify-between items-center py-4 max-w-2xl mx-auto px-3 md:px-12"
          >
            <div className="w-full flex justify-between items-center text-sm font-semibold leading-6">
              <span>Change Password</span>
              <span>
                <ChevronRightIcon className="w-6 h-6" />
              </span>
            </div>
          </button>
          {togglePassword && (
            <form action="#" method="POST" className="mx-auto max-w-xl px-5">
              <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                <div className="sm:col-span-2 pb-4">
                  <label className="block text-sm font-semibold leading-6">
                    Password
                  </label>
                  <div className="mt-2.5 px-3 flex flex-col gap-3">
                    <div className="flex sm:flex-row flex-col gap-2 items-center">
                      <span className="w-full sm:w-[30%] text-sm">
                        Current Password :{" "}
                      </span>
                      <input
                        type="password"
                        className="w-full sm:w-[70%] rounded-md border-0 px-3.5 py-1 shadow-sm ring-1 text-gray-900 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) => setCurrentPassword(e.target.value)}
                      />
                    </div>
                    <div className="flex sm:flex-row flex-col gap-2 items-center">
                      <span className="w-full sm:w-[30%] text-sm">
                        New Password :{" "}
                      </span>
                      <input
                        type="password"
                        className="w-full sm:w-[70%] rounded-md border-0 px-3.5 py-1 shadow-sm ring-1 text-gray-900 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </div>
                    <div className="flex sm:flex-row flex-col gap-2 items-center">
                      <span className="w-full sm:w-[30%] text-sm">
                        Confirm Password :{" "}
                      </span>
                      <input
                        type="password"
                        className="w-full sm:w-[70%] rounded-md border-0 px-3.5 py-1 shadow-sm ring-1 text-gray-900 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                    <p className="text-center text-red-500 text-sm">
                      {errorMessage}
                    </p>
                  </div>
                </div>
                {/* <div className="sm:col-span-2 pb-4">
                  <label className="block text-sm font-semibold leading-6">
                    2-factor authentication
                  </label>
                  <div className="mt-2.5">
                    <p className="text-gray-700">Method</p>
                  </div>
                </div> */}
              </div>
            </form>
          )}

          <div className="w-full flex justify-center items-center">
            <button
              disabled={isSaving}
              className="bg-themeColor-light dark:bg-themeColor-dark rounded-lg w-[80px] px-6 py-2 mx-1 flex flex-col gap-0 items-center justify-center md:mb-3 xl:mb-0 text-[16px] font-medium"
              onClick={() => {
                saveProfile();
              }}
            >
              {isSaving ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </div>

      <ShowPrivateKey
        user={user}
        account={account}
        email={user.email}
        openModal={openShowPrivateKeyModal}
        setOpenModal={setOpenShowPrivateKeyModal}
      />
    </div>
  );
};
