export function maskString(input, length) {
  if (input.length <= 6) {
    return input; // Return the input as is if it's 6 characters or less
  }

  const firstThree = input.substring(0, 3);
  const lastThree = input.substring(input.length - 3);
  const maxAsterisks = length;
  const middleAsterisks = Math.min(maxAsterisks, input.length - 6);
  const middle = "*".repeat(middleAsterisks);

  return firstThree + middle + lastThree;
}

export function reduceString(input, length) {
  if (input.length <= 6) {
    return input; // Return the input as is if it's 6 characters or less
  }

  const len = Math.round((length - 5) / 2)

  const firstStr = input.substring(0, len)
  const lastStr = input.substring(input.length - len)
  const middleStr = "*".repeat(5)

  return firstStr + middleStr + lastStr
}
