import { useState } from "react"
import { Chartwidget } from "./Chartwidget"
import { Holdings } from "./Holdings"
import { TrendingTokens } from "./TrendingTokens"
import { Welcome } from "../../components/Welcome"
import { RefreshAccount } from "../../components/RefreshAccount"
import { DashboardLoader } from "../../components/ContentLoader"

export const Dashboard = ({ account, user }) => {
  const [isLoading, setIsLoading] = useState(true)

  return (
    <div className="flex flex-col gap-2">
      {isLoading ? (
        <DashboardLoader />
      ) : (
        <>
          <Welcome user={user} />
          {account?.portfolioHistory && account?.portfolioHistory.length > 1 ? (
            <Chartwidget account={account} />
          ) : (
            <div className="flex justify-center items-center h-[300px]">
              <p className="text-[#666666] pr-3">No data available yet</p>
              <RefreshAccount account={account} />
            </div>
          )}
        </>
      )}
      <TrendingTokens isLoading={isLoading} setLoading={setIsLoading} />
      {isLoading ? "" : <Holdings account={account} />}
    </div>
  )
}
