import Discord from "./discord.svg"
import Telegram from "./telegram.svg"
import Twitter from "./twitter.svg"
import GooglePay from "./googlePay.svg"
import VISA from "./visa.svg"
import ApplePay from "./applePay.svg"
import MasterCard from "./mastercard.svg"
import AppleCircle from "./appleCircle.svg"
import GoogleCircle from "./googleCircle.svg"
import Line from "./line.svg"
import BlackLine from "./blackline.svg"
import LongLine from "./longLine.svg"
import Graph1 from "./graph1.svg"
import Graph2 from "./graph2.svg"
import Graph3 from "./graph3.svg"
import BlueTwitter from "./twitter_blue.svg"
import Swap from "./swap.png"
import CoinbaseLogo from "./coinbase.png"
import OnramperLogo from "./onramper.png"
import OnramperLogoWhite from "./onramper_white.png"
import CoinbaseLogoWhite from "./coinbase_white.png"
import SignUpwithGoogleDark from "./web_dark_rd_SI.svg"
import SignUpwithGoogleLight from "./web_light_rd_SI.svg"
import GoogleLogoRound from "./web_light_rd_na.svg"
import TwitterBlueRounded from "./twitter.png"
import XLogo from "./x_logo.svg"
import BlackXLogo from "./x_logo_black.svg"
import MemexLogo from "./memex.png"
import MemexWhiteLogo from "./memex_white.png"

const images = {
  Discord,
  Telegram,
  Twitter,
  GooglePay,
  VISA,
  ApplePay,
  MasterCard,
  AppleCircle,
  GoogleCircle,
  Line,
  BlackLine,
  LongLine,
  Graph1,
  Graph2,
  Graph3,
  BlueTwitter,
  Swap,
  CoinbaseLogo,
  OnramperLogo,
  OnramperLogoWhite,
  CoinbaseLogoWhite,
  SignUpwithGoogleDark,
  SignUpwithGoogleLight,
  GoogleLogoRound,
  TwitterBlueRounded,
  XLogo,
  BlackXLogo,
  MemexLogo,
  MemexWhiteLogo,
}

export default images
