import { Transactions } from "./Transactions"

export const TransactionView = ({ account }) => {

  return (
    <div
      className="flex flex-col overflow-hidden"
    >
      <Transactions account={account} />
    </div>
  )
}
