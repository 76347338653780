import { useState } from "react";
import { AddTokenModal } from "./AddTokenModal";
import { Button } from "./Button";

export const AddToken = () => {
  const [openAddTokenModal, setOpenAddTokenModal] = useState(false);

  const handleAddTokenButton = async () => {
    setOpenAddTokenModal(true);
  };
  
  return (
    <>
      <Button
        handleClick={handleAddTokenButton}
        isActive={false}
        buttonText="Add Token"
      />
      <AddTokenModal
        openModal={openAddTokenModal}
        setOpenModal={setOpenAddTokenModal}
      />
    </>
  );
};
