import { useEffect, useState } from "react";
import { db } from "../utils/firebase";
import { doc, getDoc } from "firebase/firestore";

export const usePriceData = () => {
    const [priceData, setPriceData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchPriceData = async () => {
            const documentPath = `priceData/latest`;
            const documentRef = doc(db, documentPath);
            
            try {
                const docSnapshot = await getDoc(documentRef);
                if (docSnapshot.exists()) {
                    const latest = docSnapshot.data();

                    let priceDataTemp = latest.data.sort((a, b) => b.data.fdv - a.data.fdv);

                    // Filter out USDT and USDC from priceDataTemp
                    priceDataTemp = priceDataTemp.filter(e => e.symbol !== "USDT" && e.symbol !== "USDC");

                    const sortedData = {
                        timestamp: latest.timestamp,
                        data: priceDataTemp
                    };

                    setPriceData(sortedData);
                } else {
                    console.error("Document does not exist!");
                }
            } catch (error) {
                console.error("Error fetching document:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPriceData();
    }, []);

    return { priceData, loading };
};
