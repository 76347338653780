import React, { useMemo } from "react"
import { useTheme } from "../../context/useTheme"
import { Chart } from "react-google-charts"
//import {fetchHistoricalData} from "../../hooks/functions"

export const PriceChart = ({ chartData }) => {
  const theme = useTheme()

  const options = useMemo(
    () => ({
      legend: "none",
      backgroundColor: theme.currentThemePanelColor(),
      colors: [theme.currentThemePrimaryColor()],
      hAxis: { gridlines: { color: "transparent" } },
      vAxis: { gridlines: { color: "transparent" } },
      chartArea: { width: "100%", height: "100%" },
    }),
    [theme]
  )

  return (
    <div className="h-[130px] w-full overflow-hidden">
      <Chart
        chartType="LineChart"
        width="100%"
        height="100%"
        data={chartData}
        options={options}
      />
    </div>
  )
}
