import { useState, useEffect } from "react"
import DataCard from "../../components/Data/DataCard"
import SwapInterface from "./SwapInterface"
import { useTheme } from "../../context/useTheme"

export const Trade = ({ user, account }) => {
  const [buyToken, setBuyToken] = useState({})
  const [showCard, setShowCard] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { isMobile, mobileDeviceSize } = useTheme()

  //if isMobile is false set showCard to false
  useEffect(() => {
    if (!isMobile) {
      setShowCard(false)
    }
  }, [isMobile])

  return (
    <div className="flex w-full justify-center items-start md:items-center md:mt-[10vh] gap-5">
      {!isMobile && (
        <DataCard
          buyToken={buyToken}
          showcard={showCard}
          setShowCard={setShowCard}
          isLoading={isLoading}
          setLoading={setIsLoading}
        />
      )}
      {showCard ? (
        <DataCard
          buyToken={buyToken}
          showcard={showCard}
          setShowCard={setShowCard}
          isLoading={isLoading}
          setLoading={setIsLoading}
        />
      ) : (
        <SwapInterface
          buyToken={buyToken}
          setBuyToken={setBuyToken}
          account={account}
          user={user}
          setShowCard={setShowCard}
          showCard={showCard}
          isMobile={isMobile}
          isLoading={isLoading}
          setLoading={setIsLoading}
        />
      )}
    </div>
  )
}
