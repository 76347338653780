import { useState } from "react";
import { Button } from "./Button";
import { ClaimTokenModal } from "./ClaimTokenModal";

export const ClaimTokens = () => {
  const [openClaimTokenModal, setClaimAddTokenModal] = useState(false);

  const handleClaimsTokenButton = async () => {
    setClaimAddTokenModal(true)
  };

  return (
    <>
      <Button
        handleClick={handleClaimsTokenButton}
        isActive={false}
        buttonText="Claim Tokens"
      />
      <ClaimTokenModal
        openModal={openClaimTokenModal}
        setOpenModal={setClaimAddTokenModal}
      />
    </>
  );
};
