import { Chart } from "react-google-charts"
import { useTheme } from "../../context/useTheme"
import { RefreshAccount } from "../../components/RefreshAccount"
import { usePortfolioData } from "../../hooks/usePortfolioData"
import { useMemo } from "react"
import Heading from "../../components/Heading"

const TimeframeButton = ({ duration, setDuration, label }) => (
  <button
    className={`${duration === label ? "rounded-md px-2 bg-themeColor-light dark:bg-themeColor-dark text-btnTextColor-light dark:text-btnTextColor-dark" : "px-2"}`}
    onClick={() => setDuration(label)}
  >
    {label}
  </button>
)

export const Chartwidget = ({ account }) => {
  const { data, duration, setDuration, percentage } = usePortfolioData({
    account,
  })

  const theme = useTheme()

  const options = useMemo(
    () => ({
      legend: "none",
      backgroundColor: theme.currentThemePanelColor(),
      colors: [theme.currentThemePrimaryColor()],
      hAxis: { gridlines: { color: "transparent" } },
      vAxis: { gridlines: { color: "transparent" } },
      chartArea: { width: "100%", height: "100%" },
    }),
    [theme]
  )

  return (
    <div
      className="px-5 md:px-10 w-full rounded-xl bg-panelColor-light dark:bg-panelColor-dark text-textColor-light dark:text-textColor-dark"
    >
      <div className="flex justify-between items-center pt-3 imd:pt-5">
        <Heading>Total Wallet Value</Heading>
        <RefreshAccount account={account} />
      </div>
      <div className="flex md:flex-col flex-row gap-3 md:gap-2 justify-start items-end md:items-start">
        <span className="font-medium text-sm xs:text-xl sm:text-3xl md:text-4xl pt-0 sm:pt-2 md:pt-4">
          $
          {account?.portfolioValue
            ? Number(account?.portfolioValue).toLocaleString()
            : "0"}
        </span>
        <span className="py-[2px] sm:py-1 md:pt-0 font-medium text-xs xs:text-sm md:text-xl">
          ({percentage > 0 ? "+" + percentage : percentage}%)
          <span className="opacity-50">
            &nbsp;&nbsp;Today
          </span>
        </span>
      </div>

      <div className="md:h-[250px] h-[170px] w-full flex">
        <Chart
          chartType="LineChart"
          width="100%"
          height="100%"
          data={data}
          options={options}
        />
      </div>

      <div className="mt-1 sm:mt-6">
        <div className="flex flex-row gap-5 py-2 sm:py-4 border-t-[#e2e2e2] border-t">
          <div className="flex justify-center items-center">
            <div
              className="w-4 h-4 rounded-[50px] px-2 bg-themeColor-light dark:bg-themeColor-dark"
            ></div>
            <span className="font-medium text-sm sm:text-lg ml-2">Live</span>
          </div>
          <div className="flex justify-center items-center gap-3">
            {["1d", "1w", "1m", "3m", "1y"].map((time) => (
              <TimeframeButton
                key={time}
                duration={duration}
                setDuration={setDuration}
                label={time}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
