import React from "react"
import { useTheme } from "../../context/useTheme"
import { classNames } from "../../utils/formats"

export const WidgetKeypad = ({ handleAmountChange, amount }) => {
  const theme = useTheme()

  const handleButtonClick = (value) => {
    const tempInput = amount + value
    handleAmountChange({
      target: { value: tempInput },
    })
  }

  const handleFixedAmountButtonClick = (value) => {
    const tempInput = value
    handleAmountChange({
      target: { value: tempInput },
    })
  }

  const handleBackspace = () => {
    const tempInput = amount.slice(0, -1)
    handleAmountChange({
      target: { value: tempInput },
    })
  }

  let baseClass = "px-4 py-2 text-[16px] rounded focus:outline-none font-medium"
  let fixedAmountButtonClass =
    "px-4 py-2 text-[16px] rounded focus:outline-none font-medium"
  let gridSizes = "grid grid-cols-3 gap-1"

  return (
    <div className="w-full flex flex-col items-center px-4 py-2">
      <div className={`${gridSizes} w-[80%]`}>
        <button
          onClick={() => handleFixedAmountButtonClick("50")}
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `hover:bg-lightTheme-panelColor focus:bg-lightTheme-panelColor`
              : `hover:bg-darkTheme-panelColor focus:bg-darkTheme-panelColor`,
            fixedAmountButtonClass
          )}
        >
          $50
        </button>
        <button
          onClick={() => handleFixedAmountButtonClick("100")}
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `hover:bg-lightTheme-panelColor focus:bg-lightTheme-panelColor`
              : `hover:bg-darkTheme-panelColor focus:bg-darkTheme-panelColor`,
            fixedAmountButtonClass
          )}
        >
          $100
        </button>
        <button
          onClick={() => handleFixedAmountButtonClick("500")}
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `hover:bg-lightTheme-panelColor focus:bg-lightTheme-panelColor`
              : `hover:bg-darkTheme-panelColor focus:bg-darkTheme-panelColor`,
            fixedAmountButtonClass
          )}
        >
          $500
        </button>
      </div>
      <hr className="h-[1px] bg-[#aaa] w-2/3 my-1" />
      <div className={`${gridSizes} w-[80%]`}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
          <button
            key={num}
            onClick={() => handleButtonClick(num.toString())}
            className={classNames(
              theme.currentWidgetTheme === "light"
                ? `hover:bg-lightTheme-panelColor focus:bg-lightTheme-panelColor`
                : `hover:bg-darkTheme-panelColor focus:bg-darkTheme-panelColor`,
              baseClass
            )}
          >
            {num}
          </button>
        ))}
        <button
          onClick={() => handleButtonClick(".")}
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `hover:bg-lightTheme-panelColor focus:bg-lightTheme-panelColor`
              : `hover:bg-darkTheme-panelColor focus:bg-darkTheme-panelColor`,
            baseClass
          )}
        ></button>
        <button
          onClick={() => handleButtonClick("0")}
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `hover:bg-lightTheme-panelColor focus:bg-lightTheme-panelColor`
              : `hover:bg-darkTheme-panelColor focus:bg-darkTheme-panelColor`,
            baseClass
          )}
        >
          0
        </button>
        <button
          onClick={handleBackspace}
          className={classNames(
            theme.currentWidgetTheme === "light"
              ? `hover:bg-lightTheme-panelColor focus:bg-lightTheme-panelColor`
              : `hover:bg-darkTheme-panelColor focus:bg-darkTheme-panelColor`,
            baseClass
          )}
        >
          ⌫
        </button>
      </div>
    </div>
  )
}
