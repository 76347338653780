import React, { useContext, createContext, useState, useEffect } from "react"
import { classNames } from "../utils/formats"
import { auth, db } from "../utils/firebase"
import { getDoc, doc } from "firebase/firestore"

const ThemeContext = createContext()

export function ThemeProvider({ children }) {
  const theme = useThemeProvider()

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const uid = user.uid
        const pathToUserData = `users/${uid}`;
        const userDocRef = doc(db, pathToUserData);
        const userData = await getDoc(userDocRef)

        if (userData.exists()) {
          const themeName = userData.data()["theme"] ? userData.data()["theme"] : "light"
          if (themeName === "dark") {
            document.documentElement.classList.add("dark");
          } else {
            document.documentElement.classList.remove("dark");
          }
          theme.saveTheme(themeName)
        } else {
          theme.saveTheme("light")
          document.documentElement.classList.remove("dark");
        }
      }
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    // Function to handle window resize event
    const handleResize = () => {
      const width = window.innerWidth
      const height = window.innerHeight

      // Update isMobile state based on window width
      if (width < 780) {
        theme.setMobile(true)
        theme.setNavigationPosition("bottom-8 inset-x-0")
      } else {
        theme.setMobile(false)
        theme.setNavigationPosition("top-8 right-16")
      }

      // Update mobileDeviceSize state based on window width and height
      if (width <= 320) {
        theme.setMobileDeviceSize("xs")
        theme.setNavigationPosition("bottom-4 inset-x-0")
      } else if (width <= 375) {
        theme.setMobileDeviceSize("sm")
        theme.setNavigationPosition("bottom-4 inset-x-0")
      } else if (width <= 425) {
        theme.setMobileDeviceSize("md")
      } else if (width <= 780) {
        theme.setMobileDeviceSize("lg")
      } else {
        theme.setMobileDeviceSize("xl")
      }
    }

    // Add event listener for window resize
    window.addEventListener("resize", handleResize)

    // Call handleResize initially to set initial state based on window width and height
    handleResize()

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [theme])

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}

export const useTheme = () => {
  return useContext(ThemeContext)
}

function useThemeProvider() {
  const [currentTheme, setCurrentTheme] = useState("light")
  const [currentWidgetTheme, setCurrentWidgetTheme] = useState(
    localStorage.getItem("widgetTheme")
      ? localStorage.getItem("widgetTheme")
      : "light"
  )
  const [currentRecordingData, setCurrentRecordingData] = useState({})
  const [isMobile, setMobile] = useState(false)
  const [mobileDeviceSize, setMobileDeviceSize] = useState("xs")
  const [navigationPosition, setNavigationPosition] = useState("top-8 right-16")
  const [safeArea, setSafeArea] = useState({
    top: "pt-16",
    right: "pr-2",
    bottom: "pb-24",
    left: "pl-2",
  })

  const themeWidgetColors = {
    light: "#64CECA",
    dark: "#64CECA",
  }

  const themeWidgetPanelColors = {
    light: "#f7f9f9",
    dark: "#23212B",
  }

  const themeWidgetTextColors = {
    light: "#000000",
    dark: "#ffffff",
  }

  const themeColors = {
    light: "#64CECA",
    dark: "#64CECA",
  }

  const themeBGColors = {
    dogTheme: "#ffffff",
    lightTheme: "#ffffff",
    darkTheme: "#18222D",
  }

  const themePanelColors = {
    light: "#f7f9f9",
    dark: "#23212B",
  }

  const themePanel2Colors = {
    light: "#d7dbdb",
    dark: "#484459",
  }

  const themeTextColors = {
    dogTheme: "#000000",
    lightTheme: "#000000",
    darkTheme: "#ffffff",
  }

  const defaultPanel = classNames(
    currentTheme === "dogTheme"
      ? "bg-dogTheme-panelColor text-dogTheme-textStrongColor"
      : currentTheme === "lightTheme"
      ? "bg-lightTheme-panelColor text-lightTheme-textStrongColor"
      : "bg-darkTheme-panelColor text-darkTheme-textStrongColor"
  )

  const textStyles = classNames(
    currentTheme === "dogTheme"
      ? "text-dogTheme-textStrongColor"
      : currentTheme === "lightTheme"
      ? "text-lightTheme-textStrongColor"
      : "text-darkTheme-textStrongColor"
  )

  function saveTheme(setThemeParam) {
    setCurrentTheme(setThemeParam)
  }

  function saveWidgetTheme(setWidgetThemeParam) {
    localStorage.setItem("widgetTheme", setWidgetThemeParam)
    setCurrentWidgetTheme(setWidgetThemeParam)
  }

  function currentThemePrimaryColor() {
    return themeColors[currentTheme]
  }

  function currentThemePanelColor() {
    return themePanelColors[currentTheme]
  }

  function currentThemePanel2Color() {
    return themePanel2Colors[currentTheme]
  }

  function currentThemeTextColor() {
    return themeTextColors[currentTheme]
  }

  function currentWidgetThemeColors(val) {
    const colors = {
      bg: themeWidgetPanelColors[val],
      text: themeWidgetTextColors[val],
      primary: themeWidgetColors[val],
    }

    return colors
  }

  function saveRecordingData(recordingDataParam) {
    setCurrentRecordingData(recordingDataParam)
  }

  return {
    currentTheme,
    currentThemePrimaryColor,
    currentThemePanelColor,
    currentThemePanel2Color,
    currentThemeTextColor,
    currentRecordingData,
    defaultPanel,
    textStyles,
    saveTheme,
    saveRecordingData,
    isMobile,
    setMobile,
    mobileDeviceSize,
    setMobileDeviceSize,
    navigationPosition,
    setNavigationPosition,
    safeArea,
    setSafeArea,
    currentWidgetTheme,
    saveWidgetTheme,
    currentWidgetThemeColors,
  }
}
