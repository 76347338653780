import OrangeAvatarIcon from "./avatar_orange.svg"
import PurpleAvatarIcon from "./avatar_purple.svg"
import CircleIcon from "./circle.svg"
import DownArrowIcon from "./downArrow.svg"
import LanguageIcon from "./language-svgrepo-com.svg"
import MailIcon from "./mail-svgrepo-com.svg"
import MinusIcon from "./minus.svg"
import PlusIcon from "./plus.svg"
import BlackRightArrowIcon from "./right-arrow-svgrepo-com.svg"
import GrayRightArrowIcon from "./right-arrow-svgrepo-gray.svg"
import RightArrowIcon from "./rightArrow.svg"
import SettingIcon from "./setting.svg"

const icons = {
  OrangeAvatarIcon,
  PurpleAvatarIcon,
  SettingIcon,
  RightArrowIcon,
  PlusIcon,
  MinusIcon,
  DownArrowIcon,
  CircleIcon,
  GrayRightArrowIcon,
  BlackRightArrowIcon,
  MailIcon,
  LanguageIcon,
}

export default icons
