import { useEffect, useState, useRef } from "react";
import {
  getUpdateUserBalances,  
  updateWalletSignatures,
} from "./functions";
import { useAuth } from "../context/useAuth";

export const useRefreshData = (address) => {
  const [isLoading, setIsLoading] = useState(true);  
  const isMounted = useRef(true); // Track if the component is mounted

  const {currentUser} = useAuth();

  const fetchData = async () => {
    if (!address) return;
    setIsLoading(true);  

    try {
      await getUpdateUserBalances({ force: true, accountUid: address });
      await updateWalletSignatures(address, currentUser.uid);
    } finally {
      if (isMounted.current) { // Only update state if component is still mounted
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    isMounted.current = true; // Set to true when mounted

    fetchData(); 

    return () => {
      isMounted.current = false; // Set to false when unmounted
    };
  }, [address]);

  return { isLoading, fetchData }; 
};
