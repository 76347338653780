import React, { Fragment, useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ClipLoader } from "react-spinners";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { getApprovedTokens } from "../../hooks/handleApprovedTokens";
import { ClaimIndividualToken } from "./ClaimIndividualToken";
import { Button } from "./Button";

export const ClaimTokenModal = ({ openModal, setOpenModal }) => {
  const [approvedTokens, setApprovedTokens] = useState([]);
  const childRefs = useRef([]);

  useEffect(() => {
    let isMounted = true;
    const fetchTokens = async () => {
      const res = await getApprovedTokens();
      const list = [];
      for (let key in res) {
        list.push(res[key]);
      }
      if (isMounted) setApprovedTokens(list);
    };

    fetchTokens();
    return () => {
      isMounted = false;
    };
  }, []);

  const handleClaimTokens = () => {
    // Call methods on all child components
    // childRefs.current.forEach((ref) => {
    //   if (ref) {
    //     ref.claimToken();
    //   }
    // });
    // Function to process the children in batches
    const runBatch = async (startIndex) => {
      const batchSize = 5; // Size of each batch
      const endIndex = Math.min(
        startIndex + batchSize,
        childRefs.current.length
      );

      // Run methods for the current batch
      const promises = [];
      for (let i = startIndex; i < endIndex; i++) {
        if (childRefs.current[i]) {
          promises.push(childRefs.current[i].claimToken());
        }
      }

      // Wait for all promises in this batch to resolve
      await Promise.all(promises);

      // If there are more batches, run the next one
      if (endIndex < childRefs.current.length) {
        runBatch(endIndex);
      }
    };

    // Start processing from the first batch
    runBatch(0);
  };

  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setOpenModal(true)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-center p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="bg-panelColor-light dark:bg-panelColor-dark text-textColor-light dark:text-textColor-dark relative transform mx-3 px-2 py-4 text-left shadow-xl transition-all w-full sm:max-w-[600px] h-[80vh] max-h-[600px] overflow-auto">
                <div>
                  <div className="flex mr-2 h-12 items-center justify-end">
                    <XMarkIcon
                      className="h-6 w-6 cursor-pointer"
                      aria-hidden="true"
                      onClick={() => setOpenModal(false)}
                    />
                  </div>
                  <div className="text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6"
                    >
                      Claim Tokens
                    </Dialog.Title>
                    <div className="mt-6 flex flex-col gap-2">
                      {approvedTokens.map((item, i) => (
                        <ClaimIndividualToken
                          key={i}
                          ref={(el) => (childRefs.current[i] = el)}
                          symbol={item.symbol}
                          logo={item.logo}
                          name={item.name}
                          contract={item.contract}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="mt-5 flex justify-center">
                  <Button
                    buttonText="Claim All Tokens"
                    handleClick={handleClaimTokens}
                    isActive={false}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
