import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../utils/firebase";
import { images } from "../../assets";
import { SignInButtons } from "../../components/SignInWith";
import { Spinner } from "../../components/Spinner";

export const SigninWithEmail = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);

  //check to see if user is already logged in

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setLoginError("Failed to log in");
    }
    navigate("/app");

    /*try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      )
      authentication.saveAccount(userCredential.user)
      navigate("/app")
    } catch (err) {
      console.error(err)
      setLoginError(true)
    } finally {
      setLoading(false)
    }*/

    //MEMORY LEAKS - https://www.reddit.com/r/reactjs/comments/kiwt4o/firebase_auth_and_memory_leak/?rdt=46498&onetap_auto=true&one_tap=true
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter") {
      handleSignIn(e);
    }
  };

  return (
    <>
      <form>
        <p className="pt-5 text-white flex">Email</p>
        <div className="flex border rounded-md border-solid border-white mt-2">
          <input
            type="text"
            autoComplete="username"
            className="flex bg-transparent py-2 w-full text-white focus:border-[#010101] focus:shadow-none focus:ring-0 border-none"
            placeholder="achithebest@example.com"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <p className="pt-5 text-white flex">Password</p>
        <div className="flex border rounded-md border-solid border-white mt-2">
          <input
            type="password"
            className="flex bg-transparent py-2 w-full text-white focus:border-[#010101] focus:shadow-none focus:ring-0 border-none"
            placeholder="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
            onKeyUp={handleKeyUp}
          />
        </div>

        {loginError && (
          <p className="text-[#f00] mt-2">Input correct credentials</p>
        )}
        <div className="flex gap-3">
          <button
            className="text-black bg-[#f7f9f9] hover:bg-[#ffffff]/80 rounded-full py-2 w-full mt-4"
            onClick={() => navigate("/")}
          >
            Back
          </button>
          <button
            className="text-white bg-themeColor-light hover:bg-themeColor-light/70 rounded-full py-2 w-full mt-4"
            onClick={handleSignIn}
          >
            Login
          </button>
        </div>

        <p
          className="text-right text-[14px] text-[#ddd] mt-3 hover:cursor-pointer hover:underline"
          onClick={() => navigate("/forgot-password")}
        >
          forgot password?
        </p>
        <div className="mt-3 flex items-center justify-between">
          <img src={images.Line} alt="Line" className="w-24 h-auto" />
          <p className="text-white">Or</p>
          <img src={images.Line} alt="Line" className="w-24 h-auto" />
        </div>

        <SignInButtons />

        <button
          className="mt-3 mb-12 text-white text-base hover:bg-themeColor-light rounded-full w-full py-2 border-themeColor-light border"
          onClick={() => navigate("/sign-up")}
        >
          Create new account
        </button>
      </form>
      {loading && <Spinner loading={loading} />}
    </>
  );
};
