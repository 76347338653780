import React, { useState } from "react"
import {
  auth,
  ga_provider,
  tw_provider,
  signInWithPopup,
} from "../../utils/firebase"
import { useNavigate } from "react-router-dom"
import { images } from "../../assets"

export const SignInButtons = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleSignIn = async (provider) => {
    setLoading(true)
    try {
      const result = await signInWithPopup(auth, provider)
      // The signed-in user info.
      const user = result.user
      navigate("/app")
      // You can handle additional logic here (e.g., storing user info in your app's state)
    } catch (error) {
      setError(error)
      console.error("Error signing in:", error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div className="flex items-center justify-center w-full mt-4 gap-3">
        <button
          className="flex items-center justify-center gap-2 text-white text-base hover:bg-themeColor-light rounded-full w-full py-1 border-themeColor-light border"
          onClick={() => handleSignIn(ga_provider)}
          disabled={loading}
        >
          <div className="flex justify-start text-left">
            <img src={images.GoogleLogoRound} alt="Google" className="h-8" />
          </div>
        </button>
        <button
          className="flex items-center justify-center gap-2 text-white text-base hover:bg-themeColor-light rounded-full w-full py-2 border-themeColor-light border"
          onClick={() => handleSignIn(tw_provider)}
          disabled={loading}
        >
          <div className="flex justify-start text-left">
            <img src={images.XLogo} alt="X" className="h-6 w-6" />
          </div>
        </button>
      </div>
      {error && (
        <div className="text-red-500 text-sm mt-2">{error.message}</div>
      )}
    </>
  )
}
