import React, { useEffect, useState } from "react"
import { isValidSolanaPublicKey } from "../../utils/formats"
import { FiAtSign, FiCheckCircle, FiXCircle } from "react-icons/fi"

const RecipientAddress = ({
  txDetails,
  setTxDetails,
  validations,
  setValidations,
}) => {
  const [address, setAddress] = useState(txDetails.recipientAddress || "")

  // Validate address on address state update
  useEffect(() => {
    if (address === "") {
      setValidations((prev) => ({ ...prev, address: true, addressError: "" }))
      return
    }

    const isValid = isValidSolanaPublicKey(address)
    setValidations((prev) => ({
      ...prev,
      address: isValid,
      addressError: isValid ? "" : "Invalid address",
    }))
  }, [address, setValidations])

  // Update address state and txDetails simultaneously
  const onChangeAddress = (e) => {
    const newAddress = e.target.value
    setAddress(newAddress)
    setTxDetails((prev) => ({ ...prev, recipientAddress: newAddress }))
  }

  return (
    <div className="mt-2 w-full relative">
      <input
        type="text"
        id="recipientAddress"
        placeholder="Recipient Address"
        value={address}
        onChange={onChangeAddress}
        aria-describedby="address-helper-text"
        className="rounded-md bg-transparent focus:border-[#aaa] focus:shadow-none focus:ring-0 w-full text-[16px]"
      />
      <div className="absolute top-[6px] right-[6px] w-[30px] h-[30px]">
        <span className="flex justify-center items-center p-1 text-white w-[30px] h-[30px] text-center">
          {validations.address ? (
            <FiCheckCircle style={{ color: "green" }} />
          ) : (
            <FiXCircle style={{ color: "red" }} />
          )}
        </span>
      </div>
      {!validations.address && (
        <span className="error-message">{validations.addressError}</span>
      )}
    </div>
  )
}

export default RecipientAddress

// Additional CSS styles could be added in your CSS file to mimic Chakra UI's design
