import { useEffect, useRef, useState } from "react"
import { initOnRamp } from "@coinbase/cbpay-js"
import { images } from "../../assets"
import { classNames } from "../../utils/formats"
import { useTheme } from "../../context/useTheme"

export function WidgetCoinbaseButton({ address }) {
  const [isReady, setIsReady] = useState(false)
  const onrampInstance = useRef(null)

  const theme = useTheme()

  useEffect(() => {
    const options = {
      appId: "9cbc21d5-e64e-432d-bc24-5004f8872a5d",
      target: "#cbpay-container",
      widgetParameters: {
        destinationWallets: [
          {
            address: address,
            blockchains: ["solana"],
          },
        ],
      },
      experienceLoggedIn: "embedded",
      experienceLoggedOut: "popup",
    }
    //setLoading(true)

    // Initialize the Coinbase OnRamp instance
    initOnRamp(options, (error, instance) => {
      if (instance) {
        if (onrampInstance.current) {
          onrampInstance.current.destroy()
        }
        onrampInstance.current = instance
        setIsReady(true)
      }
    })

    // Clean up the instance on component unmount
    return () => {
      if (onrampInstance.current) {
        onrampInstance.current.destroy()
        onrampInstance.current = null
      }
    }
  }, [address])

  const handleOnPress = () => {
    if (onrampInstance.current) {
      onrampInstance.current.open()
    }
  }

  return (
    <div
      className={classNames(
        theme.currentWidgetTheme === "light"
          ? "hover:bg-lightWidget-panelColor"
          : "hover:bg-darkWidget-panelColor",
        "w-full rounded-tr-xl rounded-tl-xl flex justify-center items-center hover:cursor-pointer coinbase-button py-3"
      )}
    >
      <button
        onClick={handleOnPress}
        className="w-full flex justify-center"
        disabled={!isReady}
      >
        {theme.currentWidgetTheme !== "dark" ? (
          <img
            src={images.CoinbaseLogo}
            className="w-[50%] hover:cursor-pointer"
          />
        ) : (
          <img
            src={images.CoinbaseLogoWhite}
            className="w-[50%] hover:cursor-pointer"
          />
        )}
      </button>

      <div id="cbpay-container"></div>
    </div>
  )
}
