export const MarketLoader = () => (
  <div className="animate-pulse flex flex-col px-2">
    <div className="w-1/3 h-8 md:h-10 bg-gray-300 rounded mb-2"></div>
    <div className="flex justify-end">
      <div className="w-1/2 h-6 bg-gray-300 rounded mb-2"></div>
    </div>

    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
  </div>
);

export const TransactionLoader = () => (
  <div className="animate-pulse flex flex-col px-2 py-5">
    <div className="w-1/3 h-8 md:h-10 bg-gray-300 rounded mb-2"></div>

    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-6 md:h-8 bg-gray-300 rounded my-3"></div>
  </div>
);

export const TradeLeftLoader = () => (
  <div className="animate-pulse flex flex-col px-2">
    <div className="flex gap-3 justify-end">
      <div className="w-6 h-6 bg-gray-300 rounded mb-2"></div>
      <div className="w-6 h-6 bg-gray-300 rounded mb-2"></div>
    </div>

    <div className="flex gap-3 w-full">
      <div className="w-[40%] flex justify-center items-center">
        <div className="w-20 h-20 bg-gray-300 rounded-full mb-2"></div>
      </div>
      <div className="w-[60%] flex flex-col justify-center items-center">
        <div className="w-2/3 h-8 bg-gray-300 rounded mb-2"></div>
        <div className="w-1/3 h-6 bg-gray-300 rounded mb-2"></div>
        <div className="w-1/2 h-6 bg-gray-300 rounded mb-2"></div>
      </div>
    </div>

    <div className="flex justify-between">
      <div className="w-12 h-6 bg-gray-300 rounded mb-2"></div>
      <div className="w-12 h-6 bg-gray-300 rounded mb-2"></div>
    </div>

    <div className="w-full h-4 bg-gray-300 rounded mb-3"></div>

    <div className="flex justify-between">
      <div className="w-12 h-6 bg-gray-300 rounded mb-2"></div>
      <div className="w-12 h-6 bg-gray-300 rounded mb-2"></div>
    </div>

    <div className="w-full h-4 bg-gray-300 rounded mb-3"></div>

    <div className="flex justify-between">
      <div className="w-12 h-6 bg-gray-300 rounded mb-2"></div>
      <div className="w-12 h-6 bg-gray-300 rounded mb-2"></div>
    </div>

    <div className="w-full h-4 bg-gray-300 rounded mb-3"></div>

    <div className="w-1/3 h-6 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-10 bg-gray-300 rounded mb-3"></div>

    <div className="w-1/3 h-6 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-20 bg-gray-300 rounded mb-3"></div>
  </div>
);

export const TradeRightLoader = () => (
  <div className="animate-pulse h-full flex flex-col px-5 py-5">
    <div className="flex flex-col items-center pt-8 md:pt-16">
      <div className="w-1/2 h-12 bg-gray-300 rounded mb-2"></div>
      <div className="w-[80%] h-6 bg-gray-300 rounded mb-2"></div>
    </div>
    <div className="w-full h-10 bg-gray-300 rounded my-3"></div>
    <div className="w-1/3 h-8 bg-gray-300 rounded my-3"></div>

    <div className="flex gap-3 justify-end">
      <div className="w-1/3 h-10 bg-gray-300 rounded mb-2"></div>
    </div>

    <div className="w-1/3 h-8 bg-gray-300 rounded my-3"></div>

    <div className="flex gap-3 justify-end">
      <div className="w-1/3 h-10 bg-gray-300 rounded mb-2"></div>
    </div>

    <div className="flex-grow flex gap-3 justify-center items-end mt-10 md:mt-0">
      <div className="w-1/2 md:w-1/3 h-10 bg-gray-300 rounded mb-2"></div>
    </div>
  </div>
);

export const BotLoader = () => (
  <div className="animate-pulse h-full flex flex-col gap-6 px-4 pc:px-0">
    <div className="flex flex-col lp:flex-row items-center gap-2 lp:gap-4 pt-4">
      <div className="w-[200px] h-12 bg-gray-300 rounded mb-2"></div>
      <div className="w-[200px] h-12 bg-gray-300 rounded mb-2"></div>
      <div className="w-[200px] h-12 bg-gray-300 rounded mb-2"></div>
    </div>
    <div className="flex flex-row items-center gap-4">
      <div className="w-full lp:w-1/2 tb:w-1/3 pc:w-1/4 h-72 bg-gray-300 rounded mb-2"></div>
      <div className="w-full lp:w-1/2 tb:w-1/3 pc:w-1/4 h-72 bg-gray-300 rounded mb-2 hidden lp:flex"></div>
      <div className="w-full lp:w-1/2 tb:w-1/3 pc:w-1/4 h-72 bg-gray-300 rounded mb-2 hidden tb:flex"></div>
      <div className="w-full lp:w-1/2 tb:w-1/3 pc:w-1/4 h-72 bg-gray-300 rounded mb-2 hidden pc:flex"></div>
    </div>
    <div className="flex flex-row items-center gap-4">
      <div className="w-full lp:w-1/2 tb:w-1/3 pc:w-1/4 h-72 bg-gray-300 rounded mb-2"></div>
      <div className="w-full lp:w-1/2 tb:w-1/3 pc:w-1/4 h-72 bg-gray-300 rounded mb-2 hidden lp:flex"></div>
      <div className="w-full lp:w-1/2 tb:w-1/3 pc:w-1/4 h-72 bg-gray-300 rounded mb-2 hidden tb:flex"></div>
      <div className="w-full lp:w-1/2 tb:w-1/3 pc:w-1/4 h-72 bg-gray-300 rounded mb-2 hidden pc:flex"></div>
    </div>
  </div>
);

export const IndividualBotLoader = () => (
  <div className="animate-pulse h-full flex flex-col gap-6 px-4 pc:px-0">
    <div className="flex flex-col lp:flex-row lp:items-end gap-2 lp:gap-4 pt-4">
      <div className="w-[200px] h-12 bg-gray-300 rounded mb-2"></div>
      <div className="w-[400px] h-8 bg-gray-300 rounded mb-2"></div>
      <div className="w-[50px] h-8 bg-gray-300 rounded mb-2"></div>
    </div>
    <div className="flex flex-col lp:flex-row lp:items-center gap-2 lp:gap-4">
      <div className="w-[100px] h-12 bg-gray-300 rounded mb-2"></div>
      <div className="w-8 h-8 bg-gray-300 rounded mb-2"></div>
      <div className="w-[100px] h-12 bg-gray-300 rounded mb-2"></div>
      <div className="w-[200px] h-12 bg-gray-300 rounded ml-8 mb-2"></div>
    </div>
    <div className="flex flex-col lp:flex-row lp:items-center gap-2 lp:gap-4">
      <div className="flex flex-row gap-2 mr-8 mb-2">
        <div className="w-12 h-8 bg-gray-300 rounded"></div>
        <div className="w-20 h-8 bg-gray-300 rounded"></div>
        <div className="w-20 h-8 bg-gray-300 rounded"></div>
      </div>
      <div className="flex flex-row gap-2 mr-4 mb-2">
        <div className="w-8 h-8 bg-gray-300 rounded"></div>
        <div className="w-12 h-8 bg-gray-300 rounded"></div>
      </div>
      <div className="w-12 h-8 bg-gray-300 rounded mb-2"></div>
      <div className="flex flex-row gap-2 mr-4 mb-2">
        <div className="w-8 h-8 bg-gray-300 rounded"></div>
        <div className="w-12 h-8 bg-gray-300 rounded"></div>
      </div>
      <div className="w-12 h-8 bg-gray-300 rounded mb-2"></div>
    </div>
    <div className="w-full h-64 bg-gray-300 rounded mb-2"></div>
  </div>
);

export const BalanceLoader = () => (
  <div className="animate-pulse w-full h-full flex flex-row justify-between">
    <div className="flex flex-row gap-2 mr-4">
      <div className="w-6 h-5 bg-gray-300 rounded"></div>
      <div className="w-12 h-5 bg-gray-300 rounded"></div>
    </div>
    <div className="w-12 h-5 bg-gray-300 rounded"></div>
  </div>
);

export const DashboardLoader = () => (
  <div className="animate-pulse flex flex-col px-2 py-5">
    <div className="w-1/3 h-6 md:h-10 bg-gray-300 rounded mb-2"></div>
    <div className="w-full h-[200px] md:h-[400px] bg-gray-300 rounded my-3 px-4 py-1 md:py-3">
      <div className="w-1/2 h-6 md:h-10 bg-gray-400 rounded my-3"></div>
      <div className="w-full h-[80px] md:h-[250px] bg-gray-400 rounded my-3"></div>
      <div className="w-full h-1 bg-gray-400 rounded mt-3"></div>
      <div className="w-full flex gap-3 px-2 md:px-5 mt-1">
        <div className="w-6 h-6 bg-gray-400 rounded my-3"></div>
        <div className="w-6 h-6 bg-gray-400 rounded my-3"></div>
        <div className="w-6 h-6 bg-gray-400 rounded my-3"></div>
        <div className="w-6 h-6 bg-gray-400 rounded my-3"></div>
        <div className="w-6 h-6 bg-gray-400 rounded my-3"></div>
      </div>
    </div>
    <div className="w-1/3 h-6 md:h-10 bg-gray-300 rounded my-3"></div>
    <div className="w-full h-[150px] bg-gray-300 rounded my-3 flex imd:hidden"></div>
    <div className="w-full hidden imd:flex flex-col">
      <div className="w-full flex gap-3">
        <div className="w-1/3 h-[150px] bg-gray-300 rounded my-3"></div>
        <div className="w-1/3 h-[150px] bg-gray-300 rounded my-3"></div>
        <div className="w-1/3 h-[150px] bg-gray-300 rounded my-3"></div>
      </div>
      <div className="w-full flex gap-3">
        <div className="w-1/3 h-[150px] bg-gray-300 rounded my-3"></div>
        <div className="w-1/3 h-[150px] bg-gray-300 rounded my-3"></div>
        <div className="w-1/3 h-[150px] bg-gray-300 rounded my-3"></div>
      </div>
    </div>
    <div className="w-full h-[220px] md:h-[400px] bg-gray-300 rounded my-3 px-4 py-1 md:py-3">
      <div className="w-1/3 h-6 md:h-10 bg-gray-400 rounded my-3"></div>
      <div className="w-full h-[20px] md:h-[50px] bg-gray-400 rounded my-3"></div>
      <div className="w-full h-[20px] md:h-[50px] bg-gray-400 rounded my-3"></div>
      <div className="w-full h-[20px] md:h-[50px] bg-gray-400 rounded my-3"></div>
      <div className="w-full h-[20px] md:h-[50px] bg-gray-400 rounded my-3"></div>
      <div className="w-full h-[20px] md:h-[50px] bg-gray-400 rounded my-3"></div>
    </div>
  </div>
);
