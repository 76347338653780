import React from "react"
import { useTheme } from "../../context/useTheme"
import { SigninWithEmail } from "./SignInWithEmail"
import { AuthIntro } from "../../components/AuthIntro/"

export const SigninForm = () => {
  const { isMobile } = useTheme()

  return (
    <>
      {!isMobile ? (
        // Desktop View
        <div className="bg-white md:block overflow-y-auto flex-1">
          <div className="relative isolate gradient min-h-screen pb-10">
            <div className="lg:px-8 px-6">
              <AuthIntro isMobile={isMobile} />
              {/* Auth box */}
              <div className="mt-4 border-x border-t rounded-md bg-[#010101] opacity-[0.65] max-w-sm mx-auto">
                <div className="px-10">
                  <SigninWithEmail />
                </div>
              </div>
              {/* end auth box */}
            </div>
          </div>
        </div>
      ) : (
        // End Desktop View
        // Mobile view
        <div className="relative isolate gradient overflow-y-auto flex-1">
          <div className="min-h-screen pb-20 sm:min-h-full text-center relative">
            <div className="absolute inset-0 bg-[#010101] opacity-[0.65]" />
            <div className="relative">
              <div className="px-5">
                <AuthIntro isMobile={isMobile} />
                <hr className="w-full h-[2px] mt-5" />
                <SigninWithEmail />
              </div>
            </div>
          </div>
        </div>

        // End Mobile view
      )}
    </>
  )
}
