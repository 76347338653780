import { useMemo, useState } from "react"
import { useTheme } from "../../context/useTheme"
import { usePriceData } from "../../hooks/usePriceData"
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid"
import {
  convertToMegaAndKilo,
  formatPrice,
  classNames,
} from "../../utils/formats"
import Sparkline from "../Sparkline"
import { MarketLoader } from "../ContentLoader"

export const MarketSummary = () => {
  const theme = useTheme()
  const { priceData, loading } = usePriceData()
  const [interval, setInterval] = useState("h6")

  return (
    <section>
      <div className="w-full">
        {loading && <MarketLoader />}
        {!loading && priceData && (
          <>
            <div className="rounded-t mb-0 px-4 py-1 sm:py-3 border-0">
              <div className="flex flex-wrap items-center">
                <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                  <p className="text-[20px] md:text-4xl font-bold py-0 sm:py-4 text-center md:text-left">
                    Market{" "}
                  </p>
                  <div className="uppercase text-xs md:text-sm font-semibold flex flex-row justify-between items-center">
                    <select
                      value={interval}
                      onChange={(e) => setInterval(e.target.value)}
                      className="w-1/4 h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline hover:cursor-pointer bg-panelColor2-light dark:bg-panelColor2-dark"
                    >
                      <option value="m5">5M</option>
                      <option value="h1">1H</option>
                      <option value="h6">6H</option>
                      <option value="h24">24H</option>
                    </select>
                    <div>
                      Last updated:{" "}
                      {priceData?.timestamp &&
                        new Date(priceData.timestamp).toLocaleString()}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="block w-full overflow-x-auto px-3">
              <table className="w-full table-auto text-lg">
                <thead>
                  <tr>
                    <th className="text-left">Token</th>
                    <th className="text-left">Price</th>
                    <th className="text-left">Change</th>
                    <th className="text-left">Market Cap</th>
                    <th className="text-left">Volume 6H</th>
                    <th className="text-left">Volume 5M</th>
                    <th className="text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  {priceData.data.map((token, index) => (
                    <tr
                      key={index}
                      className="border-b border-dashed hover:opacity-80 hover:cursor-pointer h-14"
                    >
                      <td>
                        <div className="flex items-center justify-start">
                          <img
                            src={token.logo}
                            alt={token.name}
                            className="w-6 h-6 mr-2 rounded-full"
                          />
                          {token.name}
                        </div>
                      </td>
                      <td>
                        <div className="flex items-center justify-start mx-1">
                          ${formatPrice(token.data.priceUsd)}
                        </div>
                      </td>
                      <td>
                        <div className="flex items-center justify-start mx-1">
                          {token.data.priceChange[interval] > 0 ? (
                            <span className="text-green-500">
                              {" "}
                              ({token.data.priceChange[interval]}%) ▲
                            </span>
                          ) : (
                            <span className="text-red-500">
                              {" "}
                              ({token.data.priceChange[interval]}%) ▼
                            </span>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="flex items-center justify-between mx-3">
                          $
                          {convertToMegaAndKilo(
                            token.symbol == "SOL"
                              ? token.marketCap
                              : token.data.fdv // Solana doesn't have fdv
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="flex items-center justify-between mx-3">
                          {convertToMegaAndKilo(token.data.volume["h6"])}{" "}
                        </div>
                      </td>
                      <td>
                        <div className="flex items-center px-3">
                          {convertToMegaAndKilo(token.data.volume["m5"])}{" "}
                        </div>
                      </td>
                      <td>
                        {token.data.priceChange[interval] > 0 ? (
                          <span className="text-green-500">
                            {" "}
                            <Sparkline mint={token.mint} trend={"up"} />
                          </span>
                        ) : (
                          <span className="text-red-500">
                            {" "}
                            <Sparkline mint={token.mint} trend={"down"} />
                          </span>
                        )}
                      </td>
                      <td>
                        <div className="flex items-center justify-start">
                          <a
                            href={token.data.url}
                            target="_blank"
                            rel="noreferrer noopener"
                            className="flex items-center"
                          >
                            <ArrowTopRightOnSquareIcon
                              className="h-4 w-4 sm:h-6 sm:w-6 cursor-pointer"
                              aria-hidden="true"
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </section>
  )
}
