import { ArrowPathIcon } from "@heroicons/react/20/solid"
import { useRefreshData } from "../../hooks/useRefreshData"

export const RefreshAccount = ({ account }) => {
  const { isLoading, fetchData } = useRefreshData(account?.address)

  return (
    <div
      className="items-center justify-center"
      onClick={() => fetchData()}
      disabled={isLoading}
    >
      <ArrowPathIcon
        className={`h-4 w-4 imd:h-6 imd:w-6 cursor-pointer ${
          isLoading ? "animate-spin" : ""
        }`}
        aria-hidden="true"
      />
    </div>
  )
}
