import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import {
  ExclamationTriangleIcon,
  ShieldExclamationIcon,
  HandRaisedIcon,
  CheckIcon,
} from "@heroicons/react/20/solid";
import { useTheme } from "../../context/useTheme";
import { classNames } from "../../utils/formats";
import { getPrivateKey } from "../../hooks/functions";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../utils/firebase";
import { IoCopyOutline } from "react-icons/io5";
import { fetchWallets } from "../../hooks/functions";
import { WalletAddress } from "../Trade/WalletAddress";

function maskString(input) {
  if (input.length <= 6) {
    return input; // Return the input as is if it's 6 characters or less
  }

  const firstThree = input.substring(0, 3);
  const lastThree = input.substring(input.length - 3);
  const maxAsterisks = 20;
  const middleAsterisks = Math.min(maxAsterisks, input.length - 6);
  const middle = "*".repeat(middleAsterisks);

  return firstThree + middle + lastThree;
}

export const ShowPrivateKey = ({
  user,
  account,
  email,
  openModal,
  setOpenModal,
}) => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [flag, setFlag] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [privateKey, setPrivateKey] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [addresses, setAddresses] = useState([
    { address: account.address, index: 0 },
  ]);
  const [selected, setSelected] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    const fetchWalletAddresses = async () => {
      const data = await fetchWallets(user.uid, account.address);
      setAddresses(data.wallets);
    };

    fetchWalletAddresses();
  }, [user, account]);

  const handleSignIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      return { success: true };
    } catch (err) {
      setError(err.message);
      return { success: false };
    }
  };

  const handlePrivateKey = async () => {
    setIsLoading(true);
    const res = await handleSignIn();
    if (res.success) {
      const key = await getPrivateKey(account.uid, selected);
      setFlag(1);
      setPrivateKey(key);
    }
    setIsLoading(false);
  };

  const handlePaste = async (event) => {
    // Access clipboard data
    await navigator.clipboard.writeText(privateKey);
    setIsCopied(true);
    // Update input value with clipboard data
  };

  return (
    <div>
      <Transition.Root show={openModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 flex items-center justify-center"
          onClose={() => setOpenModal(true)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className={classNames(
                theme.currentTheme == "dogTheme"
                  ? "bg-dogTheme-bgColor"
                  : theme.currentTheme == "lightTheme"
                  ? "bg-lightTheme-bgColor"
                  : "bg-darkTheme-bgColor",
                "fixed inset-0 bg-opacity-75 transition-opacity"
              )}
            />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-hidden">
            <div className="flex min-h-full items-center justify-center p-4 text-center md:items-center md:p-0">
              <Transition.Child
                as={Fragment}
                unmount={false}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                enterTo="opacity-100 translate-y-0 md:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 md:scale-100"
                leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              >
                <Dialog.Panel className="fixed transform overflow-y-auto rounded-lg pr-1 pb-4 pt-2 text-left shadow-xl transition-all md:my-8 w-[95vw] imd:w-[500px] h-[75vh] md:pb-6 flex flex-col bg-panelColor-light dark:bg-panelColor-dark text-textColor-light dark:text-textColor-dark">
                  <div className="flex mr-2 h-12 items-center justify-end">
                    <XMarkIcon
                      className="h-6 w-6 cursor-pointer"
                      aria-hidden="true"
                      onClick={() => {
                        setFlag(0);
                        setIsCopied(false);
                        setOpenModal(false);
                      }}
                    />
                  </div>
                  {flag == 0 && (
                    <div className="flex-grow">
                      <div className="flex flex-col gap-6 justify-start items-center relative h-full">
                        <div className="p-4 bg-[#f87171]/30 rounded-full">
                          <ExclamationTriangleIcon
                            className="h-8 w-8 text-red-700"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="flex flex-row gap-3 px-4 w-full justify-start items-center">
                          <ShieldExclamationIcon
                            className="w-[10%] text-red-700"
                            aria-hidden="true"
                          />
                          <span className="text-sm w-[90%]">
                            Your private key is the only way to recover your
                            wallet
                          </span>
                        </div>
                        <div className="flex flex-row gap-3 px-4 w-full justify-start items-center">
                          <HandRaisedIcon
                            className="w-[10%] text-red-700"
                            aria-hidden="true"
                          />
                          <span className="text-sm w-[90%]">
                            Do not let anyone see your private key
                          </span>
                        </div>
                        <div className="w-[90%] h-[2px] bg-panelColor2-light dark:bg-panelColor2-dark"></div>
                        <div className="w-[90%] flex flex-col gap-2 justify-between items-starrt flex-grow">
                          <div className="w-full flex flex-col gap-3">
                            <p>Select Wallet</p>
                            <WalletAddress
                              addresses={addresses}
                              handleSelected={setSelected}
                            />
                            <p>Enter your password</p>
                            <input
                              type="password"
                              placeholder="Password"
                              className="rounded-md pl-6 bg-transparent w-full focus:outline-none focus:shadow-none focus:ring-0 border"
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            {error && <p className="text-red-700">{error}</p>}
                          </div>
                          <div className="w-full flex justify-center items-center">
                            <button
                              className="rounded-md px-4 py-2 bg-themeColor-light dark:bg-themeColor-dark text-btnTextColor-light dark:text-btnTextColor-dark flex items-center sm:text-md text-[14px]"
                              onClick={() => {
                                handlePrivateKey();
                              }}
                            >
                              {isLoading ? "Loading Private Key..." : "Confirm"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {flag == 1 && (
                    <div className="p-4 flex flex-col gap-6 justify-center items-center relative flex-grow">
                      <div className="w-full bg-[#f87171]/10 font-semibold flex flex-col gap-2 justify-center items-center text-center p-2">
                        <span className="text-red-500 w-full font-extrabold">
                          Do <span className="underline">not</span> share your
                          private key!
                        </span>{" "}
                        <span className="text-red-700 w-full">
                          If someone has your private key they will have full
                          control of your wallet
                        </span>
                      </div>
                      <div className="w-full text-center flex flex-col text-lg font-extrabold justify-center items-center">
                        <span className="flex justify-center items-center gap-2">
                          Solana
                          {isCopied && (
                            <CheckIcon
                              className="w-5 h-5"
                              onClick={handlePaste}
                            />
                          )}
                          {!isCopied && (
                            <IoCopyOutline
                              className="w-5 h-5 cursor-pointer"
                              onClick={handlePaste}
                            />
                          )}
                        </span>
                        <span className="font-light text-sm">
                          {maskString(privateKey)}
                        </span>
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
