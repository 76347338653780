import React from "react"
import { useTheme } from "../../context/useTheme"

const Button = ({ type, className = "", onClick, disabled, children }) => {
  const { mobileDeviceSize, isMobile } = useTheme()

  const baseClass = "w-full rounded-md font-bold"
  const textSizeClass = isMobile
    ? mobileDeviceSize === "xs" ||
      mobileDeviceSize === "sm" ||
      mobileDeviceSize === "md"
      ? "text-[14px] py-3"
      : "text-[14px] py-3"
    : "text-[14px] py-3"

  let specificClass = ""
  switch (type) {
    case "review":
      specificClass = "mb-0"
      break
    case "back":
      specificClass = "bg-[#888888] mb-0"
      break
    case "submitSwap":
      specificClass = "flex justify-center items-center mb-0"
      break
    case "confirmSend":
      specificClass = "bg-[#FF7A59] text-white"
      break
    default:
      break
  }

  const buttonClass = `${baseClass} ${textSizeClass} ${specificClass} ${className}`

  return (
    <button className={buttonClass} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  )
}

export const ReviewOrderButton = (props) => <Button type="review" {...props} />
export const BackButton = (props) => <Button type="back" {...props} />
export const SubmitSwapButton = (props) => (
  <Button type="submitSwap" {...props} />
)
export const ConfirmSendButton = (props) => (
  <Button type="confirmSend" {...props} />
)
