import { useTheme } from "../../context/useTheme";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import {
  Bars3Icon,
  SquaresPlusIcon,
  PlusCircleIcon,
  CreditCardIcon,
  PaperAirplaneIcon,
  DocumentTextIcon,
  CurrencyDollarIcon,
  ArrowsRightLeftIcon,
  XMarkIcon,
  HomeIcon,
  Cog8ToothIcon,
  QrCodeIcon,
  TvIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";
import { Onboarding } from "../../pages/Dashboard/Onboarding";
import { Guide } from "../../pages/Dashboard/Guide";
import { auth } from "../../utils/firebase";
import { signOut } from "firebase/auth";
import { images } from "../../assets";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";

export const useLogout = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("idToken");
        localStorage.removeItem("refreshToken");
        navigate("/sign-in");
      })
      .catch((error) => {
        console.error("Error signing out: ", error);
      });
  };

  return { user, handleLogout };
};

const Tiles = ({ label, Icon, onClick, theme, handler, extraClasses }) => {
  const screenWidth = window.innerWidth;
  const tilesize = screenWidth < 400 ? "w-24 h-24" : "w-28 h-28";

  return (
    <div
      className={`${tilesize} ${handler} sm:w-32 sm:h-32 rounded-2xl p-3 flex flex-col items-start justify-between bg-panelColor-light dark:bg-panelColor-dark text-textColor-light dark:text-textColor-dark hover:cursor-pointer hover:border-[1px] ${extraClasses}`}
      onClick={onClick}
    >
      <Icon className="h-7 w-7 mb-2" aria-hidden="true" />
      <span className="text-[12px] sm:text-xs md:text-base font-bold">
        {label}
      </span>
    </div>
  );
};

const MobileMenu = ({ position, isMobile, theme, menuOpen, handleMenu }) => {
  const { user, handleLogout } = useLogout();

  const buttonConfig = [
    {
      label: "Home",
      icon: HomeIcon,
      handler: "first-btn",
      onClick: (navigate) => navigate("/app"),
    },
    {
      label: "Trade",
      icon: ArrowsRightLeftIcon,
      handler: "second-btn",
      onClick: (navigate) => navigate("trade"),
    },
    {
      label: "Market",
      icon: CurrencyDollarIcon,
      handler: "third-btn",
      onClick: (navigate) => navigate("market"),
    },
    {
      label: "Deposit",
      icon: CreditCardIcon,
      handler: "forth-btn",
      onClick: (navigate) => navigate("deposit"),
    },
    {
      label: "Send",
      icon: PaperAirplaneIcon,
      handler: "fifth-btn",
      onClick: (navigate) => navigate("send"),
    },
    {
      label: "Receive",
      icon: QrCodeIcon,
      handler: "sixth-btn",
      onClick: (navigate) => navigate("receive"),
    },
    {
      label: "Settings",
      icon: Cog8ToothIcon,
      handler: "seventh-btn",
      onClick: (navigate) => navigate("profile"),
    },
    {
      label: "Transactions",
      icon: DocumentTextIcon,
      handler: "eighth-btn",
      onClick: (navigate) => navigate("transactions"),
    },
    {
      label: "Bot",
      icon: TvIcon,
      handler: "nineth-btn",
      onClick: (navigate) => navigate("bot"),
    },
    {
      label: "Logout",
      icon: XMarkIcon,
      handler: "tenth-btn",
      onClick: () => handleLogout(),
    },
  ];

  const navigate = useNavigate();

  const handleTileClick = (onClick) => {
    onClick(navigate);
    handleMenu(false);
  };

  return (
    <div className={`fixed flex justify-center z-[9999] ${position}`}>
      <button onClick={() => handleMenu(true)} className="focus:outline-none">
        {isMobile ? (
          <SquaresPlusIcon
            className="menu-icon h-14 w-14 text-black cursor-pointer hover:text-slate-800 bg-slate-400 rounded-full p-2"
            aria-hidden="true"
          />
        ) : (
          <Bars3Icon
            className={`menu-icon h-14 w-14 cursor-pointer text-textColor-light dark:text-textColor-dark`}
            aria-hidden="true"
          />
        )}
      </button>

      <div
        className={`fixed inset-0 z-50 flex items-center justify-center bg-black transition-opacity duration-450 ${
          menuOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
      >
        <div className="p-5 sm:p-10 w-screen h-screen overflow-y-auto bg-bgColor-light dark:bg-bgColor-dark">
          <div className="mt-14 mb-6">
            <div className="w-full pl-2 flex justify-center">
              <img
                src={
                  theme.currentTheme === "light"
                    ? images.MemexLogo
                    : images.MemexWhiteLogo
                }
                alt="Logo"
                className="w-[200px] tb:w-[240px]"
              />
            </div>
            <div className={`fixed flex justify-center z-50 ${position}`}>
              <XMarkIcon
                className="h-14 w-14 text-black hover:text-gray-600 cursor-pointer bg-slate-400 rounded-full p-2"
                aria-hidden="true"
                onClick={() => handleMenu(false)}
              />
            </div>
          </div>

          <nav className="grid grid-cols-3 gap-4 justify-items-center items-center sm:max-w-[400px] mx-auto">
            {buttonConfig.map(
              ({ label, icon, onClick, handler, extraClasses }) => (
                <Tiles
                  key={label}
                  label={label}
                  Icon={icon}
                  handler={handler}
                  onClick={() => handleTileClick(onClick)}
                  theme={theme}
                  extraClasses={extraClasses}
                />
              )
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export const Navigation = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const [isGuide, setIsGuide] = useState(false);
  const [user, setUser] = useState({});
  const onboardingRef = useRef();
  const guideRef = useRef();
  const theme = useTheme();
  const isMobile = theme.isMobile;
  const navigationPosition = theme.navigationPosition;

  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (userData) => {
      if (userData) {
        setUser(userData);
        const userDocRef = doc(db, `users/${userData.uid}`);
        const userDoc = await getDoc(userDocRef);
        const tempIsOnboarding = userDoc.data()?.onBoarding || false;
        setIsOnboarding(tempIsOnboarding);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (isGuide && guideRef.current && isOnboarding) {
      setIsOnboarding(false);
      setTimeout(() => {
        guideRef.current.helpers.open();
      }, 50);
    }
  }, [isGuide]);

  const handleMenuOpen = (param) => {
    setIsMenuOpen(param);
    if (isOnboarding && onboardingRef.current) {
      onboardingRef.current.helpers.close();
    }
    if (param && isOnboarding && guideRef.current) {
      setIsGuide(true);
    }
  };

  const hideMenuButton =
    isMobile &&
    (location.pathname === "/app/trade" ||
      location.pathname.startsWith("/app/trade/") ||
      location.pathname.startsWith("/app/transactions/"));

  return (
    <>
      {!hideMenuButton && (
        <div
          className={`fixed flex justify-center z-[9999] ${navigationPosition}`}
        >
          <MobileMenu
            position={navigationPosition}
            isMobile={isMobile}
            theme={theme}
            menuOpen={isMenuOpen}
            handleMenu={handleMenuOpen}
          />
        </div>
      )}
      <Onboarding ref={onboardingRef} onboarding={isOnboarding} />
      <Guide ref={guideRef} onboarding={isGuide} uid={user?.uid} />
    </>
  );
};
