import { useTheme } from "../../context/useTheme"

export const WidgetOnramper = ({ account, usdValue }) => {
  const theme = useTheme()

  return (
    <iframe
      title="onRamper WIdget"
      src={`https://buy.onramper.com?wallets=SOL:${account.address}&onlyCryptoNetworks=solana&apiKey=pk_prod_01HSXVQTS1574EJYWDXSC51EK2&&mode=buy&defaultFiat=usd&defaultAmount=${usdValue}&themeName=${theme.currentWidgetTheme}`}
      style={{
        width: "100%",
        height: "100%",
      }}
    />
  )
}
