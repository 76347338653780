import { useState, forwardRef, useImperativeHandle } from "react";
import { Button } from "./Button";
import { CheckIcon } from "@heroicons/react/24/outline";
import axios from "axios";

export const ClaimIndividualToken = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("Claim");
  const claimToken = async () => {
    setLoading(true);
    const result = await axios.post(
      "https://backend-ytpq6wu7ia-ey.a.run.app/claimToken",
      {
        contract: props.contract,
      }
    );
    if (result["data"]["status"]) {
      setStatus("Success");
    } else {
      setStatus("Failed");
    }
    setLoading(false);
  };

  useImperativeHandle(ref, () => ({
    claimToken,
  }));

  return (
    <div
      key={props.symbol}
      className="flex items-center justify-between cursor-pointer py-1 px-2"
    >
      <div className="flex items-center">
        <img
          alt="logo"
          src={props.logo}
          className="w-6 tb:w-8 h-auto rounded-full mr-2"
        />
        <div className="text-[12px] tb:text-sm">
          <p className="font-semibold text-start">{props.symbol}</p>
          <p className="text-start">{props.name}</p>
        </div>
      </div>
      <Button
        buttonText={
          status == "Success" ? (
            <CheckIcon
              className="h-6 w-6 cursor-pointer"
              aria-hidden="true"
            />
          ) : status
        }
        handleClick={claimToken}
        isActive={loading}
        size="100px"
      />
    </div>
  );
});
